import {
    takeEvery,
    takeLatest,
    take,
    call,
    put,
    fork,
} from "redux-saga/effects";
import * as SubscriptionActions from "../actions/subscriptiontype";
import * as SubscriptionApi from "../api/subscriptiontype";


function* getsubscription() {
    try {
        const result = yield call(SubscriptionApi.getSubscriptionAttribute);
        yield put(
            SubscriptionActions.getsubscriptionSuccess({
                subscriptionitems: result?.data?.data,
            })
        );
    } catch (e) {
        yield put(

        );
    }
}


function* watchGetSubscriptionRequest() {
    yield takeEvery(
        SubscriptionActions.SubscriptionType.GET_SUBSCRIPTIONTYPE_REQUEST,
        getsubscription
    );
}



const Subscriptiontype = [
    fork(watchGetSubscriptionRequest),
];
export default Subscriptiontype;
