
import { Card, Row, Col, Radio, Button } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";


const Section8 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult.admin_welfare[0]});
  console.log("finalResult", finalResult, getValues());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const question = [
    {
      id: 1,
      qustion:
        "Employee welfare initiatives - Heath & Safety measures, EPF, ESI, Group Insurance available",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not comprehensive",
      right: "Comprehensive employee welfare initiatives available",
      identifier: "employee_welfare",
    },
    {
      id: 2,
      qustion: "Employee feedback/grievances",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not collect their feedback",
      right:
        "We have a system of collecting employee feedback and to work on them",
      identifier: "employee_feedback_grievances",
    },
    {
      id: 3,
      qustion: "Data Integrity",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No system in place",
      right:
        "We have process to classify sensitive information and control measures to protect it (for example customer contact details, pricing contract with suppliers etc)",
      identifier: "data_integrity",
    },
    {
      id: 4,
      qustion: "Parking Facilities for customer (in case of in-bound sales)",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No specified parking",
      right:
        "We have sufficient & easy to access parking facility to customers",
      identifier: "parking_facilities_customers",
    },
    {
      id: 5,
      qustion: "Statutory, Compliance License renewal Calendar",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not in place",
      right:
        "Clearly defined compliance calendar in place and reviewed and updated regularly.",
      identifier: "license_renewal_calendar",
    },
    {
      id: 6,
      qustion:
        "Salary and other payment to employees are made on time in full, every time?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not in place",
      right:
        "We have fixed dates for all payments for employees and payments are made in full on time every time",
      identifier: "payment_employees",
    },
    {
      id: 7,
      qustion: "Maintenance plan for equipment, building and stock materials",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not in place",
      right:
        "Clearly defined maintenance plan and AMC in place and being followed",
      identifier: "maintenance_plan",
    },
  ];  const onChangeData = (e) => {
    setValue(e.target.name, e.target.value);
  };
 
  const handleSubmitValue = () => {
    handleResult({
      ...finalResult,
      admin_welfare: [{
        result: result,
        ...getValues(),
      }]

    });
    handleNext();
  };
  const values = getValues();
  let sum = 0;
  
  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const value = parseInt(values[key], 10);
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }
  
  const result = (sum / 7).toFixed(2);
  
  console.log(result,"section88");
  const previousPage = () => {
    handleResult({
      ...finalResult,
      admin_welfare: [{
        result: result,
        ...getValues(),
      }]

    });
    console.log("first", {
      ...finalResult,
      admin_welfare: [{
        result: result,
        ...getValues(),
      }]

    });
    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3">
          <Col>
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer">
                {index < 1 && <div className="header-text">Admin & Welfare</div>}

                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}<span style={{color:"red"}}>*</span>
                  </div>
                  <div className="docusContainerLabel">
                    <div  className="docusLeftContent">{ques.left}</div>
                    {[1, 2, 3, 4, 5].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"

                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>Back</Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p className="progressPage" style={{ width: "-webkit-fill-available" }}>
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusNextbtn"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>{" "}
    </div>
  );
};

export default Section8;


