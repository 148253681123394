export const OrdermoduleType = {
    GET_ORDERMODULE_REQUEST: "ordermodule/get_ordermodule_request",
    GET_ORDERMODULE_SUCCESS: "ordermodule/get_ordermodule_success",

};


export const getordermodulerequest = () => ({
    type: OrdermoduleType.GET_ORDERMODULE_REQUEST,
});

export const getordermoduleSuccess = ({ orderitems }) => ({
    type: OrdermoduleType.GET_ORDERMODULE_SUCCESS,
    payload: { orderitems },
});
