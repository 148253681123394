import { OrdermoduleType } from '../actions/ordermodule';

const INITIAL_STATE = {
    orderitems: []
};



export default function Ordermodule(state = INITIAL_STATE, action) {
    switch (action.type) {
        case OrdermoduleType.GET_ORDERMODULE_SUCCESS: {
            return {
                ...state,
                orderitems: action.payload.orderitems
            }
        }

        // case AboutusType.GET_ABOUTUS_ERROR: {
        //     return {
        //         ...state,
        //         error: action.payload.error
        //     }
        // }

        default: {
            return state;
        }
    }
}
