import React from 'react'
import Mylearningdemo from '../components/Mylearningdemo/Mylearningdemo'
import Header1 from '../components/Layout/Header1'
const Mylearningdemopage = () => {
  return (
    <div>
        <Header1/>
        <Mylearningdemo/>
    </div>
  )
}

export default Mylearningdemopage