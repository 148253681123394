import React, { useContext, useState } from 'react';
import useDarkMode from '../Pages/useDarkMode';
import { ThemeContext } from '../utils/ThemeProvider';

import Contactus from "../components/Contactus/Contactus";
import Header1 from '../components/Layout/Header1';
import Footer from '../components/Layout/Footer';
const Contactusdetails = (props) => {
    const {darkMode}=useContext(ThemeContext)

    // const [darkMode, setDarkMode] = useState(false);
    const { toggleMode } = useDarkMode();

    // const handleToggle = () => {
    //     setDarkMode(!darkMode);
    //   };
    return (
        <div className={darkMode ? 'dark-mode' : 'light-mode'}>

        <div className="total_sections">
            <Header1 handleToggle={toggleMode}  />
            <Contactus darkMode={darkMode} />
            <Footer/>
        </div> 
        </div>
    )
}

export default Contactusdetails