import React, { useState, useEffect, useContext } from "react";
import "../assets/SCSS/Productdetails.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Modal } from "antd";
import Header1 from "../components/Layout/Header1";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from "../utils/ThemeProvider";
import axios from "axios";
import { find } from "rxjs";

const Videodetails = () => {
    const location = useLocation();
console.log('location', location)
  const { id } = useParams();
  const [course, setCourse] = useState({});
  console.log("coursecourse", course);
  const [popup, setPopup] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [UserList, setUserList] = useState([]);
  console.log('UserList', UserList)

  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);
  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);
  const [UserId, setUserId] = useState([]);

  useEffect(() => {
    const getUserlist = async () => {
      console.log("one");
      axios.get(`api/user/${parsedId._id}`).then((res) => {
        setUserId(res.data.data);
      });
    };
    getUserlist();
  }, []);
  const user_id = UserId[0]?.user_id;


  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/api/courses/" + id)
      .then((res) => res.json())
      .then((result) => {
        setCourse(result?.data[0]);
      });
    getUserlist();
  }, [id]);
  const getUserlist = async () => {
    const lessonId=location.pathname.split('videodetails/')[1]
    console.log('lessonId', lessonId)
    await axios.get("api/subscriptiontype").then((res) => {
      console.log("resres", res);
      const data=res.data.data
      const userData=data.find(doc=>doc._id===lessonId)
      setUserList(userData);

      console.log("res.data.data", userData);
    });
  };
  const showModal = async (samplevideo) => {
    console.log("first!!@@@", samplevideo);

    let res = await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/download?key=${samplevideo}`
    );
    let data = await res.json();
    console.log("first@@", data);
    console.log("first", data);
    setPopup(data);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    popup(null);
  };
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  const handleDownloadClick = () => {
    alert("Downloading this video is not permitted.");
  };
  // const handleVideoPlay = () => {
  //   const watermarkText1 = document.querySelector(".watermarkMycourseText1");
  //   const watermarkText2 = document.querySelector(".watermarkMycourseText2");
  
  //   watermarkText1.style.display = "block";
  //   watermarkText2.style.display = "block";
  // };
  return (
    <div
      onMouseDown={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Header1 handleToggle={toggleMode} />
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div className="videodetails">
        <a onClick={() => showModal(UserList?.samplevideo)}>
          <div>
                <video
              width="100%"
              height="315"
              controls
              src={popup.url}
              controlsList="nodownload"
              className="no-fullscreen-button"
              // onPlay={handleVideoPlay}
              onContextMenu={handleContextMenu}
              // onClick={handleDownloadClick}


            >
              {console.log("first2222", popup.url)}
            </video>
            <div className="WatermarkingForMyCourseSampleVideos"
                     >
                      <div
                        className="watermarkSampleVideoText1"
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "white",
                          opacity: 0.5,
                          marginBottom: "10px", 
                          // display: "none", 
                        }}
                      >
                        Business Champions
                      </div>
                      <div
                        className="watermarkSampleVideoText2"
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "white",

                          opacity: 0.5,
                          // display: "none", 
                        }}
                      >
                        {user_id}
                      </div>
                      </div>
                      </div>
          </a>
          <div className="container">
            <h4 style={{color:darkMode?"white":"black"}}>My Dreamss</h4>
            <div
              className="videoDetailsContents"
            //   dangerouslySetInnerHTML={{ __html: course?.description }}
              style={{ fontSize: "16px", color: darkMode?"white":"black" }}
            >{UserList?.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videodetails;
