import React from "react";
import { Col, Container, Row } from "reactstrap";
import demomodel from "../../assets/Images/Ellipse2.png";
import vector from "../../assets/Images/quatation.png";
import circle from "../../assets/Images/circleblue.png";
import "../../assets/SCSS/Distancelearning.scss";
import { Link } from 'react-router-dom';

const Distancelearning = (props) => {
  const {darkMode}= props

  return (
    <>
      <div className="flexible_sec">
        <Container>
          {/* <Row>
            <Col md="6">
              <div className="distance_sec">
                <h6>DISTANCE LEARNING</h6>
                <h2>
                  Flexible Study at Your Own Pace, According to Your Own Needs
                </h2>
                <p>
                  Lörem ipsum tid pretiv i vobba: om än uberisering.
                  Miltonpengar skuldkvotstak, i tress. Kontratik paran föfågt.
                  Blingbling preruling alfanummer för att vipp
                  processturism.Lörem ipsum tid pretiv i vobba: om än
                  uberisering. Miltonpengar skuldkvotstak, i tress. Kontratik
                  paran föfågt. Blingbling preruling alfanummer för att vipp
                  processturism.
                </p>
              </div>
              <div className="developer_card">
                <div className="circle_img">
                  <img alt="circle" src={circle} />
                </div>

                <Container className="developer_container">
                  <Row>
                    <Col md="10">
                      <div className="develop_explain">
                        <p>
                          Lörem ipsum tid pretiv i vobba: om än uberisering.
                          Miltonpengar skuldkvotstak, i tress. Kontratik paran
                          föfågt. Blingbling preruling alfanummer för att vipp
                          processturism.Lörem ipsum tid pretiv i vobba: om än
                          uberisering. Miltonpengar skuldkvotstak, i tress.
                          Kontratik paran föfågt. Blingbling preruling
                          alfanummer för att vipp processturism.
                        </p>
                      </div>
                    </Col>
                    <Col md="2"></Col>
                  </Row>
                  <Row>
                    <Col className="developer_sec" md="8">
                      <img alt="Sample" src={demomodel} />
                      <p>
                        David Warner
                        <br />
                        <span> QA Developer</span>
                      </p>
                    </Col>
                    <Col md="4" className="vector_sec">
                      <img alt="demo" src={vector} />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="card_register">
                <p>
                  Not a member yet? <Link to="/signup"><span>Register Now!</span></Link> {" "}
                </p>
              </div>
            </Col>
            <Col md="3" className="card-val">
              <div className="card_box">
                <div className="card_text">
                  <h1>1,920</h1>
                  <p>FINISHED SESSIONS</p>
                </div>
              </div>
              <div className="card_box2">
                <div className="card_text">
                  <h1>250</h1>
                  <p>ONLINE INSTRUCTORS</p>
                </div>
              </div>
            </Col>
            <Col md="3" className="card-val">
              <div className="card_box3">
                <div className="card_text">
                  <h1> 3,920</h1>
                  <p>ENROLLED LEARNERS</p>
                </div>
              </div>
              <div className="card_box4">
                <div className="card_text">
                  <h1> 100%</h1>
                  <p>SATISFACTION RATE</p>
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default Distancelearning;
