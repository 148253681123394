import {
    takeEvery,
    takeLatest,
    take,
    call,
    put,
    fork,
} from "redux-saga/effects";
import * as OrdermoduleActions from "../actions/ordermodule";
import * as OrdermoduleApi from "../api/ordermodule";


function* getordermodule() {
    try {
        const result = yield call(OrdermoduleApi.getOrdermoduleAttribute);
        yield put(
            OrdermoduleActions.getordermoduleSuccess({
                orderitems: result?.data?.data,
            })
        );
    } catch (e) {
        yield put(

        );
    }
}


function* watchGetOrdermoduleRequest() {
    yield takeEvery(
        OrdermoduleActions.OrdermoduleType.GET_ORDERMODULE_REQUEST,
        getordermodule
    );
}



const Ordermodule = [
    fork(watchGetOrdermoduleRequest),
];
export default Ordermodule;
