import { MessageType } from '../actions/message';

const INITIAL_STATE = {
    messageitems: [],
    messagesubjectiditem: [],
    aftersuccess: []
};



export default function Message(state = INITIAL_STATE, action) {

    if (action.type === MessageType.GET_MESSAGESUBJECTID_SUCCESS) {


    }



    switch (action.type) {
        case MessageType.GET_MESSAGE_SUCCESS: {
            return {
                ...state,
                messageitems: action.payload.messageitems
            }
        }

        case MessageType.GET_MESSAGESUBJECTID_AFERSUCCESS: {
            return {
                ...state,
                aftersuccess: action.payload.messageitems
            }
        }

        // case AboutusType.GET_ABOUTUS_ERROR: {
        //     return {
        //         ...state,
        //         error: action.payload.error
        //     }
        // }

        case MessageType.POST_MESSAGE_SUCCESS: {

            return {
                ...state,
                loadings: true,
            }

        }
        case MessageType.GET_MESSAGESUBJECTID_SUCCESS: {
            return {
                ...state,
                messagesubjectiditem: action.payload.messagesubjectiditem
            }
        }

        default: {
            return state;
        }
    }
}
