export const CoursemoduleTypes = {
    GET_COURSEMODULE_REQUEST: "coursemodule/get_coursemodule_request",
    GET_COURSEMODULE_SUCCESS: "coursemodule/get_coursemodule_success",

};

export const getCoursemoduleRequest = () => ({
    type: CoursemoduleTypes.GET_COURSEMODULE_REQUEST,
});

export const getCoursemoduleSuccess = ({ coursemoduleitems }) => ({
    type: CoursemoduleTypes.GET_COURSEMODULE_SUCCESS,
    payload: { coursemoduleitems },
});
