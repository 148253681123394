import React, { useContext, useState } from 'react'
import useDarkMode from '../Pages/useDarkMode';
import Aboutus from '../components/Aboutus/Aboutus'
import Header1 from '../components/Layout/Header1'
import Footer from '../components/Layout/Footer'
import { ThemeContext } from '../utils/ThemeProvider';

const About = (props) => {
  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext)
  // const [darkMode, setDarkMode] = useState(false);
  // const handleToggle = () => {
  //     setDarkMode(!darkMode);
  //   };
  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>

      <div className="total_sections">
        <Header1 handleToggle={toggleMode} />
        <Aboutus darkMode={darkMode} />
        <Footer />
      </div>
    </div>
  )
}

export default About
