import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Header1 from "../components/Layout/Header1";
import "../assets/SCSS/Mycourses.scss";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from "../utils/ThemeProvider";
import { ElearningAxios } from "../.../../index";
import coursemodule from "../reducer/coursemodule";
import { digitaloceanspacesurl } from "../api/constant";
import pic from "../assets/Images/kannan image.JPG";
const Mycourse = () => {
  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);

  const { toggleMode } = useDarkMode();

  const { darkMode } = useContext(ThemeContext);
  const [mycourse, setMycourse] = useState();

  const [error, setError] = useState(null);
  useEffect(() => {
    ElearningAxios(`/api/user/purchase-course/${parsedId._id}`)
      .then((response) => {
        console.log("first", response);
        setMycourse(response.data);
        // setError(null);
      })
      .catch(setError);
  }, []);

  // const DreamCardData = [
  //   {
  //     id: 1,
  //     image: pic,
  //     heading1: "ContentMarketing Masterclass: Create Content..",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "StartCourse",
  //   },
  //   {
  //     id: 2,
  //     image: pic,
  //     heading1: "Morden copywritting: in2022 Masterclass: Create Content..",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  //   {
  //     id: 3,
  //     image: pic,
  //     heading1: "Digital Marketing Masterclass: Create Content..",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  //   {
  //     id: 4,
  //     image: pic,
  //     heading1: "Business Development for startups & Tech Companies",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  //   {
  //     id: 5,
  //     image: pic,
  //     heading1: "Business Development for startups & Tech Companies",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  //   {
  //     id: 6,
  //     image: pic,
  //     heading1: "ContentMarketing Masterclass: Create Content..",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  //   {
  //     id: 7,
  //     image: pic,
  //     heading1: "ContentMarketing Masterclass: Create Content..",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  //   {
  //     id: 8,
  //     image: pic,
  //     heading1: "ContentMarketing Masterclass: Create Content..",
  //     progressTop: "Brad Merrill",
  //     progressBottom: "Start Course",
  //   },
  // ];

  const [durationPlayed, setDurationPlayed] = useState(0);

  const handleDurationChange = (duration) => {
    setDurationPlayed(duration);
  };

  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <Header1 handleToggle={toggleMode} />
        <div className="MyCoursePurchaseDetailsBodyContainer">
          <div className="MyCoursePurchaseContainer">
          <div className="MyCoursePurchaseDetailsBody">
            <Container>
              <Row>
                {mycourse?.purchase_course?.map((n) => (
                  <Col md={24} lg={4} xd={24} >
                    <Card className="MyCoursePurchaseCard m-4">
                      
                      <center>
                        <div className="mt-2">
                          <h2 style={{ fontWeight: "bolder" }}>
                          
                            {n.name}
                          </h2>
                          <div>
                            <div className="MyCoursePurchaseDescriptionS"
                              style={{
                                fontWeight: "bolder",
                                fontSize: "20px",
                                textAlign:"center"
                              }}
                            >
                               {n.description} 
                            </div>
                           
                          </div>
                          <div className="pt-3"
                              style={{
                                fontWeight: "bolder",
                                fontSize: "18px",
                                marginTop:"50px",
                                textAlign:"center"

                              }}
                            >
                              {n?.courseModules.length} Modules
                            </div>
                          <div className="headingOfProgressTop"></div>
                          <Link to={"/MyCourseVideoPage/" + n._id}>

                          <center>
                            <Button className="MyCourseVideoViewButton mt-3 mb-3">
                              View Details
                            </Button>
                          </center>
                          </Link>
                        </div>
                      </center>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mycourse;





