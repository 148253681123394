export const SubscriptionType = {
    GET_SUBSCRIPTIONTYPE_REQUEST: "subscriptiontype/get_subscriptiontype_request",
    GET_SUBSCRIPTIONTYPE_SUCCESS: "subscriptiontype/get_subscriptiontype_success",

};


export const getsubscriptionrequest = () => ({
    type: SubscriptionType.GET_SUBSCRIPTIONTYPE_REQUEST,
});

export const getsubscriptionSuccess = ({ subscriptionitems }) => ({
    type: SubscriptionType.GET_SUBSCRIPTIONTYPE_SUCCESS,
    payload: { subscriptionitems },
});
