import { Card, Row, Col, Radio, Button } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";

const Section5 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: finalResult.People_development[0],
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const question = [
    {
      id: 1,
      qustion:
        "Every employee in your company is clear about what is expected from them and their priority",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not sure",
      right: "Clearly defined KRAs and KPIs are available",
      identifier: "priority",
    },
    {
      id: 2,
      qustion: "Clearly defined organisation structure is available",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No",
      right:
        "Dynamic organisation structure is available and reviewed regularly",
      identifier: "organisation_structure",
    },
    {
      id: 3,
      qustion: "Do you have an 'employee career roadmap'?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No",
      right: "Yes, we have clearly defined career roadmap",
      identifier: "employee_career_roadmap",
    },
    {
      id: 4,
      qustion: "What is the average tenure of employees in your company?",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Don't know",
      right: "More than 5 year",
      identifier: "average_tenure",
    },
    {
      id: 5,
      qustion: "Do you have competency mapping in place?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No",
      right:
        "Yes, we have a dynamic competency mapping and actions taken on the gaps.",
      identifier: "competency_mapping",
    },
  ];
  const onChangeData = (e) => {
    setValue(e.target.name, e.target.value);
  };
  const values = getValues();
  let sum = 0;
  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const value = parseInt(values[key], 10);
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }
  
  const result = (sum / 5).toFixed(2);
  
  console.log(result,"yarooooo");

  const handleSubmitValue = () => {
    handleResult({
      ...finalResult,
      People_development: [{
        result: result,
        ...getValues(),

      }]

    });
    handleNext();
  };
  const previousPage = () => {
    handleResult({
      ...finalResult,
      People_development: [{
        result: result,
        ...getValues(),

      }]

    });
    console.log("first", {
      ...finalResult,
      People_development: [getValues()],
      value:[result]

    });
    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3">
          <Col>
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer">
                {index < 1 && (
                  <div className="header-text">People Development</div>
                )}
                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="docusContainerLabel">
                    <div className="docusLeftContent">{ques.left}</div>
                    {[1, 2, 3, 4, 5].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"
                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>Back</Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p
              className="progressPage"
              style={{ width: "-webkit-fill-available" }}
            >
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusNextbtn"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>{" "}
    </div>
  );
};

export default Section5;
