import { Card, Row, Col, Radio, Button } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";


const Section6 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult.Sales[0] });
  console.log("finalResult", finalResult, getValues());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const question = [
    {
      id: 1,
      qustion: "B2B sales - Satisfied with your average collection duration?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not tracked",
      right: "Yes track the collection duration and take actions",
      identifier: "average_collection_duration",
    },
    {
      id: 2,
      qustion:
        "B2B sales - Quarterly, Monthly Sales plan and Daily visit plan available",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No quarterly sales plan",
      right:
        "Yes, we have comprehensive sales plan and actions towards achieving",
      identifier: "salesplan_visitplan",
    },
    {
      id: 3,
      qustion: "B2B sales  - In-coming enquiries - recording and analysis",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not have any incoming enquiries",
      right:
        "We collect incoming enquiries, identify source and continuously analyse the market needs",
      identifier: "incoming_enquiries",
    },
    {
      id: 4,
      qustion:
        "If you are in retail or wholesale - are you monitoring stores inventory turns?",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No, we do not measure inventory turns",
      right: "We measure inventory turns and take actions",
      identifier: "stores_inventory",
    },
    {
      id: 5,
      qustion: "If you are in retail or wholesale - Sales Ratios",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No we do not measure sales ratios",
      right:
        "We measure Walk-in to conversion ratio, Repeat customer ratio, Bill-value ratio, Sales-square feet ratios and take actions",
      identifier: "Sales_ratios",
    },
    {
      id: 6,
      qustion: "If you are in retail or wholesale, In-shop marketing",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not focus on in-shop marketing",
      right: "We do not focus on in-shop marketing",
      identifier: "shop_marketing",
    },
  ];
  const onChangeData = (e) => {
    setValue(e.target.name, e.target.value);
  };
  const values = getValues();
  let sum = 0;
  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const value = parseInt(values[key], 10);
      console.log('value', value)
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }
  
  const result = (sum / 6).toFixed(2);
  
  console.log(result,"xxsssss");

  const handleSubmitValue = () => {
    handleResult({
      ...finalResult,
      Sales: [{
        result: result,
        ...getValues(),

      }]
    });
    handleNext();
  };
  const previousPage = () => {
    handleResult({
      ...finalResult,
      Sales: [{
        result: result,
        ...getValues(),

      }]

    });
    console.log("first", {
      ...finalResult,
      result: result,
      ...getValues(),


    });
    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3">
          <Col>
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer">
                {index < 1 && <div className="header-text">Sales</div>}
                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}<span style={{color:"red"}}>*</span>
                  </div>
                  <div className="docusContainerLabel">
                    <div  className="docusLeftContent">{ques.left} </div>
                    {[1, 2, 3, 4, 5].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"

                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>Back</Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p className="progressPage" style={{ width: "-webkit-fill-available" }}>
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusNextbtn"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section6;
