import { combineReducers } from 'redux';
import Aboutus from './aboutus';
import Admin from './admin';
import coursemodule from './coursemodule';
import Message from './message';
import Ordermodule from './ordermodule';
import Subscription from './subscriptiontype';
import User from './userdetails';

export default combineReducers({
    Aboutus: Aboutus,
    Message: Message,
    Admin: Admin,
    Subscription: Subscription,
    Ordermodule: Ordermodule,
    coursemodule: coursemodule,
    user: User,
});