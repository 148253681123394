import {
    takeEvery,
    takeLatest,
    take,
    call,
    put,
    fork,
} from "redux-saga/effects";
import * as UserActions from "../actions/userdetails";
import * as UserApi from "../api/userdetails";


function* getuser() {
    try {
        const result = yield call(UserApi.getUserAttribute);
        yield put(
            UserActions.getuserSuccess({
                useritems: result?.data?.data,
            })
        );
    } catch (e) {
        yield put(

        );
    }
}


function* watchGetUserRequest() {
    yield takeEvery(
        UserActions.UserType.GET_USER_REQUEST,
        getuser
    );
}



const User = [
    fork(watchGetUserRequest),
];
export default User;
