import React, { useEffect } from "react";
import { getsubscriptionrequest } from ".././actions/subscriptiontype";
import { connect } from "react-redux";
import Header1 from "../components/Layout/Header1";
import Rectangle3 from "../assets/Images/cardtitle.png";
import "../assets/SCSS/Plan.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";

const Plan = (props) => {
  useEffect(() => {
    props.getsubscriptionrequest();
  }, []);

  let plandata = props?.Subscription?.subscriptionitems;
  return (
    <div>
      <Header1 />
      <div className="total_plan_sec">
        <Container>
          <div className="plan_buynow">
            <Link to="/subscriptionamount">
              <Button>Buy Now</Button>
            </Link>
          </div>
          <div className="card_sec_plan">
            {plandata?.map((a) => {
              return (
                <div className="card_portion">
                  <h2>{a?.name}</h2>
                  <div className="plan_header">
                    {a?.courseModules.map((data) => {
                      return (
                        <>
                          <Card
                            className="total_card_plan"

                          >
                            <img width={320} alt="Sample" src={Rectangle3} />
                            <CardBody>
                              <div className="card_body_header_plan">
                                <div className="card_sub">
                                  {/* <img alt="Sample" src={demomodel} /> */}
                                  <CardTitle className="cardtitle">
                                    {data?.name}
                                  </CardTitle>
                                </div>

                                <CardSubtitle
                                  className="card_subtitle_plan"
                                  tag="h6"
                                >
                                  {data?.subTitle}
                                </CardSubtitle>
                                <CardText className="cardtext_plan">
                                  {data.description}
                                </CardText>
                                <div
                                  className="card_text_plan"

                                >
                                  <div className="card_text_sub">
                                    <CardText className="total_hours">
                                      Total Hours: {data?.totalHours}
                                    </CardText>
                                  </div>

                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default connect((Plan) => Plan, {
  getsubscriptionrequest,
})(Plan);
