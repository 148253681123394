import React, { useEffect, useState, useParams } from "react";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { Image } from "antd";
import { ElearningAxios } from "../../index";
const Mycoursepage = () => {



  const [mycourse, setMycourse] = useState();
  const [error, setError] = useState(null);
  useEffect(() => {
    ElearningAxios("/api/coursemodule")
      .then((response) => {
        console.log('first@@', response)
        setMycourse(response.data.data);
        console.log('first####', response.data.data)
        setError(null);
      })
      .catch(setError);
  }, []);


  return (
    <div className="learning-card">
      {mycourse?.map((n) => {
        console.log('first', mycourse)
        return (
          <div>
            <>
              <h2 className="Learning-heading2">{n.name}</h2>
              <div className="learning-card">
                {n.courses?.map((a) => {
                  return (
                    <Card className="All-card">
                      <div className="card-img All-card-img">
                        <Link to={'/pagedetailspage/' + a?._id}>
                          <Image
                            width={100}
                            height={100}
                            src={"http://54.86.219.221/assets/images/cars/" + a?.images}
                          />

                        </Link>
                      </div>
                      <div className="All-card-content">
                        {a.title}
                        <br></br>
                        {a.shortDescription}
                        <br></br>
                        <span>{a.author}</span>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </>

          </div>

        );
      })}

    </div>
  );
};

export default Mycoursepage;
