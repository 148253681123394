
import { ElearningAxios } from '../../index';
export const Changepasswordauth = {
    Changepassword,

};

async function Changepassword(email, oldpassword, newpassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, oldpassword, newpassword })
    };

    return await ElearningAxios.post("/api/user/comparepassword", {
        email: email,
        oldpassword: oldpassword,
        newpassword: newpassword
    }).then(res => {
        if (res) {
            return res.data
        } 

    });
};