import React, { useState, useEffect, useContext } from "react";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from "../utils/ThemeProvider";
import Header1 from "../components/Layout/Header1";
import "../assets/SCSS/Productdetails.scss";
// import charm_tick from "../assets/Images/charm_tick.png";
import { Collapse } from "antd";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Row,
  Col,
} from "reactstrap";
import Rectangle3 from "../assets/Images/cardtitle.png";
import { Link, useParams } from "react-router-dom";
import { getsubscriptionrequest } from "../actions/subscriptiontype";
import { connect } from "react-redux";
import { ElearningAxios } from "../index";

const { Panel } = Collapse;
const text = `
A dog is a type of domesticated animal.
Known for its loyalty and faithfulness,
it can be found as a welcome guest in many households across the world.
`;

const Productdetails = (props) => {
  let userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const [subscripecourse, setSubscripecourse] = useState();
  const [finaluserdata, setUserdatas] = useState();
  const [idd, setIdd] = useState('');
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    if (userDetail?._id) {
      let subscriberdata = subscripecourse?.find(
        (subuserdata) => subuserdata._id == id
      );
      setUserdatas(subscriberdata);
    }
  }, [subscripecourse]);

  useEffect(() => {
    if (userDetail?._id) {
      ElearningAxios.get("/api/user/" + userDetail?._id).then((response) => {
        setSubscripecourse(response.data.data[0]?.subscriptionType);
      });
    }
  }, []);

  const onChange = (key) => {};

  const [product, setProduct] = useState({});
  console.log('product', product)
  const { id } = useParams();
  console.log("id", id);
  const { toggleMode } = useDarkMode();

  let selectedData;

  // Map over the data and store the selected data in the variable
  // module?.courses?.map((data) => {
  //   // if (data._id === selectedId) {
  //   if (data._id) {
  //     selectedData = data;
  //   }
  // });
  let productdata = props?.Subscription?.subscriptionitems;
  useEffect(() => {
    props.getsubscriptionrequest();
  }, []);
  useEffect(() => {
    if (id) {
      fetch(process.env.REACT_APP_DOMAIN + "/api/subscriptiontype/" + id)
        .then((res) => res.json())
        .then((result) => {
          setProduct(result?.data[0]);
          console.log('result?.data[0]', result?.data[0])
        });
    }
  }, [id]);

  return (
    <>
      <Header1 handleToggle={toggleMode} />
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div className="course_header_sec" darkMode={darkMode}>
          <div className="container">
            <div className="couseTopData_left">
              <h1>{product?.name}</h1>
              <h4>{product?.description}</h4>
            </div>

            <div>
              {finaluserdata ? (
                <></>
              ) : (
                <>
                  <Link to={`/payment/${id}`} state={id}>
                    <Button className="buy_bttn">Buy Now</Button>
                  </Link>
                  {/* {console.log(product?.courseModules?.courses?._id,"llllll")} */}
                  <Link
                    to={`/videodetails/${product?._id}`}
                    state={module}
                  >
                   { console.log('firstiiiiiiii', product?.courseModules?.courses?.[0]._id)}

                    <Button className="buy_bttn ms-5">Preview</Button>
                  </Link>
                   {/* <Link
                    to={`/videodetails/${product?.courseModules?.courses?._id}`}
                    state={module}
                  >

                    <Button className="buy_bttn ms-5">Preview</Button>
                  </Link> */}

                </>
              )}
            </div>
          </div>
        </div>

        <div className="total_productdetail_sec">
          <Container>
            <Row>
              <Col md="12">
                <div className="total_collapse_sec">
                  <h2>Course content</h2>
                  {product?.courseModules?.map((module) => {
                    console.log("first!!", module);
                    return (
                      <>
                        <h1 className="module_h1">{module.name}</h1>
                        <p className="module_p">{module.description}</p>
                        <div className="module_slot">
                          {module?.courses?.map((data) => {
                            return (
                              <>
                                {/* {console.log("first", product?.courseModules)} */}
                                <Card className="card2_product total_card_product">
                                <Link to={"/videodetails/" + data?._id} state={module}>


                                  {/* <img
                                    alt="Sample"
                                    src={
                                      "http://54.86.219.221/assets/images/cars/" +
                                      data.images
                                    }
                                  /> */}
                                  <CardBody>
                                    <div className="card2_body_product">

                                      <CardSubtitle className="card_subtitle_product">
                                        {data?.title}
                                      </CardSubtitle>
                                      {/* <CardText className="cardtext_product">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: data?.description,
                                          }}
                                        />
                                      </CardText> */}
                                    </div>
                                  </CardBody>
                                </Link>

                              </Card>
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </div>
                
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default connect((Productdetails) => Productdetails, {
  getsubscriptionrequest,
})(Productdetails);
