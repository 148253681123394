import { all } from 'redux-saga/effects';
import Aboutus from './aboutus';
import Admin from './admin';
import coursemodule from './coursemodule';
import Message from './message';
import Ordermodule from './ordermodule';
import Subscriptiontype from './subscriptiontype';
import User from './userdetails';


export default function* rootSaga() {
    yield all([
        ...Aboutus,
        ...Message,
        ...Admin,
        ...Subscriptiontype,
        ...Ordermodule,
        ...coursemodule,
        ...User
    ]);
}