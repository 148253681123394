import { UserType } from '../actions/userdetails';

const INITIAL_STATE = {
    useritems: []
};



export default function User(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UserType.GET_USER_SUCCESS: {
            return {
                ...state,
                useritems: action.payload.useritems
            }
        }

        default: {
            return state;
        }
    }
}
