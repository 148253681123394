import React, { useEffect } from "react";
import { Card, Button } from "antd";
import "../assets/SCSS/PaymentDesign.scss";
import tick from "../assets/Images/tick_mark_image-removebg-preview (1).png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const PaymentDesign = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
  };
  const location = useLocation();
  console.log('flocationlocationirst', location)
  useEffect(() => {
    setTimeout(()=>{
      paymentCall();
    },[3000])
    
  }, [location?.search]);

  const paymentCall = async () => {
    const paymentId = location.search.split("payment_request_id=")[1];
    console.log('first', paymentId)
  
    try {
      const response = await axios.get(
        `http://143.198.66.255/api/payment-status?payment_id=${paymentId}`
      );
      console.log('firs111t', `http://143.198.66.255/api/payment-status?payment_id=${paymentId}`)
  
      if (response.data.status.payment_request.payments[0].status === 'Credit') {
        console.log('hhhhhh', response.data.status.payment_request.payments[0].status === 'Credit')
        navigate('/MyCourse');
      }
    } catch (error) {
      // Handle any error that occurred during the API request
      console.error(error);
    }
  };
  
  return (
    <div className="PaymentDesignBackground">
      <div className="paymentDesignCard">
        <Card className="payMentDesignCard">
          <div>
            <div className="paymentImageContainer">
              <img src={tick} className="paymentImageCard" />
            </div>
            <div
              className="paymentSuccessMessageFont"
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "16px",
                marginTop: "10px",
              }}
            >
              Your Payment has been done Successfully!{" "}
            </div>
            <h6
              style={{ textAlign: "center", marginTop: "10px", color: "gray" }}
            >
              {" "}
              Congratulations
            </h6>
            <center>
              <Button
                className="paymentSuccessBackBtn mt-3"
                type="primary"
                onClick={handleNavigation}
              >
                Back
              </Button>
            </center>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PaymentDesign;
