import { BehaviorSubject } from 'rxjs';
import { ElearningAxios } from '../../index';
const userSignupSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationSignup = {
    Signup,
    currentUser: userSignupSubject.asObservable(),
    get userSignupValue() { return userSignupSubject.value }
};
export const isuserSignup = () => {
    if (localStorage.getItem("currentUser")) {
        return true;

    }

    return false;
};

async function Signup(signupdata) {
    console.log('signupdata', signupdata)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ signupdata })
    };

    return await ElearningAxios.post("/api/user/register", {
        firstname: signupdata.firstname,
        lastname: signupdata.lastname,
        email: signupdata.email,
        mobile: signupdata.mobile,
        companyName: signupdata.companyName,
        password: signupdata.password,
        address: signupdata.address
    }, (err) => {
    });
};


