const learn={
    shapedata:[ {
         "learnimage":require("../../assets/Images/Rectangle 35.png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (1).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (2).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (3).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (4).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (5).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (6).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (7).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (8).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (9).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     }
 ],
 findingdata:[
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (10).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (11).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (12).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (13).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (14).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "0%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (15).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     },
     {
         "learnimage":require("../../assets/Images/Rectangle 35 (16).png"),
         "learnHeadOne":"Content Marketing",
         "learnHeadTwo":"MasterClass:CreateContent..",
         "learnContentOne":"brad Merril",
         "learnContentTwo":"30% Completed",
         "prgress": "30%"
     },
 
 ]
 }
 export default learn