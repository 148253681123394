export const UserType = {
    GET_USER_REQUEST: "user/get_user_request",
    GET_USER_SUCCESS: "user/get_user_success",

};


export const getuserrequest = () => ({
    type: UserType.GET_USER_REQUEST,
});

export const getuserSuccess = ({ useritems }) => ({
    type: UserType.GET_USER_SUCCESS,
    payload: { useritems },
});
