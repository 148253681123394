import React, { useState, useEffect, Fragment } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Home from "./Pages/Home";
import Pagedetailspage from "./Pages/Pagedetailspage";
import Paymentdetails from "./Pages/Paymentdetails";
import Submitpagedetails from "./Pages/Submitpagedetails";
import Subscriptionamount from "./Pages/Subscriptionamount";
import Learning from "./Pages/Learning";
import About from "./Pages/About";
import Docuspages from "./Pages/AssesmentPage/AssesmentPage";
import Loader from "./components/Loader/Loader";
import SignupPage from "./Pages/SignupPage";
import Contactusdetails from "./Pages/Contactusdetails";
import Login from "./Pages/Login";
import PrivateRoute from "./components/Privaterouter/Privateroute";
import Mylearningdemopage from "./Pages/Mylearningdemopage";
import Profilepage from "./components/Mylearning/Profile";
import Profilesection from "./components/ProfileSection/Profilesection";
import Password from "./components/Mylearning/Password";
import Plan from "./Pages/Plan";
import Productdetails from "./Pages/Productdetails";
import Blog from "./Pages/Blog";
import Blogdetails from "./Pages/Blogdetails";
import { ElearningAxios } from "./index";
import { authenticationService } from "./components/Service/authentication";
import Videodetails from "./Pages/Videodetails";
import AssesmentPage from "./Pages/AssesmentPage/AssesmentPage";
import Mycourse from "./Pages/Mycourse";
import MycourseVideoPage from "./Pages/MycourseVideoPage";
import PaymentDesign from "./Pages/PaymentDesign";
import MyCourseModules from "./Pages/MyCourseModules";
import AnnouncementPage from "./Pages/AnnouncementPage";
const App = () => {
  const [loading, setloading] = useState(false);

  useEffect(() => {
    ElearningAxios("/api/setting").then((response) => {
      localStorage.setItem("SettingKey", JSON.stringify(response.data.data));
    });
  }, []);
  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1000);

    authenticationService.currentUser.subscribe();
    loadScript("https://checkout.razorpay.com/v1/checkout.js");

    // authenticationService.logout();
    // history.push('/login');
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  return (
    <>
      <Fragment>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/learning" element={<Learning />}></Route>
            <Route
              path="/pagedetailspage/:id"
              element={<Pagedetailspage />}
            ></Route>
            <Route path="/profilepage" element={<Profilepage />}></Route>
            <Route path="/password" element={<Password />}></Route>
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/docuspage/:id" element={<AssesmentPage />} />

          <Route
            path="/subscriptionamount"
            element={<Subscriptionamount />}
          ></Route>
          <Route
            path="/contactusdetails"
            element={<Contactusdetails />}
          ></Route>
          <Route path="/about" element={<About />}></Route>
          <Route
            path="/mylearningdemopage"
            element={<Mylearningdemopage />}
          ></Route>
          <Route path="/profilesection" element={<Profilesection />}></Route>
          <Route
            path="/submitpagedetails"
            element={<Submitpagedetails />}
          ></Route>
          <Route path="/payment/:id" element={<Paymentdetails />}></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route path="/plan" element={<Plan />}></Route>
          <Route
            path="/productdetails/:id"
            element={<Productdetails />}
          ></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blogdetails/:id" element={<Blogdetails />}></Route>
          <Route path="/MyCourse" element={<Mycourse />}></Route>
          <Route
            path="/paymentSuccessMessage"
            element={<PaymentDesign />}
          ></Route>

          <Route
            path="/MyCourseVideoPage/:id"
            element={<MycourseVideoPage />}
          ></Route>
            <Route path="/AnnouncementPage" element={<AnnouncementPage/>}></Route>
          <Route path="/MycourseModule/:videoId" element={<MyCourseModules />}></Route>
          <Route path="/videodetails/:id" element={<Videodetails />}></Route>
          {/* <Route path="/videodetails" element={<Videodetails />}></Route> */}

          {/* <Route path='/login' element={<Login />} /> */}
        </Routes>
      </Fragment>
    </>
  );
};
export default App;
