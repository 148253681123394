import { Button, Card } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import "../../../assets/SCSS/Docuspage.scss";
import { Progress } from "antd";
const Section1 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult });

  const handleSubmitValue = () => {
    const data = getValues();
    handleResult({
      ...finalResult,
      name: data.name,
      organisation: data.organisation,
      emailid: data.emailid,
      contactnumber: data.contactnumber,
    });
    handleNext();
  };
 
  return (
    <div className="docuspagBody">
      <div className="p-3">
        <div className="DocusHeadingContainer">
          <Card className="PersonaldetailsContainer_page1">
            <div className="ms-3">
              <div>
                <label style={{ fontSize: "15px", fontWeight: "600" }}>
                  Name <span style={{ color: " rgb(217,48,37)" }}>*</span>
                </label>
              </div>
              <input
                className="InputsofDocus mt-3 mb-2"
                type="text"
                {...register("name", { required: true })}
              />{" "}
              <p className="error-text">
                {errors.name && "Name Is organization"}
              </p>
            </div>
          </Card>
        </div>
        <div className="DocusHeadingContainer">
          <Card className="PersonaldetailsContainer_page1">
            <div className="ms-3">
              <div>
                <label style={{ fontSize: "15px", fontWeight: "600" }}>
                  Organisation{" "}
                  <span style={{ color: " rgb(217,48,37)" }}>*</span>
                </label>
              </div>

              <input
                className="InputsofDocus mt-3 mb-2"
                type="text"
                {...register("organisation", { required: true })}
              />
              <p className="error-text">
                {errors.organisation && "Organization Is required"}
              </p>
            </div>
          </Card>
        </div>
        <div className="DocusHeadingContainer">
          <Card className="PersonaldetailsContainer_page1">
            <div className="ms-3">
              <div>
                <label style={{ fontSize: "15px", fontWeight: "600" }}>
                  Email ID <span style={{ color: " rgb(217,48,37)" }}>*</span>
                </label>
              </div>

              <input
          className="InputsofDocus mt-3 mb-2"
          type="email"
          {...register("emailid", { required: true, pattern: /^\S+@\S+$/i })}
        />
        <p className="error-text">
          {errors.emailid?.type === "required" && "Email is required"}
          {errors.emailid?.type === "pattern" && "Please enter a valid email address"}
        </p>
            </div>
          </Card>
        </div>
        <div className="DocusHeadingContainer">
          <Card className="PersonaldetailsContainer_page1">
            <div className="ms-3">
              <div>
                <label style={{ fontSize: "15px", fontWeight: "600" }}>
                  Contact Number
                  <span style={{ color: " rgb(217,48,37)" }}>*</span>
                </label>
              </div>

              <input
          className="InputsofDocus mt-3 mb-2"
          type="text"
          maxLength={10}
          {...register("contactnumber", { required: true, pattern: /^[0-9]{10}$/ })}
        />
        <p className="error-text">
          {errors.contactnumber?.type === "required" && "Contact Number is required"}
          {errors.contactnumber?.type === "pattern" && "Please enter a valid 10-digit Contact Number"}
        </p>
            </div>
          </Card>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <span />
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p className="progressPage" style={{ width: "-webkit-fill-available" }}>
              page {progressValue} of 9
            </p>
          </div>
          <Button
            className="docusNextbtn me-3"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section1;
