import { BehaviorSubject } from 'rxjs';
import { isuserSignup } from './Signupauthentication';
import { ElearningAxios } from '../../index';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

export const isLogin = () => {
    if (isuserSignup()) {
        return true;
    } else (localStorage.getItem("currentUser"))

    return false;
}

async function login(formvalues) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ formvalues })
    };

    return await ElearningAxios.post("api/user/login", {
        email: formvalues.email,
        password: formvalues.password
    })
}


