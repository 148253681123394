




import React, { useEffect, useRef, useState } from "react";
import { Container } from "reactstrap";
import "../../assets/SCSS/Coursevideo.scss";
import { homedata } from "../../Pages/Home";
import { useContext } from "react";
import Swiper from "swiper";
import "swiper/swiper-bundle.min.css";

const Coursevideo = (props) => {
  const { darkMode } = props;
  const videoRef5 = useRef(null);
  const [videos, setVideos] = useState([]);
  const [popup, setPopup] = useState("");
  const [popup1, setPopup1] = useState("");
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [activeVideoSrc, setActiveVideoSrc] = useState(""); // Track the active video source
  const contactsval = useContext(homedata);
  const targetId = "64cb44473ad2155bed484fc4";
  const filteredData = contactsval.filter((item) => item._id === targetId);
  const values = filteredData.length > 0 ? filteredData[0].clienttestimonials : [];

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_DOMAIN}/api/download?key=${
            filteredData && filteredData[0] && filteredData[0].homeVideo
          }`
        );
        const data = await res.json();
        setPopup(data);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchVideoData();
  }, [contactsval]);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const videoDataPromises = values.map(async (testimonial) => {
          const res = await fetch(
            `${process.env.REACT_APP_DOMAIN}/api/download?key=${testimonial.video}`
          );
          return res.json();
        });

        // Wait for all video data to be fetched
        const videoData = await Promise.all(videoDataPromises);

        // Store the video data in the state
        setVideos(videoData);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchVideoData();
  }, [contactsval])


useEffect(() => {
    const swiper = new Swiper(".mySwiper", {
      effect: "slide",
      centeredSlides: true,
      centeredSlidesBounds: true,
      initialSlide: 0,
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 10, // Adjust the space between slides if needed
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      on: {
        click: function () {
          swiper.slideTo(this.clickedIndex, 300);
        },
      },
    });

    // Set the initial active slide
    swiper.slideTo(0, 0);

  const prevButton = document.querySelector(".swiper-button-prev");
  const nextButton = document.querySelector(".swiper-button-next");
  prevButton.style.visibility = 'hidden';
  swiper.on('slideChange', function () {
    if (swiper.isBeginning && !swiper.isEnd) {
      prevButton.style.visibility = 'hidden';
      nextButton.style.visibility = 'visible';
    } else if (swiper.isEnd) {
      prevButton.style.visibility = 'visible';
      nextButton.style.visibility = 'hidden';
    } else {
      prevButton.style.visibility = 'visible';
      nextButton.style.visibility = 'visible';
    }
  });

    prevButton.addEventListener("click", () => {
      swiper.slidePrev();
    });

    nextButton.addEventListener("click", () => {
      swiper.slideNext();
    });
  }, [videos]);



  const handleVideoClick = (index, src) => {
    setActiveVideoIndex(index);
    setActiveVideoSrc(src); 
  };

  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div className="total_videosec">
          <h2 className="clientTestimonialsheading">Client Testimonials</h2>
          <Container>
            <center className="mt-5">
              <div
                className="swiper mySwiper"
                style={{ width: "100%", height: "300px" }}
              >
                <div className="swiper-wrapper">
                {videos.map((data, index) => (
                    <div className="swiper-slide" key={values[index]._id}>
                      <video
                        className={`video-slider ${
                          activeVideoIndex === index ? "active" : ""
                        }`}
                        src={data.url}
                        controls
                        controlsList="nodownload no-fullscreen-button"
                        onContextMenu={(event) => event.preventDefault()}
                        onClick={() => handleVideoClick(index, data.url)}
                      ></video>
                    </div>
                  ))}
                </div>

                <div className="swiperBtnsContainer">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
            </center>

            <h2 className="clientTestimonialsheading">
              Why Business Champions?
            </h2>

            <div className="Coursevideo_img">
              <div style={{ position: "relative" }}>
                <video
                  width="80%"
                  height="80%"
                  controls
                  controlsList="nodownload"
                  autoPlay={false}
                  className="no-fullscreen-button"
                  onContextMenu={(event) => event.preventDefault()}
                  onClick={() => alert("Downloading this video is not permitted.")}
                  ref={videoRef5}
                  src={popup.url} 
                  type="video/mp4"
                  onPlay={() => {
                    const allVideos = document.querySelectorAll("video");

                    allVideos.forEach((video) => {
                      if (video !== videoRef5.current && !video.paused) {
                        video.pause();
                      }
                    });
                  }}
                ></video>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Coursevideo;
