import React, { useEffect, useState } from "react";
import "../../assets/SCSS/Learning.scss";
import "antd/dist/antd.css";
import { BsCode, BsCardImage } from "react-icons/bs";
import { v1 } from "uuid";
import { Select } from "antd";
import { Modal, ModalBody } from "reactstrap";
import Profile from "../../assets/Images/Profile.png";
import { connect } from "react-redux";
import {
  getmessagerequest,
  PostmessageSuccess,
  getMessageBySubjectIDrequest,
  PostmessageafterSuccess,
} from "../../actions/message";
import { getadminrequest } from "../../actions/admin";

const Message = (props) => {
  const Messagedata = props.Message.messageitems;
  const Admindata = props.Admin.adminitems;

  useEffect(() => {
    props.getmessagerequest();
    props.getadminrequest();
  }, []);

  let userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const [messageList, setMessageList] = useState([]);
  const [read, setRead] = useState("");

  const [modal, setModal] = React.useState(false);
  const [send, setSend] = useState({
    from: userDetail?._id,
    to: "",
    subject: "",
  });
  const [send1, setSend1] = useState("");
  const [selectedSubject, setSelectedSubject] = useState({});

  const toggle = () => setModal(!modal);

  const Clicked = async (e) => {
    props.PostmessageSuccess({
      from: send.from,
      to: send.to,
      message: send1,
      subjectID: v1(),
      subject: send.subject,
    });
    console.log("firsteee", v1());
    
  };

  useEffect(() => {
    setMessageList(props.Message.messagesubjectiditem);
  }, [props.Message.messagesubjectiditem]);

  const DisplayData = async (value) => {
    setSelectedSubject(value);
    props.getMessageBySubjectIDrequest(value);
  };

  const ShowData = async (e) => {
    props.PostmessageSuccess({
      from: userDetail?._id,
      to: "Admin",
      message: read,
      subject: messageList[0].subject,
      subjectID: messageList[0].subjectID,
    });
  };
 
  return (
    <div className="profileDetails">
      <div className="total_message">
        <div className="MessageContainerButtons">
          <h3>Message</h3>
          <button className="ProfileComposeMessageButton me-3" onClick={toggle}>
            Compose
          </button>
        </div>
        <div
          className="meassage_val"
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div style={{ width: "95%", paddingLeft: "5px" }}>
              {Messagedata.map((data, i) => {
                return (
                  <div key={i}>
                    <div className="message">
                      <div className="message-img">
                        <img src={Profile} alt="Profile" />
                      </div>
                      <div className="message-content">
                        <h6 onClick={() => DisplayData(data.subjectID)}>
                          {data.subject}
                        </h6>
                        <div className="message-content-para">
                          <p>{data.from}</p>
                          <p className="message-content-para-two">1 year ago</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="message-right"
            style={{
              position: "inherit ",
              right: "0",
              width: "50%",
              marginRight: "88px",
            }}
          >
            <div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 500 }}
                width={500}
                className="model-border"
              >
                <ModalBody className="model">
                  <h3>New Message</h3>
                  <div className="model-to">
                    <h5>From:</h5>
                    <div className="model-to-box">
                      <input
                        type="text"
                        placeholder="subject"
                        value={send.from}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="model-to">
                    <h5>To:</h5>
                    <div className="model-to-box">
                      <Select
                        value={send.to}
                        className="form_message"
                        onChange={(e) => {
                          setSend((prevSend) => {
                            return { ...prevSend, to: e };
                          });
                        }}
                      >
                        {Admindata?.map((item) => (
                          <Select.Option key={item._id} value={item._id}>
                            {item._id}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="input_div">
                    <input
                      type="text"
                      placeholder="subject"
                      value={send.subject}
                      onChange={(e) => {
                        setSend((prevSend) => {
                          return { ...prevSend, subject: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div className="model-content">
                    <div className="model-content-icons">
                      <h3>B</h3>
                      <h3>I</h3>
                      <h3>
                        <BsCode />
                      </h3>
                      <h3>
                        <BsCardImage />
                      </h3>
                    </div>
                    <div className="model-content-input">
                      <input
                        placeholder="Write A message.."
                        value={send1}
                        onChange={(e) => setSend1(e.target.value)}
                      />
                    </div>
                  </div>
                  <button onClick={Clicked}>Send</button>
                </ModalBody>
              </Modal>
            </div>
            {messageList?.map((data) => (
              <div className="message-right-two" key={data.messageID}>
                <div className="message-right-two-start">
                  <img src={Profile} alt="Profile" />
                </div>
                <div className="message-right-two-end">
                  <p>{data.message}</p>
                </div>
              </div>
            ))}
            {messageList?.length > 0 ? (
              <div className="message-right-three">
                <div>
                  <textarea
                    type="text"
                    className="customInput"
                    placeholder="Type here.."
                    value={read}
                    onChange={(e) => {
                      setRead(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <button onClick={ShowData}>Send</button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <p style={{ visibility: "hidden" }}>
          kssksks<br></br>djd<br></br>
        </p>
      </div>
    </div>
  );
};

export default connect((state) => state, {
  getmessagerequest,
  getadminrequest,
  PostmessageSuccess,
  getMessageBySubjectIDrequest,
  PostmessageafterSuccess,
})(Message);

