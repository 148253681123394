import React, { useContext, useState, useEffect } from "react";
import Header1 from "../components/Layout/Header1";
import { Row, Col, Container,Button,Card } from "reactstrap";
import "../assets/SCSS/Mycourses.scss";
import CoursesVideo1 from "../assets/videos/Promo LSKannan 1.mp4";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from "../utils/ThemeProvider";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { digitaloceanspacesurl } from "../api/constant";
const MycourseVideoPage = ({ values }) => {
  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);

  const [popup, setPopup] = useState("");

  const [watchedDuration, setWatchedDuration] = useState(0);
  // console.log('watchedDuration', watchedDuration)
  values = watchedDuration;
  const handleTimeUpdate = (event) => {
    const currentTime = event.target.currentTime;
    setWatchedDuration(currentTime);
  };
  const [UserList, setUserList] = useState([]);
  console.log("UserList", UserList);

  const [course, setCourse] = useState({});
  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);

  useEffect(() => {
    const getUserlist = async () => {
      console.log("one");
      axios.get(`/api/user/purchase-course/${parsedId._id}`).then((res) => {
        console.log("res", res);
        setUserList(res.data);
        console.log("re3data", res.data);
      });
    };
    getUserlist();
  }, [course]);
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        console.log("first55", course);
        const res = await fetch(
          `${process.env.REACT_APP_DOMAIN}/api/download?key=${course?.video}`
        );
        // console.log('step1', `${process.env.REACT_APP_DOMAIN}/api/download?key=${course?.video}`)
        const data = await res.json();
        setPopup(data);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchVideoData();
  }, [course]);
  const { id } = useParams();
  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/api/courses/" + id)
      .then((res) => res.json())
      .then((result) => {
        console.log('nnnnn', result)
        setCourse(result?.data[0]);
      });
  }, [id]);
  let counter = 1;
  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <Header1 handleToggle={toggleMode} />
        {/* <div className="MyCoursesVideoContentMargin">
          <Row className="MycourseVideoContentRowLeftSide">
            <Col lg={8} className="p-0">
              <div className="leftSideVideoContentBody">
                <div>
                  <video
                    width="100%"
                    height="600"
                    controls
                    className="MyCouresPageVideos"
                    onTimeUpdate={handleTimeUpdate}
                    src={popup.url}
                    type="video/mp4"
                  >
                    {console.log("popup.urlt", popup.url)}
                  </video>
                </div>
                <div className="mt-4">
                  <Container>
                    <h2 className="AboutMycourseVideoHeading">
                      About this Course
                    </h2>
                    <p className="AboutMyCoursePara">
                      {course?.shortDescription}
                    </p>
                    <hr
                      style={{
                        color: darkMode ? "#ABABAB" : "black",
                        height: "2px",
                      }}
                    ></hr>
                    <Row>
                      <Col lg={2}>
                        <h4
                          style={{
                            color: darkMode ? "#A7A6A6" : "#1B1834",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Description
                        </h4>
                      </Col>
                      <Col lg={10}>
                        <div>
                          <p
                            style={{
                              color: darkMode ? "#FFFFFF" : "#1B1834",
                              fontSize: "14px",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: course.description,
                              }}
                            />

                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
            <Col lg={4} className="rightSideVideosContentDetails p-0">
              <div className="rightSideVideoContentBody">
                <h3 className="rightSideVideoContentHeading">
                  Courses Modules
                </h3>
                <hr
                  style={{
                    color: darkMode ? "#ABABAB" : "black",
                    height: "2px",
                  }}
                ></hr>

                {UserList?.purchase_course?.flatMap((n) =>
                  n?.courseModules?.map((ele) => (
                    <Link to={"/MycourseModules/" + ele._id} key={ele._id}>
                      <div className="rightSideVideoContentSubHeading">
                        <div>{`Section ${counter++} : ${ele.name}`}</div>
                      </div>
                      <hr
                        style={{
                          color: darkMode ? "#ABABAB" : "black",
                          height: "2px",
                        }}
                      ></hr>
                    </Link>
                  ))
                )}
              </div>
            </Col>
          </Row>
        </div> */}
         <div className="MyCoursePurchaseDetailsBodyContainer">
         <div className="MyCoursePurchaseContainer">

          <div className="MyCoursePurchaseDetailsBody">
            <Container>
              <Row>
                {UserList?.purchase_course?.map((n) => (
                   n?.courseModules?.map((ele) => (
                  <Col md={24} lg={4}>
                    <Card className="MyCoursePurchaseCard m-4">
                    <img
                        className="MyCourselinkImage m-4"
                        src={digitaloceanspacesurl +ele?.image}
                        alt="CardImage"
                      />
                      <center>
                        <div className="mt-2">
                          <h2 style={{ fontWeight: "bolder" }}>
                          
                            {ele.name}
                          </h2>
                          <div>
                            <div className=""
                              style={{
                                fontWeight: "bolder",
                                fontSize: "20px",
                              }}
                            >
                               {ele.description} 
                            </div>
                          
                          </div>
                          <div
                              style={{
                                fontWeight: "bolder",
                                fontSize: "15px",
                                marginTop:"20px"
                              }}
                            >
                               {ele.video.length} Videos
                            </div>
                            <div key={ele._id}>
                          <Link to={"/MycourseModule/" + ele._id}>

                          <center>
                            <Button className="MyCourseVideoViewButton mt-3 mb-3">
                              View
                            </Button>
                          </center>
                          </Link>
                          </div>
                        </div>
                      </center>
                    </Card>
                  </Col>
                   ))
                ))}
              </Row>
            </Container>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MycourseVideoPage;
