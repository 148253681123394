import { ElearningAxios } from '../index';


export const getMessageAttribute = () => {
    return ElearningAxios.get('/api/message');
};

export const CreateMessage = (payload) => {
    return ElearningAxios.post('/api/message', payload);
};

export const getMessageBySubjectId = (payload) => {
    return ElearningAxios.get("api/message/bysubjectid/" + payload);
};