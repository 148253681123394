export const AdminType = {
    GET_ADMIN_REQUEST: "aboutus/get_admin_request",
    GET_ADMIN_SUCCESS: "aboutus/get_admin_success",

};


export const getadminrequest = () => ({
    type: AdminType.GET_ADMIN_REQUEST,
});

export const getadminSuccess = ({ adminitems }) => ({
    type: AdminType.GET_ADMIN_SUCCESS,
    payload: { adminitems },
});
