import React, { useContext, useState } from 'react'
import useDarkMode from '../Pages/useDarkMode';
import { ThemeContext } from '../utils/ThemeProvider';

import Subscriptionprice from '../components/Subscriptionprice/Subscriptionprice';
import Header1 from "../components/Layout/Header1";
const Subscriptionamount = (props) => {
  // const [darkMode, setDarkMode] = useState(false);
  // const handleToggle = () => {
  //     setDarkMode(!darkMode);
  //   };
  const {  toggleMode } = useDarkMode();
const{darkMode}=useContext(ThemeContext)
  return (
    <>
    <Header1  handleToggle={toggleMode}  />
    <Subscriptionprice  darkMode={darkMode}/>
    </>
  ) 
}

export default Subscriptionamount