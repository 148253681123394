import React, { useEffect, useState } from "react";
import "../../assets/SCSS/Learning.scss";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import Mycoursepage from "./Mycourse";
import { BsCode, BsCardImage } from "react-icons/bs";
import { v1 } from "uuid";
import {
  Select
} from "antd";
import {
  Modal,
  ModalBody,
} from "reactstrap";
import Profile from "../../assets/Images/Profile.png";
import { ElearningAxios } from "../..";

const Mylearning = () => {
  useEffect(() => {
    ElearningAxios("/api/message")
      .then((response) => {
        setMessage(response.data.data);
        setError(null);
      })
      .catch(setError);
  }, []);



  useEffect(() => {
    ElearningAxios("/api/user")
      .then((res) => {
        setUser(res.data.data)
      })
  }, [])

  useEffect(() => {
    ElearningAxios("/api/admin")
      .then((res) => {
        setAdmin(res.data.data)
      })
  }, [])

  let userDetail = JSON.parse(localStorage.getItem('userDetail'))
  const diasplayDetails = {
    messages: "WELCOME",
    conName: "Arun",
  };

  const [diasplay, setDisplay] = useState(diasplayDetails);


  const [updated, setUpdated] = useState([])
  const [modal, setModal] = React.useState(false);
  const [admin, setAdmin] = useState([]);
  const [message, setMessage] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});


  const [resData, setResData] = useState({})
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const [send, setSend] = useState(null)
  const [data, setData] = useState({})
  const [read, setRead] = useState("")
  const toggle = () => setModal(!modal);

  const Clicked = async (e) => {
    setModal(!modal)
    setData(send)
    await ElearningAxios.post('/api/message', {
      from: data.from,
      to: data.to,
      subject: data.subject,
      message: data.message,
      subjectID: v1(),
    }).then((res) => {
      setResData(res.data.data)
    })
  }

  const DisplayData = async (value) => {

    setSelectedSubject(value)


    await ElearningAxios.get(`/api/message/subject/${value.subjectID}`)
      .then((res) => {
        setUpdated(res.data.data)
      })
  }

  const ShowData = () => {


    let mockObj = {
      from: userDetail?._id,
      to: selectedSubject.to,
      subjectID: selectedSubject.subjectID,
      message: read
    }


    setRead("")

  }
  return (
    <div className="learbox">
      <div className="container">
        <h1 className="Header">My Learning</h1>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={<span className="tab">My Course</span>} key="1">
            <Mycoursepage />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span className="tab">Message</span>} key="2">
            <div className="all-message">
              <div>
                {
                  message.map((data, i) => {

                    return (
                      <div>
                        <div className="message"
                        >
                          <div className="message-img">
                            <img src={Profile}></img>
                          </div>
                          <div className="message-content">
                            <h6 onClick={() => DisplayData(data)}>
                              {data.subject}
                            </h6>
                            <div className="message-content-para">
                              <p>{data.from}</p>
                              <p className="message-content-para-two">1 year ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="message-right">
                <div className="message-right-one">
                  <p>Conversation with </p>
                  <div>
                    <button onClick={toggle}>Compose</button>
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      modalTransition={{ timeout: 500 }}
                      width={500}
                      className="model-border"
                    >
                      <ModalBody className="model">
                        <h3>New Message</h3>
                        <div className="model-to">
                          <h5>From:</h5>
                          <div className="model-to-box">
                            <Select type="text" className='form_message'
                              value={send?.from}
                              onChange={(e) => {
                                setSend((pre) => {
                                  return { ...pre, from: e };
                                });
                              }}>
                              {updated?.map((item) => (
                                <Select.Option key={item.from} value={item.from} className="form_data" >
                                  {item.from}
                                </Select.Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="model-to">
                          <h5>To:</h5>
                          <div className="model-to-box">
                            <Select type="text" value={send?.to} className='form_message'
                              onChange={(e) => {
                                setSend((pre) => {
                                  return { ...pre, to: e };
                                });
                              }}>{admin?.map((item) => (
                                <Select.Option key={item._id} value={item._id}>
                                  {item._id}
                                </Select.Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="input_div">
                          <input type="text" placeholder="subject" value={send?.subject} onChange={(e) => {
                            setSend((pre) => {
                              return { ...pre, subject: e.target.value };
                            });
                          }}></input>
                        </div>
                        <div className="model-content">
                          <div className="model-content-icons">
                            <h3>B</h3>
                            <h3>I</h3>
                            <h3>
                              <BsCode />
                            </h3>
                            <h3>
                              <BsCardImage />
                            </h3>
                          </div>
                          <div className="model-content-input">
                            <input placeholder="Write A message.." value={send?.message} onChange={(e) => {
                              setSend((pre) => {
                                return { ...pre, message: e.target.value };
                              });
                            }}></input>
                          </div>
                        </div>
                        <button onClick={Clicked}> Send</button>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
                {
                  updated?.map(data => {
                    return (
                      <div className="message-right-two">
                        <div className="message-right-two-start">
                          <img src={Profile}></img>
                          <p>{data.from} 6.27 P M</p>
                        </div>
                        <div className="message-right-two-end">
                          <p>{data.message}</p>
                        </div>
                      </div>
                    )
                  })
                }

                <div className="message-right-three">
                  <textarea type="text" placeholder="Type Your message.." value={read} onChange={(e) => {
                    setRead((pre) => {
                      return e.target.value;
                    });
                  }}></textarea>
                  <button onClick={ShowData}>Send</button>
                </div>
              </div>
              <p className="message-right-para" >kssksks<br></br>djd<br></br></p>
            </div>
          </Tabs.TabPane>

        </Tabs>
      </div>
    </div >
  );

};

export default Mylearning;
