import { AdminType } from '../actions/admin';

const INITIAL_STATE = {
    adminitems: []
};



export default function Admin(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AdminType.GET_ADMIN_SUCCESS: {
            return {
                ...state,
                adminitems: action.payload.adminitems
            }
        }

        // case AboutusType.GET_ABOUTUS_ERROR: {
        //     return {
        //         ...state,
        //         error: action.payload.error
        //     }
        // }

        default: {
            return state;
        }
    }
}
