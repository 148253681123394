// import React from "react";
// import { Container, Card} from "reactstrap";
// import {Row,Col} from "antd"
// import "../../assets/SCSS/Subscriptionprice.scss";
// import { Link } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { getsubscriptionrequest } from "../../actions/subscriptiontype";
// import { connect } from "react-redux";
// import { ElearningAxios } from "../../index";

// import Rectangle3 from "../../assets/Images/kannan image.JPG";

// const Subscriptionprice = (props) => {
//   const { darkMode } = props;

//   useEffect(() => {
//     props.getsubscriptionrequest();
//   }, []);
//   const [enroll, setEnroll] = useState();

//   let userDetail = JSON.parse(localStorage.getItem("userDetail"));
//   const subcriptiontype = props.Subscription.subscriptionitems;

//   useEffect(() => {
//     if (userDetail?._id) {
//       ElearningAxios.get("/api/user/" + userDetail?._id).then((response) => {
//         setEnroll(response.data.data[0]?.subscriptionType);
//       });
//     }
//   }, []);

//   function checkEnroll(id) {
//     if (enroll) {
//       let enrolldata = enroll?.find(data => data?._id == id);
//       if (enrolldata) {
//         return true
//       }
//     } else {
//       return false
//     } 
//   }
//   return (
//     <>
//            <div className={darkMode ? 'dark-mode' : 'light-mode'}>

//       <div className="Full_color">
//         <Container className="container_top">
//           <div className="Sub_container">
         
//             <div className="sub_productheading">
//             <center><h1>Our Products</h1></center>
//               {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}
//             </div>
//             <div className="Body_content">

//               {subcriptiontype?.map((val) => {
//                 return (
//                   <Card className="Sub_Start sub_card_header">
//                     <img src={Rectangle3} alt="sample" />
//                     <div className="top_header_sub">
//                       <div className="card_header_name_sub">
//                         <p>{val.name}</p>
//                       </div>
//                       <div className="Rupee_Section">
//                         <p>
//                           <span>₹</span> {val.price}
//                         </p>

//                       </div>
//                     </div>
//                     <div className="Sub_Starter1">
//                       <p>{val.description}</p>
//                     </div>
//                     <div className="Whole_points">
//                       <div className="Shape_dreams">

//                         <div>
//                           <p>12 Modules</p>
//                         </div>
//                       </div>
//                       <div className="Shape_dreams">

//                         <div>
//                           <p>150 Hours of Video</p>
//                         </div>
//                       </div>
//                       <div className="Shape_dreams">

//                         <div>
//                           <p>View All Courses</p>
//                         </div>
//                       </div>

//                       <div className="Starter_btn">
//                         {/* <Link to={"/payment/" + val?._id} state={val}> */}
//                         <Link to={"/docuspage/" + val?._id} state={val}>

//                           {checkEnroll(val?._id) ? (<></>)
//                             : (
//                               <>
//                                 <button>Enroll Now</button>
//                               </>
//                             )}
//                         </Link>

//                         <Link to={"/productdetails/" + val?._id} state={val}>
//                           <button>View Details</button>
//                         </Link>
//                       </div>
//                     </div>
                  
//                   </Card>
//                 );
//               })}
//             </div>

//           </div>
//         </Container>
//       </div>
//       </div>
//     </>
//   );
// };

// export default connect((Subscription) => Subscription, {
//   getsubscriptionrequest,
// })(Subscriptionprice);


import React, { useContext, useEffect, useState } from "react";
import { Container, Card } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getsubscriptionrequest } from "../../actions/subscriptiontype";
import { Modal } from "antd";
import Login from "../../Pages/Login";
import Rectangle3 from "../../assets/Images/kannan image.JPG";
import { ElearningAxios } from "../../index";
import "../../assets/SCSS/Subscriptionprice.scss";
import useDarkMode from "../../Pages/useDarkMode";
import { ThemeContext } from '../../utils/ThemeProvider';


const Subscriptionprice = (props) => {
  const {  getsubscriptionrequest, subscriptionitems } = props;

  useEffect(() => {
    getsubscriptionrequest();
  }, []);
  const {toggleMode } = useDarkMode();
  const{darkMode}=useContext(ThemeContext)

  const navigate = useNavigate();
  const [enroll, setEnroll] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageType, setPageType] = useState("LOGIN");

  let userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const subcriptiontype = subscriptionitems;
  const handleClick = (_id) => {
    console.log('_id', _id)
    const storedData = JSON.parse(localStorage.getItem('userDetail'));
    const id = storedData && storedData._id;
console.log('first', id)
    if (id) {
      navigate(`/docuspage/${_id}`,{state:{id:_id}});
    } else {
      showModal();
    }
  };
  useEffect(() => {
    if (userDetail?._id) {
      ElearningAxios.get("/api/user/" + userDetail?._id).then((response) => {
        setEnroll(response.data.data[0]?.subscriptionType);
      });
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPageType("LOGIN");
  };

  const clickRow = (status) => {
    if (status === "cancel") {
      setIsModalOpen(false);
    }
  };

  const handlePage = (value) => {
    setPageType(value);
  };

  function checkEnroll(id) {
    if (enroll) {
      let enrolldata = enroll?.find((data) => data?._id === id);
      if (enrolldata) {
        return true;
      }
    } else {
      return false;
    }
  }
  

  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <div className="Full_color"  handleToggle={toggleMode}>
        <Container className="container_top">
          <div className="Sub_container">
            <div className="sub_productheading">
              <center>
                <h1>Our Products</h1>
              </center>
            </div>
            <div className="Body_content">
              {subcriptiontype?.map((val) => (
                
                <Card className="Sub_Start sub_card_header">
                 { console.log('val', val)}
                  <img src={Rectangle3} alt="sample" />
                  <div className="top_header_sub">
                    <div className="card_header_name_sub">
                      <p>{val.name}</p>
                    </div>
                    <div className="Rupee_Section">
                      <p>
                        <span>₹</span> {val.price}
                      </p>
                    </div>
                  </div>
                  <div className="Sub_Starter1">
      <p>{val.description}</p>
      
    </div>
                  <div className="Whole_points">
                    <div className="Shape_dreams">
                      <div>
                        <p>{val.courseModules.length} Modules</p>
                      </div>
                    </div>
                    <div className="Shape_dreams">
                      <div>
                        <p>{val.duration}  Hours of Video</p>
                      </div>
                    </div>
                    <div className="Shape_dreams">
                      <div>
                        <p>View All Courses</p>
                      </div>
                    </div>
                    <div className="Starter_btn">
                      {/* <Link to={"/docuspage/" + val?._id} state={val}>
                        {checkEnroll(val?._id) ? (
                          <></>
                        ) : ( */}
                          {/* <button className="EnrollButton" onClick={showModal}>Enroll Now</button> */}
                          {checkEnroll(val?._id) ? (<></>)
                            : (
                              <>
                          <button className="EnrollButton" onClick={()=>handleClick(val._id)}>Enroll Now</button>
 </>
                            )}
                        {/* )}
                      </Link> */}
                      {/* <Link to={"/productdetails/" + val?._id} state={val}> */}
                                            <Link to={"/productdetails/" + val?._id} state={val}>

                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </Container>
      </div>
      <div className="login_model">
        <Modal
          width="50%"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <Login clickRow={clickRow} pageType={pageType} handlePage={handlePage} />
        </Modal>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    darkMode: state.darkMode,
    subscriptionitems: state.Subscription.subscriptionitems,
  }),
  { getsubscriptionrequest }
)(Subscriptionprice);
