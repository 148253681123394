import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import "../../assets/SCSS/Learning.scss";
import { Changepasswordauth } from "../Service/Changepasswordauth";
import { toast } from "react-toastify";
const Password = () => {
  const [email, setEmail] = useState({
    error: false,
    value: "",
  });
  const [password, setPassword] = useState();
  const [newpassword, setNewpassword] = useState();

  const Changepassword = async (e) => {
    e.preventDefault();
    Changepasswordauth.Changepassword(email, password, newpassword).then(
      (Res) => {
        //  console.log('first!!', Res)
        if (Res.Success) {
          toast.success(Res.message);
        } else {
          toast.error(Res.message);
        }
      }
    );
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
  };

  function EnableSave() {
    if (email && password && newpassword) {
      return (
        <div className="change_password_btn">
          <Button onClick={Changepassword}>Change Password</Button>
        </div>
      );
    } else {
      return (
        <div className="change_pswrd_blur mt-3 pb-3">
          <button type="submit" disabled>
            Change Password
          </button>
        </div>
      );
    }
  }

  return (
    <div className="profileDetails">
      <h3>Change Password</h3>
      <div className="password_form">
        <Form {...layout} layout="vertical">
          <Form.Item
            name="email"
            label="E-mail"
            className="form_item_pswrd"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              className="ProfileChangePasswordInput"
              required
              style={{ color: "white" }}
              maxLength="254"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="oldpassword"
            label="Old Password"
            className="form_item_pswrd"
            rules={[
              {
                required: true,
                message: "Please input your Old password!",
              },
            ]}
          >
            <Input.Password
              className="ProfileChangePasswordInput1"
              type="password"
              required
              maxLength="254"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="newpassword"
            label="New Password"
            minLength="6"
            className="form_item_pswrd"
            rules={[
              {
                required: true,
                message: "Please input your New password!",
              },
              {
                type: "string",
                min: 6,
                message: "Please input Minimum 6 digit",
              },
            ]}
          >
            <Input.Password
              className="ProfileChangePasswordInput1"
              type="password"
              required
              maxLength="254"
              onChange={(e) => {
                setNewpassword(e.target.value);
              }}
              dependencies={["password"]}
              hasFeedback
            />
          </Form.Item>
          <EnableSave />
        </Form>
      </div>
    </div>
  );
};

export default Password;
