import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { Card } from "antd";
import "../../assets/SCSS/Educationforeveryone.scss";
import group1 from "../../assets/Images/partner/client-logo1.png";
import group2 from "../../assets/Images/partner/client-logo2.png";
import group3 from "../../assets/Images/partner/client-logo3.png";
import group4 from "../../assets/Images/partner/client-logo4.png";
import group5 from "../../assets/Images/partner/client-logo5.png";
import group6 from "../../assets/Images/partner/client-logo6.png";
import group7 from "../../assets/Images/partner/client-logo7.png";
import group8 from "../../assets/Images/partner/client-logo8.png";
import group9 from "../../assets/Images/partner/client-logo9.png";
import group10 from "../../assets/Images/partner/client-logo10.png";
import group11 from "../../assets/Images/partner-l/client-logo1.jpg";
import group12 from "../../assets/Images/partner-l/client-logo2.jpg";
import group13 from "../../assets/Images/partner-l/client-logo3.jpg";
import group14 from "../../assets/Images/partner-l/client-logo4.jpg";
import group15 from "../../assets/Images/partner-l/client-logo5.jpg";
import group16 from "../../assets/Images/partner-l/client-logo6.jpg";
import group17 from "../../assets/Images/partner-l/client-logo7.jpg";
import group18 from "../../assets/Images/partner-l/client-logo8.jpg";
import group19 from "../../assets/Images/partner-l/client-logo9.jpg";
import group20 from "../../assets/Images/partner-l/client-logo10.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Educationforeveryone = (props) => {
  const datas = [
    {
      img: require("../../assets/Images/subway_world.png"),
      cardtitle: "Accustom to Champion Mindset",
      cardtext:
        "Become aware of your purpose, passion and personality",
      startbtn: "Start Now!",
    },

    // {
    //   img: require("../../assets/Images/charm_shield-tick (1).png"),
    //   cardtitle: "Enjoy Learning From Anywhere",
    //   cardtext:
    //     "We are delighted to give you an option to enjoy learning from anywhere in the world.",
    //   startbtn: "Start Now!",
    // },
    // {
    //   img: require("../../assets/Images/file-icons_brainfuck.png"),
    //   cardtitle: "Get trained by best  trainers",
    //   cardtext:
    //     "We have years of experience with more than 800 Entrepreneurs",
    //   startbtn: "Start Now!",
    // },
    // {
    //   img: require("../../assets/Images/ic_baseline-monitor.png"),
    //   cardtitle: "Collaborate with Champions",
    //   cardtext:
    //     "Work with a team of fellow Champions who are highly committed to Growth.",
    //   startbtn: "Start Now!",
    // },
    // {
    //   img: require("../../assets/Images/Untitled__1_-removebg-preview.png"),
    //   cardtitle: "Accustom to Champion Mindset",
    //   cardtext:
    //     "Connect with your purpose and find a balance between business and personal accomplishments",
    //   startbtn: "Start Now!",
    // },
    {
      img: require("../../assets/Images/smile_image-removebg-preview.png"),
      cardtitle: "Find your Joy and Create Joyous Teams",
      cardtext:
        "Find Joy in what you do - Know your leadership style and learn to play within your strength",
      startbtn: "Start Now!",
    },
    {
      img: require("../../assets/Images/image_5-removebg-preview.png"),
      cardtitle: "Organise your self and Organisation",
      cardtext:
        "Get your organisation Organised – Identify the right people to form right team",
      startbtn: "Start Now!",
    },
    {
      img: require("../../assets/Images/business model and strategy.png"),
      cardtitle: "Business Model & Strategies that fits",
      cardtext:
        "Redefine the business model and Devise winning strategies that work for your organisation",
      startbtn: "Start Now!",
    },
  ];


  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ],
  };
  const { darkMode } = props;
  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
    <div className="total_sec_education">
      <Container>
        <div className="company_logo">
          <Slider {...settings}>

            <div className="company_logo1">
              <img  className='darkgroup' src={darkMode?group1:group11} />
            </div>

            <div className="company_logo1">
              <img className='darkgroup' src={darkMode?group2:group12} />
            </div>

            <div className="company_logo2">
              <img className='darkgroup' src={darkMode?group3:group13} />
            </div>

            <div className="company_logo3">
              <img className='darkgroup' src={darkMode?group4:group14} />
            </div>

            <div className="company_logo4">
              <img className='darkgroup' src={darkMode?group5:group15} />
            </div>
            <div className="company_logo1">
              <img className='darkgroup' src={darkMode?group6:group16} />
            </div>
            <div className="company_logo1">
              <img className='darkgroup' src={darkMode?group7:group17} />
            </div>

            <div className="company_logo1">
              <img className='darkgroup' src={darkMode?group8:group18} />
            </div>
            <div className="company_logo1">
              <img className='darkgroup' src={darkMode?group9:group19} />
            </div>
            <div className="company_logo1">
              <img className='darkgroup' src={darkMode?group10:group20} />
            </div>

          </Slider>
        </div>
        <div className="slider_content">
          <h6>TRULY PRACTICAL BUSINESS EDUCATION</h6>
          <h4>
            Learn how small companies get<br /> transformed into corporates
          </h4>
          <p>
            Fasten your seatbelt, sit back and be ready to take off!
          </p>
        </div>

        <div className="education_total_card">
          <Row className="education-card">
            {datas.map((a) => {
              return (

                <Col md="3" className="r-edu education_card_main" >

                  <Card
                    className="education_card"
                  >
                    <CardBody className="card_education">
                      <img alt="Sample" className="imageContainer" src={a.img} />
                      <CardTitle className="cardtitle_education">
                        {a.cardtitle}
                      </CardTitle>
                      <CardSubtitle className="cardsubtitle_education">
                        {a.cardtext}
                      </CardSubtitle>
                      <CardText className="cardtext_education">
                        {a.startbtn}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}

          </Row>
        </div>
      </Container>
    </div>
    </div>
  );
};

export default Educationforeveryone;
