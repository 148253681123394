import { Card, Row, Col, Radio, Button } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";


const Section7 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult.Operations[0]});
  console.log("finalResult", finalResult, getValues());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const question = [
    {
      id: 1,
      qustion: "Reorder level",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Don't have defined reorder levels",
      right: "Well defined reorder levels based on inventory turns",
      identifier: "reorder_level",
    },
    {
      id: 2,
      qustion: "Discontinued business/customers",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not have/track discontinued business/customers",
      right: "We measure % of discontinued business and take actions",
      identifier: "discontinued_business_customers",
    },
    {
      id: 3,
      qustion:
        "Efficiencies (For Mfrs - yield/people productivity, for Sales - sales value per staff)",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not track efficienc",
      right: "We track efficiencies at regular intervals and take actions",
      identifier: "efficiencies",
    },
    {
      id: 4,
      qustion: "Customer returns",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not track / measure customer returns",
      right: "We measure customer returns and take corrective actions",
      identifier: "customer_returns",
    },
    {
      id: 5,
      qustion: "Internal failures and defects",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not track / measure Internal failures and defects",
      right:
        "We measure and take corrective actions for Internal failures and defects",
      identifier: "internal_failures_defects",
    },
    {
      id: 6,
      qustion: "Supplier Relationship and Development",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Transactional Relationship",
      right:
        "We have identified long term suppliers to work as partner and we develop them  for changing customer need with supplier evaluation system",
      identifier: "supplier_relationship_development",
    },
  ];
  const onChangeData = (e) => {
    setValue(e.target.name, e.target.value);
  };
  const values = getValues();
  let sum = 0;
  
  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const value = parseInt(values[key], 10);
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }
  
  const result = (sum / 6).toFixed(2);
  
  console.log(result,"asdfghjk");
  const handleSubmitValue = () => {
    handleResult({
      ...finalResult,
      Operations: [{
        result: result,
        ...getValues(),
      }]

    });
    handleNext();
  };
  const previousPage = () => {
    handleResult({
      ...finalResult,
      Operations: [{
        result: result,
        ...getValues(),
      }]

    });
    console.log("first", {
      ...finalResult,
      Operations: [{
        result: result,
        ...getValues(),
      }]

    });
    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3">
          <Col>
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer">
                {index < 1 && <div className="header-text">Operations</div>}

                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}<span style={{color:"red"}}>*</span>
                  </div>
                  <div className="docusContainerLabel">
                    <div className="docusLeftContent">{ques.left}</div>
                    {[1, 2, 3, 4, 5].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"

                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>Back</Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p className="progressPage" style={{ width: "-webkit-fill-available" }}>
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusNextbtn"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>{" "}
    </div>
  );
};

export default Section7;
