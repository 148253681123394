import { CoursemoduleTypes } from '../actions/coursemodule'

const INITIAL_STATE = {
    coursemoduleitems: []
};

export default function coursemodule(state = INITIAL_STATE, action) {
    switch (action.type) {

        case CoursemoduleTypes.GET_COURSEMODULE_SUCCESS: {
            return {
                ...state,
                coursemoduleitems: action?.payload?.coursemoduleitems
            }
        }


        default: {
            return state;
        }
    }
}