import React from 'react'
import Loginpage from '../components/Loginpage/Loginpage'

const Login = ({clickRow,pageType,handlePage,page}) => {
    console.log('firsthandlePage', handlePage)
    console.log(page,'page')
    return (
        <>
            <Loginpage clickRow={clickRow} pageType={pageType} handlePage={handlePage} page={page}/>
        </>
    )
}

export default Login