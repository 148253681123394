import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Form, Input, Space } from "antd";

import "../../assets/SCSS/Contactus.scss";
import Vector8 from "../../assets/Images/contactUsImage1.png";
import Vector11 from "../../assets/Images/ContactUsImage2.png";
import axios, { Axios } from "axios";
import { toast } from "react-toastify";
import { ElearningAxios } from "../../index";
import TextArea from "antd/lib/input/TextArea";
import { ThemeContext } from "../../utils/ThemeProvider";

const Contactus = (props) => {
  // const { darkMode } = props;
  const { darkMode } = useContext(ThemeContext);

  const [form] = Form.useForm();

  useEffect(() => {
    ElearningAxios("/api/cms")
      .then((response) => {
        setContacts(response.data.data);
        setError(null);
      })
      .catch(setError);
  }, []);

  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState(null);
  const layout = { labelCol: { span: 11 }, wrapperCol: { span: 30 } };

  const contactUs = async (Contactdetails) => {
    // await ElearningAxios.post("/api/enquiry", Contactdetails).then(
      await ElearningAxios.post("api/user/contactus", Contactdetails).then(

      (res) => {
        toast.success("Your details added successfully");
      },
      (error) => {
        toast.error("Please Enter Your details");
      }
    );
  };

  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <section className="detail_contactus">
          <Container>
            <div className="start_con">
              <h1>Contact us</h1>
            </div>
            <Row>
              <Col md="6" className="total_contact">
                <Form
                  form={form}
                  {...layout}
                  className="contact_data"
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={contactUs}
                >
                  <Form.Item
                    className="form_item"
                    name="name"
                    label={
                      <label style={{ color: darkMode ? "#fff" : "black" }}>
                        {" "}
                        Name
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 22 }}
                    rules={[
                      {
                        type: "string",
                        message: "Please input your First Name",
                      },
                      {
                        required: true,
                        message: "Please enter First Name",
                      },
                    ]}
                  >
                    <Input className="ContactUsPageInputField" />
                  </Form.Item>
                  <Form.Item
                    className="form_item"
                    name="email"
                    label={
                      <label style={{ color: darkMode ? "#fff" : "black" }}>
                        E-mail id
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 22 }}
                    rules={[
                      {
                        type: "email",
                        message: "Please input your Email",
                      },
                      {
                        required: true,
                        message: "Please enter Email",
                      },
                    ]}
                  >
                    <Input className="ContactUsPageInputField" />
                  </Form.Item>
                  <Form.Item
                    className="form_item"
                    name="phonenumber"
                    label={
                      <label style={{ color: darkMode ? "#fff" : "black" }}>
                        Mobile
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 22 }} 
                    rules={[
                      {
                        type: "string",
                        message: "Please input your Mobile",
                      },
                      {
                        required: true,
                        message: "Please enter Mobile",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Mobile number must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                     className="ContactUsPageInputField"
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    className="form_item"
                    name="message"
                    label={
                      <label style={{ color: darkMode ? "#fff" : "black" }}>
                        Message
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 22 }}
                    rules={[
                      {
                        type: "string",
                        message: "Please input your message",
                      },
                      {
                        required: true,
                        message: "Please enter message",
                      },
                    ]}
                  >
                    <TextArea className="ContactUsPageInputField" />
                  </Form.Item>
                  <Form.Item>
                    <Button className="contact_submit" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col md="6">
                <div className="contactTop">
                  <img src={Vector8}></img>
                </div>
                <div className="contactBottom">
                  <img src={Vector11}></img>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Contactus;
