import React, { useContext, useState, useEffect } from "react";
import Header4 from "../components/Layout/Header4";
import { Row, Col, Container } from "reactstrap";
import "../assets/SCSS/MycourseVideoPage.scss";
import CoursesVideo1 from "../assets/videos/Promo LSKannan 1.mp4";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from "../utils/ThemeProvider";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import kannanVideo from "../assets/videos/dhamu jew.mp4";

const MyCourseModules = (params) => {
  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);
  const location = useLocation();
  console.log("firstlocation", location);

  const [watchedDuration, setWatchedDuration] = useState(0);

  const handleTimeUpdate = (event) => {
    const currentTime = event.target.currentTime;
    setWatchedDuration(currentTime);
  };
  const [UserList, setUserList] = useState([]);

  console.log("firstUserList", UserList);
  // const [course, setCourse] = useState({});
  const [popup, setPopup] = useState("");

  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);
  const [UserId, setUserId] = useState([]);

  useEffect(() => {
    const getUserlist = async () => {
      console.log("one");
      axios.get(`api/user/${parsedId._id}`).then((res) => {
        setUserId(res.data.data);
      });
    };
    getUserlist();
  }, []);
  const user_id = UserId[0]?.user_id;

  useEffect(() => {
    const getUserlist = async () => {
      try {
        const lessonId = location.pathname.split("MycourseModule/")[1];
        console.log("lessonId", lessonId);

        const response = await axios.get(
          `/api/user/purchase-course/${parsedId._id}`
        );
        console.log("firstresponse", response);
        const purchaseCourseData = response.data.purchase_course;
        const courseModules = purchaseCourseData.flatMap(
          (item) => item.courseModules
        );

        const userData = courseModules.find((doc) => doc._id === lessonId);
        console.log("userData", userData);

        setUserList(userData);
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error(error);
      }
    };

    getUserlist();
  }, []);
  const videoList = UserList?.video || [];
  const [selectedVideo, setSelectedVideo] = useState(null);
  console.log("firstbbbbb", selectedVideo);
  // Function to handle link click event
  const handleVideoLinkClick = (videoItem) => {
    setSelectedVideo(videoItem);
    console.log("firstennoda", videoItem);
  };
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_DOMAIN}/api/download?key=${selectedVideo?.video}`
        );
        console.log("first333", res);
        const data = await res.json();
        setPopup(data);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchVideoData();
  }, [selectedVideo]);
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  const handleDownloadClick = () => {
    alert("Downloading this video is not permitted.");
  };
  const handleVideoPlay = () => {
    const watermarkText1 = document.querySelector(".watermarkMycourseText1");
    const watermarkText2 = document.querySelector(".watermarkMycourseText2");

    watermarkText1.style.display = "block";
    watermarkText2.style.display = "block";
  };


  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <Header4 handleToggle={toggleMode} />
        <div className="MyCoursesVideoContentMargin">
          <div className="MyCoursePurchaseDetailsBodyContainer">
            <div className="MyCoursePurchaseContainer">
              <div className="MyCoursePurchaseDetailsBody mb-3">
                <Row className="MyCourseModulesVideoConTentRow p-5 ">
                  <Col lg={12} md={12}>
                    <div className="MyCourseModulesVideosListatLeft p-4">
                      <center>
                        <h2 className="MycourseModulesVideoHeading" style={{ color: "white", fontWeight: "bolder" }}>
                          List of Videos
                        </h2>
                        {videoList.map((videoItem) => (
                          <div
                            key={videoItem._id}
                            style={{
                              cursor: "pointer",
                              fontWeight:
                                selectedVideo?._id === videoItem._id
                                  ? "bold"
                                  : "normal",
                              color:
                                selectedVideo?._id === videoItem._id
                                  ? "#4FC0D0"
                                  : "white",
                            }}
                            onClick={() => handleVideoLinkClick(videoItem)}
                          >
                            <div className="MycourseModulesVideotagName" style={{ fontSize: "21px" }}>
                              {videoItem.video_tag_name}
                            </div>
                          </div>
                        ))}
                      </center>
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div>
                      <video
                        width="100%"
                        controls
                        controlsList="nodownload"
                        onContextMenu={handleContextMenu}
                        // onClick={handleDownloadClick}
                        className="MyCouresModulesVideo"
                        src={popup.url}
                        type="video/mp4"
                        onPlay={handleVideoPlay}
                      ></video>
                      <div className="WatermarkingForMyCourses"
                      >
                        <div
                          className="watermarkMycourseText1"
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "white",
                            opacity: 0.5,
                            marginBottom: "10px",
                            display: "none",
                          }}
                        >
                          Business Champions
                        </div>
                        <div
                          className="watermarkMycourseText2"
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "white",
                            opacity: 0.5,
                            display: "none",
                          }}
                        >
                          {user_id}
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col lg={4} className="mt-3">
                        <div className="MycourseModulesVideoHeading"
                          style={{
                            fontWeight: "700",
                            fontSize: "25px",
                            color: darkMode ? "white" : "black",
                          }}
                        >
                          Description :
                        </div>
                      </Col>
                      <Col lg={8} className="mt-4">
                        <p className="MycourseModulesVideotagName"
                          style={{
                            fontWeight: "600",
                            color: darkMode ? "white" : "black",
                          }}
                        >
                          {UserList?.description}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCourseModules;
