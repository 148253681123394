import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { isLogin } from '../Service/authentication';

const PrivateRoute = ({ component: Component, ...rest }) => {

    let currentUser = null; // determine if authorized, from context or however you're doing it

    currentUser = isLogin()


    return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;