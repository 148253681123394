import React from 'react'
import Signuppage from '../components/Signup/Signuppage'
const SignupPage = () => {
  return (
    <div>
       <Signuppage/>
    </div>
  )
}

export default SignupPage