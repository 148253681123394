/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { digitaloceanspacesurl } from "../../api/constant";
import "../../assets/SCSS/About.scss";
import Vector6 from "../../assets/Images/aboutus.png";
import Vector5 from "../../assets/Images/vision.png";
import Vector8 from "../../assets/Images/people.png";
import Learn1 from "../../assets/Images/camp1.svg";
import Learn2 from "../../assets/Images/camp2.svg";
import Learn3 from "../../assets/Images/camp3.svg";
import Learn4 from "../../assets/Images/busi3.svg";
import Learn5 from "../../assets/Images/busi2.svg";
import Learn6 from "../../assets/Images/busi1.png";
import { getaboutusrequest } from "../../actions/aboutus";
import { connect } from "react-redux";
import { ThemeContext } from "../../utils/ThemeProvider";
import { Link } from "react-router-dom";
import axios from "axios";

const Aboutus = (props) => {
  const [aboutUsList, setAboutUsList] = useState([]);

  const { darkMode } = useContext(ThemeContext);
  console.log("darkMode", darkMode);
  useEffect(() => {
    props.getaboutusrequest();
  }, []);

  const aboutusdata = props.Aboutus.aboutusitems;
  useEffect(() => {
    const getAboutusList = async () => {
      console.log("one");
      axios.get("/api/cms").then((res) => {
        console.log("res", res);
        setAboutUsList(res.data.data);
        console.log("re3data", res.data.data);
      });
    };
    getAboutusList();
  }, []);
  const extractFirstTwoParagraphs = (text) => {
    if (!text) return "";
    const paragraphs = text.split("<p>");
    if (paragraphs.length >= 3) {
      return `<p>${paragraphs[1]}</p><p>${paragraphs[2]}</p>`;
    } else if (paragraphs.length === 2) {
      return `<p>${paragraphs[1]}</p>`;
    }
    return "";
  };
  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <div className="about">
        <div className="container">
          <Row className="mt-5">
            <Col lg={13} md={13} style={{display:"flex",alignItems:"center"}}>
              <img
                className="TopContentLeftImage"
                src={digitaloceanspacesurl + aboutUsList[1]?.abutImage}
                alt="AboutUs Image"
              ></img>
            </Col>
            <Col lg={11} md={11}>
              <h4 className="aboutmiddle-left-head">
                {aboutUsList[1]?.aboutTitle}
              </h4>
              {console.log("first112", aboutUsList)}
              <p style={{ color: darkMode ? "white" : "black" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutUsList[1]?.aboutDescription,
                  }}
                />
              </p>
              {/* <p style={{ color: darkMode ? "white" : "black" }}>
                Business Champions Program empowers and cultivates
                entrepreneurs, enabling them to expedite their path to success.
              </p>
              <p style={{ color: darkMode ? "white" : "black" }}>
                We are committed to equipping entrepreneurs with the essential
                resources and guidance to flourish.
              </p>
              <p style={{ color: darkMode ? "white" : "black" }}>
                Join us on this journey towards manifesting your entrepreneurial
                aspirations!
              </p> */}

              <Link to={"/contactusdetails"}>
                <div className="GetTouchButtonInAbout">
                  <span className="signinbtn touch">Get in Touch</span>
                </div>
              </Link>
            </Col>
          </Row>

          <Row className="mt-5 mb-5">
            <Col lg={12} md={12}>
              <h4 className="aboutmiddle-left-head">{aboutUsList[2]?.aboutTitle}</h4>
              <p style={{ color: darkMode ? "white" : "black" }}>
              <div
                  dangerouslySetInnerHTML={{
                    __html: aboutUsList[2]?.aboutDescription,
                  }}
                />
              </p>
              {/* <p style={{ color: darkMode ? "white" : "black" }}>
                Furthermore, we are dedicated to improving the lives of 100,000
                employees by promoting the universal truth that "Happy Employees
                bring Happy Customers."
              </p> */}
            </Col>
            <Col lg={12} md={12} style={{display:"flex",alignItems:"center"}}>
              <img className="SecondContentImageSection" src={digitaloceanspacesurl + aboutUsList[2]?.abutImage}
                alt="OurVision Image"></img>
            </Col>
          </Row>
          <Row className="thirdSectionContentContiner">
            <Col lg={8} md={12} style={{display:"flex",alignItems:"center"}}>
              <img className="ThirdSectionImageSection"   src={digitaloceanspacesurl + aboutUsList[3]?.abutImage}
                alt="AboutUs Image"></img>
            </Col>
            <Col lg={16} md={12}>
              <Row>
                <Col lg={24} className="thirdContentAboutMiddlePara">
                  <h4 className="aboutmiddle-left-head">{aboutUsList[3]?.aboutTitle}</h4>
                  <p style={{ color: darkMode ? "white" : "black" }}>
                  <div
                  dangerouslySetInnerHTML={{
                    __html: aboutUsList[3]?.aboutDescription,
                  }}
                />
                  </p>
                  {/* <p style={{ color: darkMode ? "white" : "black" }}>
                    We have conducted extensive research with nearly 1000
                    entrepreneurs and furthered our knowledge by working closely
                    with more than 100 of them. In doing so, we have identified
                    6 roadblocks that prevent small companies from becoming
                    corporates, and we have developed consulting models to
                    tackle these obstacles.
                  </p> */}
                </Col>
                {/* <Col lg={12} className="thirdContentAboutLastPara">
                  <p style={{ color: darkMode ? "white" : "black" }}>
                    Our highly successful consulting model has been adapted into
                    a cutting-edge online program called the Business Champions
                    Program. With a proven track record of over eight years, we
                    guarantee a remarkable 20% increase in growth during the
                    first year, a staggering 100% increase in growth in just
                    three years, and an incredible 1000% increase within seven
                    years.
                  </p>
                  <p style={{ color: darkMode ? "white" : "black" }}>
                    Trust us; you'll see the results you've been searching for.
                  </p>
                  <p style={{ color: darkMode ? "white" : "black" }}>
                    Since it is online learning, business leaders can choose
                    their own pace and sequence. They can also customise and opt
                    for the required modules.
                  </p>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row className="MiddleRowContentContainer">
            <div className="aboutmiddle-left cards">
              <h4 className="aboutmiddle-left-head mt-3">
                What is Business Champions Program
              </h4>
              <div className="aboutmiddle-left-para champ">
                <div className="MiddleContentContainerRow">
                  <Col lg={8} className="MiddleContentContainerCol busdiv">
                    <div style={{ color: darkMode ? "white" : "black" }}>
                      <img
                        className="c_icon"
                        // src={darkMode ? Learn1 : Learn4}
                        src={digitaloceanspacesurl + aboutUsList[7]?.abutImage}
                      />
                      {/* <br /> */}
                      <div class="remove-div"
                        dangerouslySetInnerHTML={{
                          __html: aboutUsList[7]?.aboutDescription,
                        }}
                      />
                      {/* Our online learning program comprises 12 modules that
                      tackle six common roadblocks to growth. Each module is
                      designed to provide 70% of the learning session, with the
                      remaining 30% dedicated to practical application through
                      the use of customised workbooks. */}
                    </div>
                  </Col>
                  <Col lg={8} className="MiddleContentContainerCol busdiv">
                    <div style={{ color: darkMode ? "white" : "black" }}>
                      <img
                        className="c_icon"
                        // src={darkMode ? Learn2 : Learn5}
                        src={digitaloceanspacesurl + aboutUsList[8]?.abutImage}
                      />
                      <br />
                      <div class="remove-div"
                        dangerouslySetInnerHTML={{
                          __html: aboutUsList[8]?.aboutDescription,
                        }}
                      />
                      {/* Participants will also access proprietary tools such as
                      JoyQ - an entrepreneur's personality assessment tool, and
                      the CSense Wheel of Business Assessment. */}
                    </div>
                  </Col>
                  <Col lg={7} className="MiddleContentContainerCol busdiv">
                    <div style={{ color: darkMode ? "white" : "black" }} >
                      <img
                        className="c_icon"
                        // src={darkMode ? Learn3 : Learn6}
                        src={digitaloceanspacesurl + aboutUsList[9]?.abutImage}
                      />
                      {/* <br /> */}
                      <div class="remove-div"
                        dangerouslySetInnerHTML={{
                          __html: aboutUsList[9]?.aboutDescription,
                        }}
                      />
                     {/* {aboutUsList[9]?.aboutDescription} */}
                      {/* The program consists of a plethora of exclusive management
                      concept videos and materials. There will be monthly live
                      online sessions and yearly physical meetings. The added
                      advantage is to work in a community of growth-minded
                      entrepreneurs. */}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </Row>
          <Row className="aboutmiddle-left-para">
            <h4 className="aboutmiddle-left-head mt-3">Why BCP Academy?</h4>
            <Col>
            <div
                        dangerouslySetInnerHTML={{
                          __html: aboutUsList[10]?.aboutDescription,
                        }}
                      />
              {/* <p style={{ color: darkMode ? "white" : "black" }}>
                We've always been ahead of the curve in empowering leaders with
                the tools to constantly innovate and create their destinies. Our
                concepts are not built on theoretical management concepts. We
                have proven, practical, and SME-centric business and management
                models.
              </p>
              <p style={{ color: darkMode ? "white" : "black" }}>
                We discovered answers to questions that no one else had thought
                of. We have proprietary models such as
              </p>
              <ul>
                <li>
                  Four personality types and relevant strengths and weaknesses
                  are directly applicable to business.
                </li>
                <li className="mt-3">
                  CSense Wheel of Business – SME organisational health
                  assessment across eight axes.
                </li>
                <li className="mt-3">
                  Organisation's Lifeline and suitable strategies.
                </li>
                <li className="mt-3">
                  Business Cycle Model – demonstrating the number of departments
                  an SME should have and their purpose.
                </li>

                <li className="mt-3">
                  Factors that can deliver 5X business growth.
                </li>

                <li className="mt-3">
                  5-point strategy for profit maximisation
                </li>
                <li className="mt-3">The Commodity curve of business.</li>

                <li className="mt-3">SME-specific KRAs and KPIs.</li>
              </ul> */}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default connect((Aboutus) => Aboutus, {
  getaboutusrequest,
})(Aboutus);
