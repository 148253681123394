import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, CardBody, CardSubtitle, CardText } from "reactstrap";
import { Modal } from "antd";
import "../../assets/SCSS/Homepage.scss";
import cardimg from "../../assets/Images/redarrow 100px.png";
import cardimg2 from "../../assets/Images/bluearrow 100px.png";
import { ElearningAxios } from "../../index";
import card1lessons from "../../assets/Images/card1lessonsblue.png";
import card1lessonsRed from "../../assets/Images/card1lessons.png";
import section1_design1 from "../../assets/Images/section1_design1.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { homedata } from "../../Pages/Home";
import Login from "../../Pages/Login";
import { digitaloceanspacesurl } from "../../api/constant";

const Homepage = (props) => {
  const [mycourse, setMycourse] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let currentUser = localStorage.getItem("currentUser");
  const contactsval = useContext(homedata);
  const targetId = "64cb44473ad2155bed484fc4";
  const filteredData = contactsval.filter((item) => item._id === targetId);
  const [pageType, setPageType] = useState("LOGIN");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setPageType("LOGIN");

  };
  const handlePage = (value) => {
    setPageType(value);
  };
  const clickRow = (status) => {
    if (status === "cancel") {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    ElearningAxios("/api/subscriptiontype")
      .then((response) => {
        setMycourse(response.data.data);
      })
      .catch(setError);
  }, []);

  const level1Data = mycourse.find((item) => item.name === "Level 1");
  const level2Data = mycourse.find((item) => item.name === "Level 2");

  const { darkMode } = props;

  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <div className="section1_total">
        <div className="section1_background_image">
          <Container>
            <div>
              <Row className="r-banner">
                <Col md="5" className="r-btextalign">
                  <>
                    <h1 className="section1_left_heading">
                      {filteredData && filteredData[0] && filteredData[0].bannerHeading}
                    </h1>
                    <h6 className="section1_left_subheading">
                      {filteredData && filteredData[0] && filteredData[0].bannerSubHeading}
                    </h6>
                  </>
                  <div className="banner-btn ">
                    {currentUser ? (
                      <></>
                    ) : (
                      <>
                        <Button className="join_btn R-join" onClick={showModal}>
                          Join Today
                        </Button>
                      </>
                    )}
                    <Modal
                      width="50%"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okButtonProps={{ hidden: true }}
                      cancelButtonProps={{ hidden: true }}
                    >
                      <Login clickRow={clickRow} pageType={pageType}
                        handlePage={handlePage} 
                        page="home" />
                    </Modal>
                    <Link to="/subscriptionamount">
                      <Button className="subscribe_btn R-join">
                        View Products
                      </Button>
                    </Link>
                  </div>
                </Col>
                <Col md="7" className="r-bagalign">
                  <div className="card_bag_image">
                    <img src={section1_design1} alt="Section 1 Design 1" />
                  </div>
                  <div className="total_card">
                    <Row className="total_val">
                      <Col md="6" className="card1">
                        <Card className="card1_main">
                          <img style={{ width: "100%" }} alt="CardImage" src={(digitaloceanspacesurl + (filteredData && filteredData[0] && filteredData[0].cardimageone)) || ""} />
                          <CardBody>
                            <div className="card1_body">
                              <div className="card1_body_sub">
                                <img alt="Sample" src={cardimg} />
                              </div>
                              <CardSubtitle className="card_subtitle" tag="h6">
                                {filteredData && filteredData[0] && filteredData[0].cardheadingone}
                              </CardSubtitle>
                              <CardText className="cardtext">
                                {filteredData && filteredData[0] && filteredData[0].carddescriptionone}
                              </CardText>
                              <div className="cardtext_main">
                                <div className="cardtext_sub">
                                  <img
                                    src={
                                      darkMode ? card1lessons : card1lessonsRed
                                    }
                                    alt="Card1 Lessons"
                                  />
                                  <CardText className="card_count">
                                    {level1Data?.courseModules.length || 0} modules
                                  </CardText>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6" className="card3">
                        <Card className="card2">
                          <img style={{ width: "100%" }} alt="CardImage" src={(digitaloceanspacesurl + (filteredData && filteredData[0] && filteredData[0].cardimagetwo)) || ""} />
                          <CardBody>
                            <div className="card2_body">
                              <div className="card2_body_sub">
                                <img alt="Sample" src={cardimg2} />
                              </div>
                              <CardSubtitle className="card_subtitle" tag="h6">
                                {filteredData && filteredData[0] && filteredData[0].cardheadingtwo}
                              </CardSubtitle>
                              <CardText className="cardtext">
                                {filteredData && filteredData[0] && filteredData[0].carddescriptiontwo}
                              </CardText>
                              <div className="cardtext_main">
                                <div className="cardtext_sub">
                                  <img
                                    src={
                                      darkMode ? card1lessons : card1lessonsRed
                                    }
                                    alt="Card1 Lessons"
                                  />
                                  <CardText className="card_count">
                                    {level2Data?.courseModules.length || 0} modules
                                  </CardText>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}; 

export default Homepage;
