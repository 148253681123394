import React from 'react'
import Mylearning from '../components/Mylearning/Mylearning'
import Header1 from "../components/Layout/Header1"
const Learning = () => {
  return (
    <>
      <Header1/>
      <Mylearning/> 
    </>
  )
}

export default Learning