import React, { useState, useEffect } from "react";
import { Table,Button } from "antd";
import { getordermodulerequest } from "../../actions/ordermodule";
import { connect } from "react-redux";
import moment from "moment";

const Payment = () => {
  let userData = JSON.parse(localStorage.getItem("userDetail"));
  const [orderpayment, setOrderpayment] = useState([]);

  useEffect(() => {
    if (userData?._id) {
      fetch(process.env.REACT_APP_DOMAIN + '/api/user/payment-history/' + userData?._id)
        .then(res => res.json())
        .then(
          (result) => {
            setOrderpayment(result.paymentDetails)
          }
        );
    }
  }, []);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'order_id',
      key: 'order_id',
      render: (_, record, index) => (
        <>{(index + 1) + ((currentPage - 1) * 5)}</>
      ),
    },
   
    {
      title: 'Price',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a?.createdAt)?.unix() - moment(b?.createdAt)?.unix(),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => {
        let d = moment.utc(text).format("MM/DD/YYYY");
        return d;
      },
    },
    {
      title: 'Status',
      dataIndex: 'payments',
      key: 'payments',
      render: (_, record) => {
        const { status } = record?.payments || {};
  
        if (status === 'Credit') {
          return (
           <div style={{color:"green",fontWeight:"bolder"}}>Credit</div>
          );
        }
  
        return (
         <div style={{color:"#E7B10A",fontWeight:"bolder"}}>Pending....</div>
        );
      },
    },
  ];
  return (
    <div className="profileDetails">
      <div>
        <h3>Payment</h3>
        <div className="profile_table">

          <Table className="payment_table" pagination={{
            onChange(current) {
              setCurrentPage(current)
            },
            defaultPageSize: 3
          }}
            dataSource={orderpayment} columns={columns} />;
        </div>

      </div>
    </div>
  );
};

export default connect((Ordermodule) => Ordermodule, {
  getordermodulerequest,
})(Payment);
