import React from "react";
// import Header1 from '../../components/Layout/Header1';

import Section1 from "./Sections/Section1";
import { useState } from "react";
import { Card } from "antd";
import "./AssesmentPage.scss";
import Section2 from "./Sections/Section2";
import Section3 from "./Sections/Section3";
import Section4 from "./Sections/Section4";
import Section5 from "./Sections/Section5";
import Section6 from "./Sections/Section6";
import Section7 from "./Sections/Section7";
import Section8 from "./Sections/Section8";
import Section9 from "./Sections/Section9";
import { useLocation } from "react-router-dom";
const AssesmentPage = () => {
  const [sectionIndex, setSectionIndex] = useState(1);
  const [progress, setProgress] = useState(0);
  const [ sum,setsum]= useState({
    section1:"",
    section2:"",
    section3:"",


})
  const [finalResult, setFinalResult] = useState({
    name: "",
    emailid: "",
    organisation: "",
    contactnumber: "",
    leadership: [
      {
        businessowner: null,
        targets_achievement: null,
        Value_proposition: null,
        emerging_trends: null,
        vision_boals: null,
        organisational_structure: null,
        learning_organisation: null,
      },
    ],
    marketing: [
      {
        brand_identity: null,
        digital_presence: null,
        target_customer: null,
        potential_market: null,
        marketing_effectiveness: null,
        products_services: null,
        differentiates_company: null,
      },
    ],
    product_process_development: [
      {
        responsible_innovations: null,
        Customer_complaints: null,
        sample_analysis: null,
        newproduct_service_pipeline: null,
        customer_feedback: null,
        secret_of_your_success: null,
      },
    ],
    People_development: [
      {
        priority: null,
        organisation_structure: null,
        employee_career_roadmap: null,
        average_tenure: null,
        competency_mapping: null,
      },
    ],
    Sales: [
      {
        average_collection_duration: null,
        salesplan_visitplan: null,
        employee_career_roadmap: null,
        incoming_enquiries: null,
        stores_inventory: null,
        Sales_ratios: null,
        shop_marketing: null,
      },
    ],
    Operations: [
      {
        reorder_level: null,
        discontinued_business_customers: null,
        efficiencies: null,
        customer_returns: null,
        internal_failures_defects: null,
        supplier_relationship_development: null,
      },
    ],
    admin_welfare: [
      {
        employee_welfare: null,
        employee_feedback_grievances: null,
        data_integrity: null,
        parking_facilities_customers: null,
        license_renewal_calendar: null,
        payment_employees: null,
        maintenance_plan: null,
      },
    ],
    Accounts: [
      {
        Financial_discipline: null,
        inventory_turnover_ratio: null,
        On_time_payment: null,
        annual_budget_provisions: null,
        monthly_pl: null,
      },
    ],
    value:[

    ]
  });
  const handleNext = () => {
    setSectionIndex(sectionIndex + 1);
  };
  const handleBack = () => {
    setSectionIndex(sectionIndex - 1);
  };
  const location = useLocation();
  console.log("location", location);
  return (
<div>
{/* <Header1   /> */}

    <div className="docuspagBody">
      {" "}
      <div className="DocusHeadingContainer p-3">
        <Card className="headerofWheelBusiness ">
          <div className="ms-3">
            <h2 style={{ fontWeight: "700" }}>Wheel of Business</h2>
            <div>Lets see whether your organisation is designed to Grow</div>
          </div>
          <hr></hr>
          <div style={{ color: "rgb(217,48,37)" }}>
            * Indicates required question
          </div>
        </Card>
      </div>
      {sectionIndex == 1 && (
        <Section1
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 2 && (
        <Section2
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 3 && (
        <Section3
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 4 && (
        <Section4
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 5 && (
        <Section5
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 6 && (
        <Section6
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 7 && (
        <Section7
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 8 && (
        <Section8
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
        />
      )}
      {sectionIndex === 9 && (
        <Section9
          handleBack={handleBack}
          handleNext={handleNext}
          handleResult={setFinalResult}
          finalResult={finalResult}
          progressValue={sectionIndex}
          id={location.state.id}
        />
      )}
    </div>
    </div>
  );
};

export default AssesmentPage;
