import { Container, Row, Col, Button } from "reactstrap";
import useDarkMode from "../Pages/useDarkMode";
import "../assets/SCSS/Payment.scss";
import { Modal, ModalBody } from "reactstrap";
import Successmessage from "../assets/Images/success.png";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import displayRazorpay from "../utils/PaymentGatway";
import { useParams } from "react-router-dom";
import jwt from "jwt-decode";
import Header1 from "../components/Layout/Header1";
import Signuppage from "../components/Signup/Signuppage";
import razorpay_con from "../assets/Images/instamojadarkmode-removebg-preview.png";
import razorpay_white from "../assets/Images/insta1-removebg-preview.png";
import axios from "axios";

import { Radio } from "antd";
import { ThemeContext } from "../utils/ThemeProvider";
import { useForm } from "react-hook-form";

const Paymentdetails = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({});
  // const contacthandele = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setContactdetails((prev) => {
  //     return { ...prev, [name]: value };
  //   });
  // };
  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);
  console.log("parsedIddd", parsedId);
  const location = useLocation();
  console.log("first", location);
  const handleContactDetailsChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevContactDetails) => ({
      ...prevContactDetails,
      [name]: value,
    }));
  };

  let logintoken = JSON.parse(localStorage.getItem("currentUser"));
  let decode;
  try {
    decode = jwt(logintoken);
  } catch (error) {}

  const [state, setState] = useState(decode);
  const [course, setCourse] = useState({});
  const [paymentId, setPaymentId] = useState(null);
  const [batchId, setBatchId] = useState("");

  console.log("course", course);
  let token = localStorage.getItem("currentUser");

  const { id } = useParams();
  console.log("id", id);
  useEffect(() => {
    if (id) {
      fetch(process.env.REACT_APP_DOMAIN + "/api/subscriptiontype/" + id)
        .then((res) => res.json())
        .then((result) => {
          setCourse(result?.data[0]);
          console.log("payMent", result?.data[0]);
        });
    }
  }, [id]);
  // useEffect(() => {
  //   getBatchDropdown();
  // }, [paymentId]);
  // const getBatchDropdown = async () => {
  //   try {

  //     const response = await axios.get(`http://143.198.66.255/api/payment-status?payment_id=${paymentId}`);
  //     console.log("res@@@", response);
  //     setPaymentId(response);
  //     console.log('first444', response)
  //   } catch (error) {
  //     console.error("Error occurred while fetching data:", error);
  //   }
  // };

  const handleRedirect = async (data) => {
    try {

      const response = await axios.post("api/createpayment", {
        buyer_name: data.buyer_name,
        amount: course?.price,
        email: data.email,
        phone: data.phone,
        course_id: course?._id,
        user_id: parsedId._id,
        course_name: course?.name,
      });

      const paymentUrl = response.data.response.payment_request.longurl;
      console.log("paymentUrl", paymentUrl);
      // window.location.href = paymentUrl;
      const paymentId = response.data.response.payment_request.id;
      console.log("firstffffffffff", response.data.response.payment_request.id);
      setPaymentId(paymentId);
      console.log("paymentId", paymentId);

      // const paymentStatusResponse = await axios.get(
      //   `http://143.198.66.255/api/payment-status?payment_id=${paymentId}`
      // );
      
      window.location.href = paymentUrl;

    } catch (error) {
      console.error("Error fetching payment URL:", error);
    }
    // getBatchDropdown();
  };

  console.log("first@@@@@@", course);
  //

  const [contactDetails, setContactDetails] = useState({
    buyer_name: "",
    email: "",
    phone: "",
    amount: "",
    user_id: "",
    course_id: "",
    course_name: "",

  });

  const [Otptoken, setOtptoken] = React.useState([]);
  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);
  const [batchList, setBatchList] = useState([]);
  // const darkMode= localStorage.removeItem('theme');

  // ----///

  let userlogin = localStorage.getItem("currentUser");
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  let changeWord = (data) => {
    setOtptoken(data);
  };
  return (
    <>
      <Header1 handleToggle={toggleMode} />
      <div
        className={darkMode ? "dark-mode" : "light-mode"}
        // className={theme==="l"}
      >
        <div className="totelPayment" darkMode={darkMode}>
          <Container>
            <div className="back_bttn">
              <i class="fa fa-angle-left" />
              <i class="fa fa-angle-left" />
              <Link to="/subscriptionamount">
                <span>Back to pricing </span>
              </Link>
            </div>
            <Row className="paymentalignment">
              <Col md="7">
                <div class="accordion" id="accordionExample">
                  {/* <div
                    class="accordion-item payment_title"
                    style={userlogin ? { pointerEvents: "none" } : {}}
                  >
                    <h2 class="accordion-header payment-head" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <span>1</span> Account details{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class={"collapse" + (userlogin ? "" : "show")}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <Signuppage changeWord={changeWord} />
                      </div>
                    </div>
                  </div> */}
                  <div class="accordion-item payment_title">
                    <h2 class="accordion-header payment-head" id="headingTwo">
                      {/* <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      > */}
                      {/* <span>1</span>Payment details */}
                      {/* </button> */}
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <span>1</span>Payment details
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      // class={"collapse" + (userlogin ? "show" : "")}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="payment_detaile">
                          <Row className="razerpay_row">
                            <Col md="6">
                              <div className="razorpay_radio">
                                <input type="radio" defaultChecked />

                                <img
                                  width="40%"
                                  src={darkMode ? razorpay_con : razorpay_white}
                                />
                              </div>
                            </Col>
                          </Row>
                          <form onSubmit={handleSubmit(handleRedirect)}>
                            <Row className="userdetail_row">
                              <Col md="6" className="account_col-align">
                                <div className="form_item">
                                  <div className="userinput">
                                    <label>Name</label>
                                    <input
                                      className="PaymentDetailsInputs"
                                      type="text"
                                      // name="buyer_name"
                                      // value={contactDetails.buyer_name}
                                      {...register("buyer_name", {
                                        required: true,
                                      })}
                                      // onChange={handleContactDetailsChange}
                                    />
                                    {errors.buyer_name && (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "15px",
                                        }}
                                      >
                                        Please Enter the Name
                                      </span>
                                    )}
                                    <div>
                                      <label>E-mail</label>
                                      <input
                                        className="PaymentDetailsInputs"
                                        type="email"
                                        // name="email"
                                        {...register("email", {
                                          required: true,
                                        })}
                                        // value={contactDetails.email}
                                        // onChange={handleContactDetailsChange}
                                      />
                                      {errors.email && (
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Please Enter your Email 
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form_item">
                                  <div className="userinput">
                                    <label>Mobile</label>
                                    <input
                                      className="PaymentDetailsInputs"
                                      // type="number"
                                      // name="phone"
                                      // onChange={contacthandele}
                                      {...register("phone", {
                                        required: "Please Enter the Phone Number",
                                        minLength: {
                                          value: 10,
                                          message:
                                            "Mobile number must be 10 digits",
                                        },
                                      })}
                                      // value={contactDetails.phone}
                                      // onChange={handleContactDetailsChange}
                                      maxLength="10"
                                    />
                                    {errors.phone && (
                                      <span style={{ color: "red" }}>
                                        {errors.phone.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="card_confirm">
                              <Button
                                type="submit"
                                style={{
                                  backgroundColor: darkMode
                                    ? "#0DAAFE"
                                    : "#0DAAFE",
                                  color: "white",
                                }}
                                className="Continue_bttn"
                                // onClick={() =>
                                //   displayRazorpay({
                                //     course,
                                //     state,
                                //     contactdetails,
                                //     getBatchDropdown,
                                //   })
                                // }
                                // onClick={}
                              >
                                Continue
                              </Button>

                              <div>
                                <Modal
                                  isOpen={modal}
                                  toggle={toggle}
                                  modalTransition={{ timeout: 2000 }}
                                >
                                  <ModalBody>
                                    <div className="message_align">
                                      <div className="success_message">
                                        <img
                                          alt="success message"
                                          src={Successmessage}
                                        />
                                        <h6>
                                          Your payment have been
                                          <br />
                                          paid successfully{" "}
                                        </h6>
                                        <Link to="/learning">
                                          <Button>OK</Button>
                                        </Link>
                                      </div>
                                    </div>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="5" className="order_summary">
                <div className="final_order">
                  <div className="order_head">
                    <h6>Order summary</h6>
                  </div>

                  <div className="individual_trial1">
                    <h5>PLAN</h5>
                    <h5>PRICE</h5>
                  </div>
                  <div className="individual_trial">
                    <h5>{course.name}</h5>
                    <h5>₹ {course.price}</h5>
                  </div>
                  <div className="order_subtotal">
                    <h6>Subtotal </h6>
                    <span>₹ {course.price}</span>
                  </div>
                  <div className="estimate_tax">
                    <span>Estimation tax</span>
                    <h6>₹ 0</h6>
                  </div>
                  <div className="order_total">
                    <h5>Total due today</h5>
                    <h5>₹ {course.price}</h5>
                  </div>
                </div>
                <div className="order_content">
                  {/* <p>
                    Lörem ipsum hybridkrig latärat. Väl bötning morotsaktivism
                    fast kapselbryggare. Uplass preng då hening selogi
                    frimester. Techlash sms-livräddare i framåtlutad, 5:2-diet
                    menskopp. Den nya ekonomin ridade inte skynka. Ugiktig
                    multir böligen, att fuldelning. Prena mitt.
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Paymentdetails;
