import {
    takeEvery,
    takeLatest,
    take,
    call,
    put,
    fork,
} from "redux-saga/effects";
import * as AdminActions from "../actions/admin";
import * as AdminApi from "../api/admin";


function* getadmin() {
    try {
        const result = yield call(AdminApi.getAdminAttribute);
        yield put(
            AdminActions.getadminSuccess({
                adminitems: result?.data?.data,
            })
        );
    } catch (e) {
        yield put(

        );
    }
}


function* watchGetAdminRequest() {
    yield takeEvery(
        AdminActions.AdminType.GET_ADMIN_REQUEST,
        getadmin
    );
}



const Admin = [
    fork(watchGetAdminRequest),
];
export default Admin;
