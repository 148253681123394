import { Card, Row, Col, Radio, Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";
import { useNavigate } from "react-router-dom";
import "../AssesmentPage.scss";
import axios from "axios";

import { ElearningAxios } from "../../..";
const Section9 = ({
  handleBack,
  id,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult.Accounts[0] });
  console.log("finalResult", finalResult, getValues());
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [UserList, setUserList] = useState([]);
  console.log("UserList", UserList);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const question = [
    {
      id: 1,
      qustion: "Financial Discipline - Company Accounts",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "I take money from the company accounts for my personal requirement",
      right:
        "I take monthly fixed amount for me from company (either as salary or otherwise)",
      identifier: "Financial_discipline",
    },
    {
      id: 2,
      qustion: "Inventory Turnover Ratio",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "We do not measure inventory turns",
      right: "We monitor ITR and take actions to improve it",
      identifier: "inventory_turnover_ratio",
    },
    {
      id: 3,
      qustion: "On time payment to suppliers",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not focussed",
      right: "100% on time payment to 100% suppliers ",
      identifier: "On_time_payment",
    },
    {
      id: 4,
      qustion: "Annual Budget and provisions",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No clear budget",
      right:
        "Clearly defined budget with resources allocated for every departments & projects",
      identifier: "annual_budget_provisions",
    },
    {
      id: 5,
      qustion: "Monthly P&L",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No monthly P&L and Reviews",
      right:
        "Weekly / Monthly P&L is reviewed every month and actions being taken ",
      identifier: "monthly_pl",
    },
  ];
  const onChangeData = (e) => {
    setValue(e.target.name, e.target.value);
  };
  const handleNavigation = () => {
    const aa=UserList.find(data=>data.name===`Level ${totalresult < 3 ? 1 : totalresult >= 5 ? 3 : 2}`)
     navigate(`/payment/${aa._id}`);
  };
  const handleNavigate = () => {
     navigate(`/payment/${id}`);
  };
  const values = getValues();
  let sum = 0;

  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const value = parseInt(values[key], 10);
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }
  useEffect(() => {
    const getUserlist = async () => {
      console.log("one");
      axios.get("http://143.198.66.255/api/subscriptiontype").then((res) => {
        console.log("resres", res);
        setUserList(res.data.data);
      });
    };
    getUserlist();
  }, []);
  const result = (sum / 5).toFixed(2);
  const finalresults = finalResult;

  console.log("first", finalresults);
  const resultArray = Object.values(finalresults)
    .filter(Array.isArray)
    .flatMap((arr) => arr.map((obj) => obj.result));
  resultArray.push(result);
 
  const filteredArray = resultArray.filter((value) => value !== undefined);

  for (let i = 0; i < filteredArray.length; i++) {
    if (filteredArray[i] === undefined) {
      filteredArray[i] = filteredArray[i + 1];
    }
  }
  const total = filteredArray.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue);
  }, 0);
  const totalresult = (total / 8).toFixed(2);
  console.log("total", total);

  console.log("totalllllllllll", totalresult);

  console.log(filteredArray);

  console.log(filteredArray, "SASASAS");
  console.log(result, "section9");
  const handleSubmitValue = () => {
    handleResult({
      ...finalResult,
      Accounts: [
        {
          result: result,
          ...getValues(),
        },
      ],
    });

    ElearningAxios.post("/api/user/application", {
      ...finalResult,
      Accounts: [
        {
          result: result,
          ...getValues(),
        },
      ],
    }).then((res) => {
      console.log("res.data", res.data);
      setIsModalOpen(true);
    });
  };
  const previousPage = () => {
    handleResult({
      ...finalResult,
      Accounts: [
        {
          result: result,
          ...getValues(),
        },
      ],
    });
    console.log("first", {
      ...finalResult,
      Accounts: [
        {
          values: getValues(),
          result: result,
        },
      ],
    });
    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3">
          <Col>
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer">
                {index < 1 && <div className="header-text">Accounts</div>}
                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="docusContainerLabel">
                    <div className="docusLeftContent">{ques.left}</div>
                    {[1, 2, 3, 4, 5].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"
                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>
            Back
          </Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p
              className="progressPage"
              style={{ width: "-webkit-fill-available" }}
            >
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusSubmitBtm"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Submit
          </Button>
          {/* <Button type="primary" onClick={showModal}>Submit</Button> */}
          <Modal
            className="AssesmentModalContainer"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            closeIcon={<span className="close-icon">&#x2715;</span>}
          >
            <div className="AssesmentContent">
              <div className="AssesmentHeadings">
                <div
                  className="AssesmentModalHeading"
                  style={{
                    // fontSize: "17px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                  }}
                >
                  SUGGESTION
                </div>
                <div
                  className="AssesmentModalSub_Heading"
                  style={{ fontWeight: "bold", letterSpacing: "1px" }}
                >
                  Your assessment score suits for{" "}
                  {"level " + (totalresult < 3 ? 1 : totalresult >= 5 ? 3 : 2)}
                </div>
              </div>
              <div className="AssesmentButtonContainer">
                <Button
                  className="popupbuttons"
                  style={{
                    border: "#1890ff",
                    backgroundColor: "#1890ff",
                    // fontSize: "16px",
                    fontWeight: "bolder",
                    letterSpacing: "1px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                  onClick={handleNavigate}
                >
                  Skip
                </Button>
                <Button
                  className="popupbuttons"
                  style={{
                    border: "#1890ff",
                    backgroundColor: "#1890ff",
                    color: "white",
                    // fontSize: "16px",
                    fontWeight: "bolder",
                    letterSpacing: "1px",
                    borderRadius: "5px",
                    padding: "0px 20px",
                  }}
                  onClick={handleNavigation}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>{" "}
    </div>
  );
};

export default Section9;
