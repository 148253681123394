import React, { useContext, useState } from "react";
import OTPInput from "otp-input-react";
import { Button } from "reactstrap";
import { Form, Input, Space } from "antd";
import { toast } from "react-toastify";
import { authenticationSignup } from "../Service/Signupauthentication";
import "../../assets/SCSS/Payment.scss";
import jwt from "jwt-decode";
import { ElearningAxios } from "../../index";
import { ThemeContext } from "../../utils/ThemeProvider";
import useDarkMode from "../../Pages/useDarkMode";

const Signuppage = (props) => {
  const [form] = Form.useForm();

  const layout = { labelCol: { span: 11 }, wrapperCol: { span: 30 } };
  const [userOtp, setUserOtp] = React.useState(false);
  const [otpnumber, setOtpNumber] = React.useState("");
  const [otptoken, setOtptoken] = React.useState([]);
  const [signupdata, setSignupdata] = useState("");

  const [agree, setAgree] = useState(false);
  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);

  const Otpsubmit = async (otptoken) => {
    if (otpnumber === "") {
      toast.error("Please enter OTP");
      return;
    }
  
    try {
      const response = await ElearningAxios.post("/api/user/verify", {
        email: signupdata.email,
        otp: otpnumber,
        password: signupdata.password,
      });
  
      if (response.data.success) {
        toast.success("User registration successful");
        window.location.reload();
  
        // Store the token and user details in local storage
        const { token } = response.data;
        const userDetail = jwt(token);
        localStorage.setItem("currentUser", JSON.stringify(token));
        localStorage.setItem("userDetail", JSON.stringify(userDetail));
      } else {
        toast.error("Your OTP is incorrect");
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const Signup = async (signupdata) => {
    setSignupdata(signupdata);

    authenticationSignup.Signup(signupdata).then((res) => {
      if (res.status) {
        setUserOtp(true);
      }
    });
  };

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  };
  return (
    <div handleToggle={toggleMode}>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div class="accordion-body">
          {userOtp == true ? (
            <div className="open_otp">
              <div className="total_otp">
                <OTPInput
                  className="otp_input"
                  style={{ color: darkMode ? "white" : "black" }}
                  value={otpnumber}
                  onChange={setOtpNumber}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                />
              </div>
              <Button onClick={() => Otpsubmit(otptoken)}>Verify</Button>
            </div>
          ) : (
            <>
              <Form
                form={form}
                {...layout}
                className="payment_data"
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={Signup}
              >
                <Space.Compact className="password_align">
                  <Form.Item
                    className="form_item"
                    name="firstname"
                    label={
                      <label
                        style={{ 
                          color: darkMode ? "white" : "black",
                          width: "90px",
                        }}
                      >
                        First Name
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        type: "string",
                        message: "Please input your First Name",
                      },
                      {
                        required: true,
                        message: "Please enter First Name",
                      },
                    ]}
                  >
                    <Input className="singupPageInputs" />
                  </Form.Item>
                  <Form.Item
                    className="form_item"
                    name="lastname"
                    label={
                      <label
                        style={{
                          color: darkMode ? "white" : "black",
                          width: "95px",
                        }}
                      >
                        Last name
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last name",
                      },
                    ]}
                  >
                    <Input className="singupPageInputs" />
                  </Form.Item>
                </Space.Compact>

                <Space.Compact className="password_align">
                  <Form.Item
                    className="form_item"
                    name="email"
                    label={
                      <label
                        style={{
                          color: darkMode ? "white" : "black",
                          width: "90px",
                        }}
                      >
                        Email
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail",
                      },
                      {
                        required: true,
                        message: "Please enter E-mail",
                      },
                    ]}
                  >
                    <Input
                      className="singupPageInputs"
                      maxlength="254"
                    />
                  </Form.Item>
                  <Form.Item
                    className="form_item"
                    name="password"
                    label={
                      <label
                        style={{
                          color: darkMode ? "white" : "black",
                          width: "95px",
                        }}
                      >
                        Password
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        type: "password",
                        message: "Please input your Password",
                      },
                      {
                        required: true,
                        message: "Please enter Password",
                      },
                    ]}
                  >
                    <Input
                     className="singupPageInputs"
                      type="password"
                    />
                  </Form.Item>
                </Space.Compact>

                <Space.Compact className="password_align">
                  <Form.Item
                    className="form_item"
                    name="mobile"
                    label={
                      <label
                        style={{
                          color: darkMode ? "white" : "black",
                          width: "90px",
                        }}
                      >
                        Mobiles
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Mobile number must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                     className="singupPageInputs"
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    className="form_item"
                    name="companyName"
                    label={
                      <label
                        style={{
                          color: darkMode ? "white" : "black",
                          width: "95px",
                        }}
                      >
                        Company
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        type: "string",
                        message: "Please Valid enter Company Name",
                      },
                      {
                        required: true,
                        message: "Please enter Company Name",
                      },
                    ]}
                  >
                    <Input className="singupPageInputs" />
                  </Form.Item>
                </Space.Compact>
                <Space.Compact className="password_align">
                 
                  <Form.Item
                    className="form_item"
                    name="address"
                    label={
                      <label
                        style={{
                          color: darkMode ? "white" : "black",
                          width: "95px",
                        }}
                      >
                        Address
                      </label>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        type: "string",
                        message: "Please Valid enter Address",
                      },
                      {
                        required: true,
                        message: "Please enter Address",
                      },
                    ]}
                  >
                    <Input.TextArea className="singupInputfield" style={{ color: darkMode ? "white" : "black",height:"120px" }} />
                  </Form.Item>
                </Space.Compact>
                <div className="continue_bttnsec">
                  <input type="checkbox" onChange={checkboxHandler} />
                  <span>
                    By checking here and continuing. I agree to the site{" "}
                    <p>Terms of Use</p>
                  </span>
                </div>
                <Form.Item className="form_margin">
                  <Button
                    disabled={!agree}
                    htmlType="submit"
                    style={{
                      backgroundColor: darkMode ? "#0DAAFE" : "#E7005E",
                      color: "white",
                      // marginTop: "10px",
                      width: "190px",
                      height: "50px",
                      background: "#0DAAFE",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    SignUp
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signuppage;
