import { Card, Row, Col, Radio, Button } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";
import "../../../assets/SCSS/Docuspage.scss";

const Section2 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch, 
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult.leadership[0] });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const question = [
    {
      id: 1,
      qustion:
        "As a Business Owner – how long you can go off from your office? (completely disconnected)",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "1 day",
      right: "1 year",
      identifier: "businessowner",
    },
    {
      id: 2,
      qustion: "Growth targets and achievement",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Targets not set",
      right:
        "Targets set for at least past 5 years and we consistently achieve 80% of targets ",
      identifier: "targets_achievement",
    },
    {
      id: 3,
      qustion: "Value Proposition",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Value proposition is not clearly defined",
      right:
        "Value proposition is clearly defined and strategies are aligned to the Value Proposision",
      identifier: "Value_proposition",
    },
    {
      id: 4,
      qustion: "Emerging trends",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No clear plan to identify and tap the emerging trend in the market",
      right:
        "Systems in place to identify the changing trend and we continuously innovate ways to stay ahead of market",
      identifier: "emerging_trends",
    },
    {
      id: 5,
      qustion: "Vision & Goals",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Organisational Vision is not defined",
      right:
        "Clearly defined and communicated Organisational Vision. We set goals based on the Vision.",
      identifier: "vision_boals",
    },
    {
      id: 6,
      qustion: "Organisational Structure",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Functional Organisation structure not defined and communicated to everyone",
      right:
        "Functional Organisational structure is defined and communicated. People know their responsibilities and expectations. Structure covers at least 7 department.",
      identifier: "organisational_structure",
    },
    {
      id: 7,
      qustion: "Learning Organisation",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Monthly reviews with planned agenda is not conducted.",
      right:
        "Monthly reviews with planned agenda regularly conducted. Actions arising are noted and followed up. Learning are captured and internalised.",
      identifier: "learning_organisation",
    },
  ];
  const onChangeData = (e) => {
    console.log("e.target.value", e.target.name, typeof e.target.name);
    setValue(e.target.name, e.target.value);
    console.log("getvalues", getValues());
  };
  const values = getValues();
let sum = 0;

for (let key in values) {
  if (values.hasOwnProperty(key)) {
    const value = parseInt(values[key], 10);
    if (!isNaN(value)) {
      sum += value;
    }
  }
}

const result = (sum / 7).toFixed(2);

console.log(result,"asdfghjk");
const handleSubmitValue = () => {
  handleResult({
    ...finalResult,
    leadership: [{
      result: result,
      ...getValues(),
     
    }]
  });
  handleNext();
};
  const previousPage = () => {
    handleResult({
      ...finalResult,
      leadership: [{
        result: result,
        ...getValues()
        
      }]

    });
    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3">
          <Col> 
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer position-relative mt-5">
                {index < 1 && <div className="header-text" >Leadership</div>}
                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div className="AssessmentQuestions" style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}<span style={{color:"red"}}>*</span>
                  </div>
                  <div className="superContent">
                  <div className="docusContainerLabel">
                    <div className="docusLeftContent">{ques.left}</div>
                    {[1, 2, 3, 4, 5,].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"
                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>Back</Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p className="progressPage" style={{ width: "-webkit-fill-available" }}>
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusNextbtn"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section2;
