import React, { useRef } from "react";
import {
    Container,
} from "reactstrap";
import "../../assets/SCSS/Submitpage.scss";
import { Link } from "react-router-dom";

const Submitpage = () => {
    const inputref = useRef(null)

    return (
        <>
            <div className="package">
                <Container className="package_content">

                    <div className="space">
                        <div className="questions">
                            <h1>
                                Help us with below questions to find the suitable package for
                                you
                            </h1>
                            <div className="click">
                                <h1 className="yes">Yes</h1>
                                <h1>NO</h1>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>1. operating business for at least 3 years.</p>
                            <div className="content">

                                <input type="radio" id="box-1-yes" name="question-1" value="yes" />
                                <label for='box-1-yes' className="inputOne" ref={inputref}></label>

                                <input type="radio" id="box-1-no" name="question-1" value="no" />
                                <label for='box-1-no' className="inputTwo"></label>

                            </div>
                        </div>
                        <div className="allinput">
                            <p>2. Has a team of 7+ people.</p>
                            <div className="content">

                                <input type="radio" id="box-2-yes" name="question-2" value="" />
                                <label for='box-2-yes' className="inputOne" ></label>

                                <input type="radio" id="box-2-no" name="question-2" value="" />
                                <label for='box-2-no' className="inputTwo"></label>

                            </div>
                        </div>
                        <div className="allinput">
                            <p>3. Clocks an Annual TO of 50 lac and above.</p>
                            <div className="content">
                                <input type="radio" id="box-3-yes" name="question-3" value="" />
                                <label for='box-3-yes' className="inputOne" ></label>

                                <input type="radio" id="box-3-no" name="question-3" value="" />
                                <label for='box-3-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>4. Determined to grow his/her business.</p>
                            <div className="content">
                                <input type="radio" id="box-4-yes" name="question-4" value="" />
                                <label for='box-4-yes' className="inputOne" ></label>

                                <input type="radio" id="box-4-no" name="question-4" value="" />
                                <label for='box-4-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>
                                5. Can invest his time in learning and upgrading
                                himself/herself.
                            </p>
                            <div className="content">
                                <input type="radio" id="box-5-yes" name="question-5" value="" />
                                <label for='box-5-yes' className="inputOne" ></label>

                                <input type="radio" id="box-5-no" name="question-5" value="" />
                                <label for='box-5-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>6. Willing to collabo.</p>
                            <div className="content">
                                <input type="radio" id="box-6-yes" name="question-6" value="" />
                                <label for='box-6-yes' className="inputOne" ></label>

                                <input type="radio" id="box-6-no" name="question-6" value="" />
                                <label for='box-6-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>7. Rate with team members.</p>
                            <div className="content">
                                <input type="radio" id="box-7-yes" name="question-7" value="" />
                                <label for='box-7-yes' className="inputOne" ></label>

                                <input type="radio" id="box-7-no" name="question-7" value="" />
                                <label for='box-7-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>8. Committed to TAKE ACTION after learning.</p>

                            <div className="content">
                                <input type="radio" id="box-8-yes" name="question-8" value="" />
                                <label for='box-8-yes' className="inputOne" ></label>

                                <input type="radio" id="box-8-no" name="question-8" value="" />
                                <label for='box-8-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <div className="allinput">
                            <p>9. Willing to keep video on during the Zoom Sessions.</p>
                            <div className="content">
                                <input type="radio" id="box-9-yes" name="question-9" value="" />
                                <label for='box-9-yes' className="inputOne" ></label>

                                <input type="radio" id="box-9-no" name="question-9" value="" />
                                <label for='box-9-no' className="inputTwo"></label>
                            </div>
                        </div>
                        <Link to="/subscriptionamount">
                            <button className="submitbtn">SUBMIT</button>
                        </Link>
                        <p className="paraa">s<br></br>s<br></br></p>
                    </div>
                </Container>
            </div >
        </>
    );
};

export default Submitpage;