import React, { useState, useEffect } from "react";
import { Button, message, Row, Col, Card } from "antd";
import '../../assets/SCSS/Subscriptionprice.scss';
import { ElearningAxios } from "../..";
import Rectangle3 from "../../assets/Images/cardtitle.png";
import { getuserSuccess } from "../../actions/userdetails";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';


const Subscription = () => {
  let userDetail = JSON.parse(localStorage.getItem('userDetail'))
  const [subscripecourse, setSubscripecourse] = useState([]);
  const userdata_id = userDetail?._id;
  useEffect(() => {
    if (userdata_id) {
      ElearningAxios.get("/api/usersubscription/" + userdata_id)
        .then((response) => {
          setUsersubscription(response.data.data);
          setError(null);
        })
        .catch(setError);
      ElearningAxios.get("/api/user/" + userdata_id)
        .then((response) => {
          setSubscripecourse(response.data.data);
        })
    }
  }, [userdata_id]);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usersubscription, setUsersubscription] = useState({});
  const [error, setError] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const confirm = (e) => {
    message.success('Click on Yes');
  };
  const cancel = (e) => {
    message.error('Click on No');
  };

  const selectcourse = subscripecourse?.map((a) => {
    return a.subscriptionType;
  });
  const finaldata = selectcourse?.map((data) => {
    return data.length
  })
  return (

    <>
      <div className="subscription_course">
        {finaldata > 0 ? <Row>
          {selectcourse?.map((data) => {
            return <Col md='3' className="card_details">
              {data.map((a) => {
                return <>
                  <Card >


                    <img className="course_image" src={Rectangle3} alt="sample" />
                    <div className="my_subscription">
                      <div>
                        <h3>{a.name}</h3>
                      </div>
                      <div>
                        <h3>₹ {a.price}</h3>
                      </div>
                    </div>
                    <div className="course_detailes">
                      <p>{a.description}</p>
                    </div>
                    <div className="course_list">
                      <div className="course_module">
                        <div>12 Modules</div>

                      </div>
                      <div className="course_module">
                        <div>{a.duration} Hours of Video</div>

                      </div>
                      <div className="course_module">
                        <div>View All Courses</div>

                      </div>
                    </div>
                    <div className="view_detailbttn">
                      <Link to={"/productdetails/" + a?._id} state={a}>
                        <Button>View Details</Button>
                      </Link>
                    </div>

                  </Card>
                </>
              })}

            </Col>
          })}


        </Row> : <p className="course_err">You have no courses to view. Please visit products page</p>


        }

      </div>
    </>
  );
};



export default connect((User) => User, {
  getuserSuccess,
})(Subscription);