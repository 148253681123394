import React, { useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import "../../assets/SCSS/Profilesection.scss";
import Header1 from "../../components/Layout/Header1";
import Profilepage from "../Mylearning/Profile";
import Password from "../Mylearning/Password";
import Message from "../Mylearning/Message";
import Payment from "../Mylearning/Payment";
import Subscription from "../Mylearning/Subscription";
import { useNavigate, useSearchParams } from "react-router-dom";
import admin from "../../assets/Images/admin.png";
import setting from "../../assets/Images/setting.png";
import message from "../../assets/Images/message.png";
import payment from "../../assets/Images/payment.png";
import subscription from "../../assets/Images/subscription.png";
import logoutimg from "../../assets/Images/logout.png";
import Document from "../../Pages/Document";
import useDarkMode from "../../Pages/useDarkMode";
import { ThemeContext } from "../../utils/ThemeProvider";
import { CgProfile } from "react-icons/cg";
import { RiVideoChatFill } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { SiAmazonpay } from "react-icons/si";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { IoDocumentTextSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";

const Profilesection = () => {
  const [searchParams] = useSearchParams();
  let paymentId = searchParams.get("payment_request_id");

  useEffect(() => {
    if (paymentId) {
      fetch(process.env.REACT_APP_DOMAIN + "/api/payment-status?payment_id=" + paymentId)
        .then((res) => res.json())
        .then((result) => {

          setTimeout(() => {
            window.location.href = "http://bcpacademy.com/#/profilesection";
            window.location.reload();
          }, 2000);
          // setCourse(result?.data[0]);
          // console.log("payMent", result?.data[0]);
        
        });
    }
  }, [paymentId]);

  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);

  const handleButtonClick = () => {
    navigate("/MyCourse"); // Replace '/my-courses' with the desired route
  };

  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userDetail");

    navigate("/");
  }
  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <div className="total_profilesection">
        <Header1 handleToggle={toggleMode} />
        <div class="d-flex align-items-start total_profile_section">
          <Container>
            <Row className="flex_seprate">
              <Col md="4 flex_seprates">
                <div
                  class="nav flex-column nav-pills me-3 sidebar_btn profile_btns"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                  // className="butttonnnnnn"
                >
                  <button
                    class="nav-link active profile_icons"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                  >
                    {/* <img src={admin} /> Profile */}
                    <CgProfile
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    Profile
                  </button>
                  <button
                    class="nav-link profile_icons"
                    id="v-pills-password-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-password"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-password"
                    aria-selected="false"
                  >
                    {/* <img src={setting} /> Change Password s */}
                    <FiSettings
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    Change Password
                  </button>
                  <button
                    class="nav-link profile_icons"
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    {/* <img src={message} /> Messages  */}
                    <AiOutlineMail
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    Messages
                  </button>
                  <button
                    class="nav-link profile_icons"
                    id="v-pills-payment-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-payment"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-payment"
                    aria-selected="false"
                  >
                    {/* <img src={payment} /> Payment History  */}
                    <SiAmazonpay
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    Payment History
                  </button>
                  <button
                    class="nav-link profile_icons"
                    id="v-pills-subscription-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-subscription"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-subscription"
                    aria-selected="false"
                    onClick={handleButtonClick}
                  >
                    {/* <img src={subscription} /> My Courses  */}
                    <RiVideoChatFill
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    My Courses
                  </button>
                  <button
                    class="nav-link profile_icons"
                    id="v-pills-document-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-document"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-document"
                    aria-selected="false"
                  >
                    {/* <i class="fa fa-file-text" aria-hidden="true"></i> My Documents */}
                    <IoDocumentTextSharp
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    My Documents
                  </button>
                  <button
                    class="nav-link profile_icons"
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                    onClick={logout}
                  >
                    {/* <img src={logoutimg} />  Logout */}
                    <RiLogoutBoxRLine
                      className="me-2"
                      style={{ fontSize: "24px" }}
                    />{" "}
                    Logout
                  </button>
                </div>
              </Col>
              <Col md="8 flex_seprates" className="menu_val">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <Profilepage />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-password"
                    role="tabpanel"
                    aria-labelledby="v-pills-password-tab"
                  >
                    <Password />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <Message />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <Payment />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-subscription"
                    role="tabpanel"
                    aria-labelledby="v-pills-subscription-tab"
                  >
                    <Subscription />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-document"
                    role="tabpanel"
                    aria-labelledby="v-pills-document-tab"
                  >
                    <Document />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    ...
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Profilesection;
