import React, { useState, useEffect, useContext } from "react";
import Header1 from "../components/Layout/Header1";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import "../assets/SCSS/Blogdetails.scss";
import useDarkMode from '../Pages/useDarkMode';
import { ThemeContext } from '../utils/ThemeProvider';
import { digitaloceanspacesurl } from '../api/constant';

const Blogdetails = () => {
  const [blogdetails, setBlogdetails] = useState();
  const { toggleMode } = useDarkMode();
  const {darkMode}=useContext(ThemeContext)

  const { id } = useParams();

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/api/blog/" + id)
      .then((res) => res.json())
      .then((result) => {
        setBlogdetails(result?.data[0]);
      });
  }, [id]);
console.log('first!!!!!!', blogdetails?.description)
  return (
    <div>
      <Header1 handleToggle={toggleMode} />
      <div className={darkMode ? 'dark-mode' : 'light-mode'}>

      <div className="total_sec_blogdetails">
        <Container>
          <div className="total_blogdetails">
            <div className="part1">
              <h1>{blogdetails?.title}</h1>
            </div>
            <div className="">
              <img
                className="card_img_blogdetail"
                alt="Sample"
                src={digitaloceanspacesurl + blogdetails?.image}
              />
            </div>
            <div className="part2">
              <h3>{blogdetails?.author}</h3>
            </div>
            <div className="part3">
              <div
                dangerouslySetInnerHTML={{ __html: blogdetails?.description }}
              />
            </div>
          </div>
        </Container>

      </div>
      </div>
    </div>
  );
};

export default Blogdetails;
