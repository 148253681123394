import React, { useContext, useEffect, useState } from "react";
import "../../assets/SCSS/Learning.scss";
import { Button, Input, Form } from "antd";
import { toast } from 'react-toastify';
import { ElearningAxios } from "../..";
import axios from "axios";

// import useDarkMode from "../Pages/useDarkMode";
// import { ThemeContext } from '../utils/ThemeProvider';


const Profilepage = () => {

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userDetail"));

    setUserDetail(userData);
    form.setFieldsValue(userData);
  }, [])
  const userid = JSON.parse(localStorage.getItem("userDetail"));
  console.log('userid', userid)

  const getUserlist = async () => {
    await axios.get(`api/user/${userid._id}`).then((res) => {
      setUserList(res.data.data);
    });
  };
  useEffect(() => {
    getUserlist();
  }, []);
  const [form] = Form.useForm(null);
  const layout = { labelCol: { span: 4 }, wrapperCol: { span: 12 } };
  const [display, setDisplay] = useState(true);
  const [userDetail, setUserDetail] = useState();
  const [UserList, setUserList] = useState([]);

  const onFinish = async () => {

    let formValue = form.getFieldsValue(true);
    form.setFieldsValue(formValue);

    setDisplay(!display);
    return await ElearningAxios
      .put(`/api/user/${formValue._id}`, formValue)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message)
          localStorage.setItem("userDetail", JSON.stringify(formValue));
          setUserDetail(formValue);
          form.setFieldsValue(formValue)
        }
      },
        (error) => {
          toast.error("Edited Not Sucesfully")
        }
      );
  };



  return (
    <>

      <div className="profileDetails" >
        <h3>Profile</h3>
        {display ? (
          <div className="profile">
            <ul>
              <li>
                <span className="profile-title">Name</span>
                <span className="profile-sym">:</span>
                <span className="profile-content">{userDetail?.firstname} {UserList[0]?.lastname}</span>
              </li>
              <li>
                <span className="profile-title">Email id</span>
                <span className="profile-sym">:</span>
                <span className="profile-content">{userDetail?.email}</span>
              </li>
              <li>
                <span className="profile-title">Phone</span>
                <span className="profile-sym">:</span>
                <span className="profile-content">{userDetail?.mobile}</span>
              </li>
              <li>
                <span className="profile-title">Address</span>
                <span className="profile-sym">:</span>
                <span className="profile-content">{UserList[0]?.address}</span>
              </li>
            </ul>
          </div>
        ) : (
          <div className="profile">
            <Form onFinish={onFinish} form={form} {...layout} className="total_profile_form">
              <Form.Item
                className="profile_form_item"
                label="Name :"
                name="firstname"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="profile_form_item"
                label="Email id :"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="profile_form_item"
                label="Mobile :"
                name="mobile"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>
              <Form.Item
                className="profile_form_item"
                label="Address :"
                name="address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input maxLength={254} />
              </Form.Item>
              <Form.Item className="profile_form_item">
                <Button type="primary" htmlType="submit" className="profile_form_btn">
                  Save
                </Button>
              </Form.Item>
            </Form>

          </div>
        )}
        <button
          className={display ? "btn btn-primary showbtn" : "editbtn"}
          onClick={() => setDisplay(!display)}
        >
          Edit
        </button>
      </div>
    </>
  );
};

export default Profilepage;
