import React, { useState, useEffect, useContext } from "react";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from '../utils/ThemeProvider';
import { digitaloceanspacesurl } from '../api/constant';

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import Header1 from "../components/Layout/Header1";
import "../assets/SCSS/Blog.scss";
import { Link } from "react-router-dom";

const Blog = (props) => {
  
  const {toggleMode } = useDarkMode();
  const{darkMode}=useContext(ThemeContext)
  const [blog, setBlog] = useState();
  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/api/blog")
      .then((res) => res.json())
      .then((result) => {
        setBlog(result?.data);
        console.log(result?.data);
      });
  }, []);
  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <div>
        <Header1 handleToggle={toggleMode} />

        <div className="total_blog_sec" darkMode={darkMode}>
          <Container>
            <div className="card_of_blog">
              {blog?.map((data) => {
                console.log(data,'data')
                return (
                  <>
                    <Link to={"/blogdetails/" + data?._id} state={data}>
                      <Card className="total_card_blog">
                        <div className="card_img_blog">
                          <img
                            alt="Sample"
                            src={
                              digitaloceanspacesurl +
                              data?.image
                            }
                          />
                        </div>

                        <CardBody>
                          <div className="card_body_header_blog">
                            <div className="card_sub_blog">
                              <CardTitle className="cardtitle_blog">
                                {data?.title}
                              </CardTitle>
                            </div>

                            {/* <CardSubtitle className="card_subtitle_blog" tag="h6">
                          {data?.author}
                        </CardSubtitle> */}
                            {/* <CardText className="cardtext_blog">
                            <div dangerouslySetInnerHTML={{ __html: data.description }} />
                          </CardText> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Link>
                  </>
                );
              })}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Blog;
