export const MessageType = {
    GET_MESSAGE_REQUEST: "message/get_message_request",
    GET_MESSAGE_SUCCESS: "message/get_message_success",
    POST_MESSAGE_SUCCESS: "message/post_message_success",
    GET_MESSAGESUBJECTID_REQUEST: "message/get_messagebysubjectid_request",
    GET_MESSAGESUBJECTID_SUCCESS: "message/get_messagebysubjectid_success",
    GET_MESSAGESUBJECTID_AFERSUCCESS: "message/get_messagebysubjectid_aftersuccess"
};


export const getmessagerequest = () => ({
    type: MessageType.GET_MESSAGE_REQUEST,
});

export const getmessageSuccess = ({ messageitems }) => ({
    type: MessageType.GET_MESSAGE_SUCCESS,
    payload: { messageitems },
});

export const PostmessageSuccess = (message) => ({
    type: MessageType.POST_MESSAGE_SUCCESS,
    payload: message,
});

export const PostmessageafterSuccess = (messageitems) => ({
    type: MessageType.GET_MESSAGESUBJECTID_AFERSUCCESS,
    payload: { messageitems },
});

export const getMessageBySubjectIDrequest = (subjectId) => ({
    type: MessageType.GET_MESSAGESUBJECTID_REQUEST,
    payload: subjectId
});

export const getMessagebySubjectID = ({ messagesubjectiditem }) => ({
    type: MessageType.GET_MESSAGESUBJECTID_SUCCESS,
    payload: { messagesubjectiditem },
});