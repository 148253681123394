import { Card, Row, Col, Radio, Button } from "antd";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Progress } from "antd";

const Section3 = ({
  handleBack,
  handleNext,
  handleResult,
  finalResult,
  progressValue,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: finalResult.marketing[0] });
  console.log("finalResult", finalResult, getValues());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const question = [
    {
      id: 1,
      qustion: "Brand Identity",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not in place",
      right: "Registered Logo, Own website, Company Email",
      identifier: "brand_identity",
    },
    {
      id: 2,
      qustion: "Digital Presence",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not in place",
      right:
        "Omnipresence (Facebook Page, Google My Business, LinkedIn, Instagram, Youtube, Website Keyword Optimisation) ",
      identifier: "digital_presence",
    },
    {
      id: 3,
      qustion: "Do you know your Target Customer?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Don't know",
      right: "Well defined Customer Persona",
      identifier: "target_customer",
    },
    {
      id: 4,
      qustion: "Do you know your next potential market?",
      answer1: "1 ",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Don't know",
      right: "A clear target market and plan available",
      identifier: "potential_market",
    },
    {
      id: 5,
      qustion:
        "Do you measure your customer walk-in or touchpoints as part of your marketing effectiveness?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "No",
      right: "We collect data and review regularly and take actions",
      identifier: "marketing_effectiveness",
    },
    {
      id: 6,
      qustion:
        "Launch of new products/services in the past 3 years and their revenue share",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Not launched any new product / services in past 3 years",
      right:
        "Launched new product/service and they have more than 20% of revenue share now",
      identifier: "products_services",
    },
    {
      id: 7,
      qustion:
        "Do you know one thing that differentiates your company in the market?",
      answer1: "1",
      answer2: "2",
      answer3: "3",
      answer4: "4",
      answer5: "5",
      left: "Don't know",
      right: "We know and we consciously leverage our differentiators",
      identifier: "differentiates_company",
    },
  ];
  const onChangeData = (e) => {
    setValue(e.target.name, e.target.value);
  };
  const values = getValues();
  let sum = 0;
  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const value = parseInt(values[key], 10);
      if (!isNaN(value)) {
        sum += value;
      }
    }
  }

  const result = (sum / 7).toFixed(2);

  console.log(result, "yarooooo");
  const handleSubmitValue = () => {
    handleResult({
      ...finalResult,
      marketing: [
        {
          result: result,
          ...getValues(),
        },
      ],
    });
    handleNext();
  };
  console.log("getValues(", getValues());
  const previousPage = () => {
    handleResult({
      ...finalResult,
      marketing: [
        {
          result: result,

          ...getValues(),
        },
      ],
    });

    handleBack();
  };
  return (
    <div>
      <div className="">
        <Row className="DocusHeadingContainer p-3" >
          <Col>
            {question.map((ques, index) => (
              <Card className="PersonaldetailsContainer">
                {index < 1 && <div className="header-text">Marketing</div>}
                <div className={`ms-3 ${index < 1 && "mt-5"}`}>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {ques.qustion}
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="docusContainerLabel">
                    <div className="docusLeftContent">{ques.left}</div>
                    {[1, 2, 3, 4, 5].map((radioData) => (
                      <div
                        // style={{
                        //   padding: "0px 15px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        // }}
                        className="AssesmentInput"
                      >
                        <input
                          className="me-2"
                          type="radio"
                          value={radioData}
                          {...register(ques.identifier, {
                            required: true,
                            onChange: (e) => onChangeData(e),
                          })}
                          checked={`${radioData}` == watch(ques.identifier)}
                        />
                        <span>{radioData}</span>
                      </div>
                    ))}

                    <div className="docusRightContent">{ques.right}</div>
                  </div>
                </div>
                <p className="error-text">
                  {errors[`question${ques.id}`] &&
                    `Question${ques.id} is Required`}
                </p>
              </Card>
            ))}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-3">
        <div
          className="d-flex justify-content-between mt-3 pb-5 "
          style={{ width: "860px" }}
        >
          <Button className="docusBackbtn" onClick={previousPage}>
            Back
          </Button>
          <div className="progress-container">
            <Progress
              percent={Math.round((progressValue - 1) * 11.12)}
              className="percentageProgress"
            />
            <p
              className="progressPage"
              style={{ width: "-webkit-fill-available" }}
            >
              page {progressValue} of 9
            </p>
          </div>{" "}
          <Button
            className="docusNextbtn"
            onClick={handleSubmit(handleSubmitValue)}
          >
            Next
          </Button>
        </div>
      </div>{" "}
    </div>
  );
};

export default Section3;
