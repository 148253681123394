// import React, { useState, useEffect, useContext } from "react";
// import { Switch } from 'antd';
// import { Container, Row, Col, Button } from "reactstrap";
// import Headerlogo from "../../assets/Images/Business Champions latest.png";
// import user_logout from "../../assets/Images/user_logout.png";
// import user_profile from "../../assets/Images/user_profile.png";
// import Searchicon from "../../assets/Images/search_icon.png";
// import Switchs from "../../assets/Images/switch.png";
// import "../../assets/SCSS/Header1.scss";
// import { Link } from "react-router-dom";
// import { useNavigate, useHistory } from "react-router-dom";
// import { Modal } from "antd";
// import Login from "../../Pages/Login";
// import { ElearningAxios } from "../../index";
// import { NavLink } from 'react-router-dom';
// import { ThemeContext } from "../../utils/ThemeProvider";
// import axios from "axios";
// import { HiSpeakerWave } from "react-icons/hi2";
// const Header1 = (props) => {
//   // const { darkMode } = props;
// const {toggleTheme,darkMode}=useContext(ThemeContext)
//   useEffect(() => {
//     ElearningAxios("/api/announcement")
//       .then((response) => {
//         setAnnouncementData(response.data.data);
//         setAnnouncementerror(null);
//       })
//       .catch(setAnnouncementerror);
//       // getUserlist(parsedId);

//   }, []);
//   const [UserList, setUserList] = useState([]);
//   const userid = localStorage.getItem("userDetail");
//   const parsedId = JSON.parse(userid);
// useEffect(()=>{
  
//   const getUserlist = async () => {
//     console.log('one')
//     axios.get(`api/user/${parsedId._id}`).then((res) => {
//      setUserList(res.data.data);
//    });
//  };
//  getUserlist()
// },[userid])

//   const [AnnouncementData, setAnnouncementData] = useState([]);
//   const [Announcementerror, setAnnouncementerror] = useState(null);

//   const [pageType, setPageType] = useState("LOGIN");
  
 
//   const navigate = useNavigate();
//   let currentUser = localStorage.getItem("currentUser");
//   let userDetail = localStorage.getItem("userDetail");

//   function logout() {
//     localStorage.removeItem("currentUser");
//     localStorage.removeItem("userDetail");
//     navigate("/");
//   }
  
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//     setPageType('LOGIN')
//   };

//   const clickRow = (status) => {
//     if (status === "cancel") {
//       setIsModalOpen(false);
//     }
//   };
//   const [activeItem, setActiveItem] = useState('');

//   const handleItemClick = (itemName) => {
//     setActiveItem(itemName);
//   };
//   const handlePage=(value)=>{
//     setPageType(value)
//   }
//   return (
//     <>
//         <div className={darkMode ? 'dark-mode' : 'light-mode'}>

//       <div className="header4_total_section" style={{background:" #1c1935",position:"fixed",zIndex:"5",top:"0px",width:"100%"}}>
//         {/* <div className="headerTop">
//           <Container>
//             <ul className="rightMenu">

//               <li><Link to="/">info@businesschamp.com</Link></li>

//               <li><Link to="/">+91 9994392983</Link></li>

//             </ul>
//           </Container>
//         </div> */}
//         <Container>

//           <nav class="header_left navbar navbar-expand-sm navbar-dark">
//             <div class="container header1-design" >
//               <div className="menuLeft">
//                 <Link to="/">
//                   <div className="header1-image">
//                     <img
//                       width={150}
//                       alt="headerlogo"
//                       src={Headerlogo}
//                     />
//                   </div>
//                 </Link>
//                 <div className="header_links">
//                   {/* <Link to="/plan">Products</Link> */}
//                   {/* <Link to="/subscriptionamount"
//                                   className={`navbar-item ${activeItem === 'products' ? 'active' : ''}`}
//                                   onClick={() => handleItemClick('products')}

//                                   >Our Products</Link> */}
//                                              <NavLink
//                 to="/"
//                 activeClassName="active"
//                 className={`navbar-item ${activeItem === 'home' ? 'active' : ''}`}
//                 onClick={() => handleItemClick('home')}
//               >
//                 Home
//               </NavLink>
//                                   <NavLink
//                 to="/subscriptionamount"
//                 activeClassName="active"
//                 className={`navbar-item ${activeItem === 'products' ? 'active' : ''}`}
//                 onClick={() => handleItemClick('products')}
//               >
//                 Our Products
//               </NavLink>
//                   {/* <Link to="/blog"
//                    className={`navbar-item ${activeItem === 'blogs' ? 'active' : ''}`}
//                    onClick={() => handleItemClick('blogs')}>
//                     Blogs</Link> */}
//                       <NavLink
//                 to="/blog"
//                 activeClassName="active"
//                 className={`navbar-item ${activeItem === 'blogs' ? 'active' : ''}`}
//                 onClick={() => handleItemClick('blogs')}
//               >
//                 Blogs
//               </NavLink>
//                   {/* <Link to="/about" className={`navbar-item ${activeItem === 'about' ? 'active' : ''}`}
//                 onClick={() => handleItemClick('about')}
//                 >
//                   About Us</Link> */}
//                                 {/* <Link
//                 to="/about"
//                 className={`navbar-item ${activeItem === 'about' ? 'active' : ''}`}
//                 onClick={() => handleItemClick('about')}
//               >
//                 About Us
//               </Link> */}
//                <NavLink
//                 to="/about"
//                 activeClassName="active"
//                 className={`navbar-item ${activeItem === 'about' ? 'active' : ''}`}
//                 onClick={() => handleItemClick('about')}
//               >
//                 About Us
//               </NavLink>
//                 </div>
//               </div>

//               <div className="contact_us_header">



//                 <Link to="/contactusdetails">
//                   <Button>
//                     Contact Us
//                   </Button>


//                 </Link>


//                 {currentUser ? (
//                     <div>
//                       <Link to="/profilesection">
//                         <a
//                           className="profile_icon me-3 mb-2"
//                           style={{
//                             display: "flex",
//                             textAlign: "center",
//                             alignItems: "center",
//                           }}
//                         >
//                           {" "}
//                           <div>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
//                               <img src={user_profile} />
//                               <h6
//                                 style={{
//                                   fontWeight: "bolder",
//                                   fontSize: "18px",
//                                 }}
//                               >
//                                 {parsedId?.firstname}  
//                               </h6>
//                             </div>
//                             <div style={{color:"white"}}>User ID :  
//                              {UserList[0]?.user_id}
//                              </div>
//                           </div>
//                         </a>
//                       </Link>
//                     </div>
//                   ) : (
//                     <div>
//                       <span className="signinbtn" onClick={showModal}>
//                         Sign In
//                       </span>
//                     </div>
//                   )}
//                   <div className="headerSpeakerIcon">
//                     <HiSpeakerWave/>
//                   </div>
//               <div className="toggle_field mt-2 ms-2 mb-2">
// 	<input className="toggle_input" type="checkbox" id="toggle-btn"
//   onChange={toggleTheme} checked={darkMode}
//   />
// 	<label className="toggle_label" for="toggle-btn"></label>
// </div>

//                 <div className="login_model">
//                   <Modal
//                     width="50%"
//                     open={isModalOpen}
//                     onOk={handleOk} 
//                     onCancel={handleCancel}
//                     okButtonProps={{ hidden: true }}
//                     cancelButtonProps={{ hidden: true }}
//                   >
//                     <Login clickRow={clickRow} pageType={pageType} handlePage={handlePage} page="header" />
//                   </Modal>
//                 </div>


//               </div>
//             </div>
//           </nav>
//         </Container>
//       </div>
//       </div>
//     </>
//   );
// };

// export default Header1;
import React, { useState, useEffect, useContext } from "react";
import { Switch } from "antd";
import { Container, Row, Col, Button } from "reactstrap";
import Headerlogo from "../../assets/Images/Business Champions latest.png";
import user_logout from "../../assets/Images/user_logout.png";
import user_profile from "../../assets/Images/user_profile.png";
import Searchicon from "../../assets/Images/search_icon.png";
import Switchs from "../../assets/Images/switch.png";
import "../../assets/SCSS/Header1.scss";
import { Link } from "react-router-dom";
import { useNavigate, useHistory } from "react-router-dom";
import { Modal } from "antd";
import Login from "../../Pages/Login";
import { ElearningAxios } from "../../index";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "../../utils/ThemeProvider";
import axios from "axios";
import { HiSpeakerWave } from "react-icons/hi2";

const Header4 = (props) => {
  // const { darkMode } = props;
  const { toggleTheme, darkMode } = useContext(ThemeContext);
  useEffect(() => {
    ElearningAxios("/api/announcement")
      .then((response) => {
        setAnnouncementData(response.data.data);
        setAnnouncementerror(null);
      })
      .catch(setAnnouncementerror);
  },[] );
  const [UserList, setUserList] = useState([]);
  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);
useEffect(()=>{
  
  const getUserlist = async () => {
    console.log('one')
    axios.get(`api/user/${parsedId._id}`).then((res) => {
     setUserList(res.data.data);
   });
 };
 getUserlist()
},[userid])

  const [AnnouncementData, setAnnouncementData] = useState([]);
  const [Announcementerror, setAnnouncementerror] = useState(null);
  const user_id=UserList[0]?.user_id;
  const [pageType, setPageType] = useState("LOGIN");
  

  const navigate = useNavigate();
  let currentUser = localStorage.getItem("currentUser");
  let userDetail = localStorage.getItem("userDetail");

  function logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userDetail");
    navigate("/");
  }
  const getUserlist = async () => {
     axios.get(`api/user/${parsedId._id}`).then((res) => {
      setUserList(res.data.data);
    });
  };
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setPageType("LOGIN");
  };

  const clickRow = (status) => {
    if (status === "cancel") {
      setIsModalOpen(false);
    }
  };
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };
  
  const handlePage = (value) => {
    setPageType(value);
  };
 
  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div className="header_total_section">
          {/* <div className="headerTop">
          <Container>
            <ul className="rightMenu">

              <li><Link to="/">info@businesschamp.com</Link></li>

              <li><Link to="/">+91 9994392983</Link></li>

            </ul>
          </Container>
        </div> */}
          <Container>
            <nav class="header_left navbar navbar-expand-sm navbar-dark">
              <div class="container header1-design">
                <div className="menuLeft">
                  <Link to="/">
                    <div className="header1-image">
                      <img width={150} alt="headerlogo" src={Headerlogo} />
                    </div>
                  </Link>
                  <div className="header_links">
                    {/* <Link to="/plan">Products</Link> */}
                    {/* <Link to="/subscriptionamount"
                                  className={`navbar-item ${activeItem === 'products' ? 'active' : ''}`}
                                  onClick={() => handleItemClick('products')}

                                  >Our Products</Link> */}
                    <NavLink
                      to="/"
                      activeClassName="active"
                      className={`navbar-item ${
                        activeItem === "home" ? "active" : ""
                      }`}
                      onClick={() => handleItemClick("home")}
                    >
                      Home
                    </NavLink>
                    <NavLink
                      to="/subscriptionamount"
                      activeClassName="active"
                      className={`navbar-item ${
                        activeItem === "products" ? "active" : ""
                      }`}
                      onClick={() => handleItemClick("products")}
                    >
                      Our Products
                    </NavLink>
                    {/* <Link to="/blog"
                   className={`navbar-item ${activeItem === 'blogs' ? 'active' : ''}`}
                   onClick={() => handleItemClick('blogs')}>
                    Blogs</Link> */}
                    <NavLink
                      to="/blog"
                      activeClassName="active"
                      className={`navbar-item ${
                        activeItem === "blogs" ? "active" : ""
                      }`}
                      onClick={() => handleItemClick("blogs")}
                    >
                      Blogs
                    </NavLink>
                    {/* <Link to="/about" className={`navbar-item ${activeItem === 'about' ? 'active' : ''}`}
                onClick={() => handleItemClick('about')}
                >
                  About Us</Link> */}
                    {/* <Link
                to="/about"
                className={`navbar-item ${activeItem === 'about' ? 'active' : ''}`}
                onClick={() => handleItemClick('about')}
              >
                About Us
              </Link> */}
                    <NavLink
                      to="/about"
                      activeClassName="active"
                      className={`navbar-item ${
                        activeItem === "about" ? "active" : ""
                      }`}
                      onClick={() => handleItemClick("about")}
                    >
                      About Us
                    </NavLink>
                  </div>
                </div>

                <div className="contact_us_header">
                  <Link to="/contactusdetails">
                    <Button>Contact Us</Button>
                  </Link>

                  {currentUser ? (
                    <div>
                      <Link to="/profilesection">
                        <a
                          className="profile_icon me-3 mb-2"
                          style={{
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <div>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                              <img src={user_profile} />
                              <h6
                              className="UserNameClass"
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "18px",
                                }}
                              >
                                {parsedId?.firstname}  
                              </h6>
                            </div>
                            <div className="UserIdClassName" style={{color:"white"}}>User ID :  
                            {user_id}
                            
                            {/* { console.log('swddd',user_id)} */}
                             </div>
                          </div>
                        </a>
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <span className="signinbtn" onClick={showModal}>
                        Sign In
                      </span>
                    </div>
                  )}
                  <NavLink to="/AnnouncementPage" style={{cursor:"pointer"}}  
                  //  activeClassName="active"
                      // className={`navbar-item ${
                      //   activeItem === "AnnouncementPage" ? "active" : ""
                      // }`}
                      // onClick={() => handleItemClick("AnnouncementPage")} 
                      >
                    <div className="headerSpeakerIcon">
                    <HiSpeakerWave className="HiSpeakerWaveInheader" title="Announcement"/>
                  </div>
                  </NavLink>
                  <div className="toggle_field mt-2 ms-2 mb-2">
                    <input
                      className="toggle_input"
                      type="checkbox"
                      id="toggle-btn"
                      onChange={toggleTheme}
                      checked={darkMode}
                    />
                    <label className="toggle_label" for="toggle-btn"></label>
                  </div>
                  {/* <button onClick={handleToggle}>Toggle Mode</button> */}
                  {/* (<Switch defaultChecked onChange={onChange} onClick={handleToggle}  style={{width:"25px",height:"30px",marginTop:"5px" }}/>); */}

                  {/* {currentUser ?
<div style={{color:"white"}} className="ms-3">User Id: {parsedId._id}</div>:<div></div>
} */}

                  <div className="login_model">
                    <Modal
                      width="50%"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okButtonProps={{ hidden: true }}
                      cancelButtonProps={{ hidden: true }}
                    >
                      <Login
                        clickRow={clickRow}
                        pageType={pageType}
                        handlePage={handlePage}
                        page="header"
                      />
                    </Modal>
                  </div>
                </div>
              </div>
            </nav>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Header4;
