import { useState, useEffect } from 'react';

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedMode = localStorage.getItem('mode');
    setDarkMode(storedMode === 'dark');
  }, []);

  const toggleMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('mode', newMode ? 'dark' : 'light');
  };

  return { darkMode, toggleMode };
};

export default useDarkMode;