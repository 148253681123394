import { AboutusType } from '../actions/aboutus';

const INITIAL_STATE = {
    aboutusitems: []
};



export default function Aboutus(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AboutusType.GET_ABOUTUS_SUCCESS: {
            return {
                ...state,
                aboutusitems: action.payload.aboutusitems
            }
        }

        // case AboutusType.GET_ABOUTUS_ERROR: {
        //     return {
        //         ...state,
        //         error: action.payload.error
        //     }
        // }

        default: {
            return state;
        }
    }
}
