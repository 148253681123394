export const AboutusType = {
    GET_ABOUTUS_REQUEST: "aboutus/get_aboutus_request",
    GET_ABOUTUS_SUCCESS: "aboutus/get_aboutus_success",

};


export const getaboutusrequest = () => ({
    type: AboutusType.GET_ABOUTUS_REQUEST,
});

export const getaboutusSuccess = ({ aboutusitems }) => ({
    type: AboutusType.GET_ABOUTUS_SUCCESS,
    payload: { aboutusitems },
});
