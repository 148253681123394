import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
// import { Card } from "antd";
import demomodel from "../../assets/Images/Ellipse2.png"
import Rectangle3 from "../../assets/Images/cardtitle.png"
import card1lessons from "../../assets/Images/card1lessonsblue.png"
import card1student from "../../assets/Images/card1studentblue.png"
import Rectangle2 from "../../assets/Images/Rectangle3.png"
import Rectangle4 from "../../assets/Images/Rectangle4.png"
import "../../assets/SCSS/papular.scss";
import { getCoursemoduleRequest, getCoursemoduleSuccess } from "../../actions/coursemodule";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";



const Popularcourses = (props) => {
  const {darkMode}= props

  useEffect(() => {
    props.getCoursemoduleRequest();
  }, [])

  let Coursemoduledata = props?.coursemodule?.coursemoduleitems;
  return (
    <>
      <div className="card_section">
        <Container>
          <div>
            {/* <div className="learn_card">
              <h6>Beyond Learning</h6>
              <p>Come, let us rediscover the Leader within every business owner, who will take <br />the organisation to new heights, make it prosperous and perpetual. </p>
            </div> */}
            {/* <div className="learncard_design">
              <div className="R-talign" >
                <Row>
                  {Coursemoduledata.slice(1, 7).map((moduledata) => {
                    console.log(moduledata);
                    return <>
                      <Col md='4'>
                        <Card
                          className="total_card_popular"
                        >
                          <img alt="Sample" src={"http://143.198.66.255/assets/images/cars/" + moduledata?.image} width="100%" />
                          <CardBody>
                            <div className="card_body_popular">
                            

                              <CardSubtitle className="card_subtitle" tag="h6">
                                {moduledata.subTitle}
                              </CardSubtitle>
                              <CardText className="cardtext">
                                {moduledata.description}
                              </CardText>
                              <div
                                className="card_text_popular"

                              >
                                <div className="card_text_header">
                                  <img
                                    width={20}
                                    height={20}
                                    src={card1lessons}
                                  />
                                  <CardText className="card_count">
                                    {moduledata.courses.length}Lessons
                                  </CardText>
                                </div>
                                <div className="card_text_header">
                                  <img
                                    width={20}
                                    height={20}
                                    src={card1student}
                                  />
                                  <CardText className="card_count">
                                    {moduledata.totalHours}
                                  </CardText>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  })};
                </Row>
              </div>

            </div> */}
            {/* <div className="learn_content">
              <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator<br /> of your own career & we will guide your through that. <Link to="/signup"><span>Register Free Now!</span></Link></p>
            </div> */}
          </div>
        </Container>
      </div>

    </>
  );
};

// export default Popularcourses;


export default connect((Coursemodule) => Coursemodule, {
  getCoursemoduleRequest,
  getCoursemoduleSuccess
})(Popularcourses);


