import React from 'react'
import Submitpage from '../components/Submitpage/Submitpage'
import Header1 from "../components/Layout/Header1"
const Submitpagedetails = () => {
  return (
    <>
      <Header1/>
      <Submitpage/>
    </>
  )
}

export default Submitpagedetails