import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Container } from "reactstrap";
import { ElearningAxios } from "../.../../index";
import { digitaloceanspacesurl } from "../api/constant";
import "../assets/SCSS/Mycourses.scss";
import Header1 from "../components/Layout/Header1";
import useDarkMode from "../Pages/useDarkMode";
import { ThemeContext } from "../utils/ThemeProvider";

const AnnouncementPage = () => {
  const { toggleMode } = useDarkMode();

  const { darkMode } = useContext(ThemeContext);

  const [mycourse, setMycourse] = useState(null);
  const AnnouncementList = mycourse?.data || [];
  const [error, setError] = useState(null);

  useEffect(() => {
    ElearningAxios("/api/announcement")
      .then((response) => {
        setMycourse(response.data);
      })
      .catch(setError);
  }, []);
  
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
              <Header1 handleToggle={toggleMode} />

      <div className="AnnouncementPageBodyContainer">
        <center>
          <div style={{ fontWeight: "bolder", fontSize: "20px",color:darkMode?"white":"black" }} className="pt-5 pb-5">ANNOUNCEMENTS</div>
        </center>
        <Container>
          <Row>
            {AnnouncementList?.map((i) => (
              <Col lg={4} md={6} key={i.id}>
                <Card className="MyCoursePurchaseCard m-3">
                  <div className="px-4 pt-2" style={{ fontWeight: "bolder" }}> {formatDate(i.createdAt)}</div>
                  <img
                    className="AnnouncementpageImage m-4"
                    src={digitaloceanspacesurl + i?.image}
                    alt="CardImage"
                  />
{                  console.log('firs@@@t', digitaloceanspacesurl + i?.image)
}                  <div className="px-4 pb-3">
                    <div style={{ fontSize: "20px", fontWeight: "700" }}>{i.title}</div>
                    <div style={{ fontSize: "15px", fontWeight: "500" }}>Start Date: {formatDate(i.startDate)}</div>
                    <div style={{ fontSize: "15px", fontWeight: "500" }}>End Date: {formatDate(i.endDate)}</div>
                    {i.url && (
                      <div >
                        <a className="AnnouncementUrl pt-1 pb-1" style={{fontSize:"15px"}} href={i.url} target="_blank" rel="noopener noreferrer">
                          {i.url}
                        </a>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AnnouncementPage;
