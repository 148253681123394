import React, { useState, useEffect, createContext, useContext } from "react";
import useDarkMode from '../Pages/useDarkMode';

import Homepage from "../components/Homepage/Homepage";
import Educationforeveryone from "../components/Homepage/Educationforeveryone";
import OnlineLearning from "../components/Homepage/OnlineLearning";
import "../assets/SCSS/Homepage.scss";
import Popularcourses from "../components/Homepage/Popularcourses";
import Distancelearning from "../components/Homepage/Distancelearning";
import Footer from "../components/Layout/Footer";
import Header1 from "../components/Layout/Header1";
import Coursevideo from "../components/Homepage/Coursevideo";
import "../assets/SCSS/responsive.scss";
import { Modal } from "antd";
import { getaboutusrequest } from '.././actions/aboutus';
import { connect } from "react-redux";
import { ElearningAxios } from "../index";
import moment from "moment";
import { ThemeContext } from '../utils/ThemeProvider';

export const homedata = createContext();
const Home = (props) => {
  useEffect(() => {
    props.getaboutusrequest()
  }, []);
  const Homeabout = props.Aboutus.aboutusitems;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [AnnouncementData, setAnnouncementData] = useState([]);
  const [Announcementerror, setAnnouncementerror] = useState(null);
  const [annoucementvisible, setAnnouncementvisible] = useState();
  let isadvertisementClose = true
  useEffect(() => {
    setIsModalOpen(true); 
    ElearningAxios("/api/announcement")
      .then((response) => {
        setAnnouncementData(response.data.data);
        setAnnouncementerror(null);
      })
      .catch(setAnnouncementerror);


  }, []);
  const {  toggleMode } = useDarkMode();
const{darkMode}=useContext(ThemeContext)
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    sessionStorage.setItem('isadvertisementClose', true);
    setIsModalOpen(false);
  };
  isadvertisementClose = sessionStorage.getItem('isadvertisementClose');

  const announcement_Data = AnnouncementData.slice(0, 1)?.map((a) => {
    return a.endDate;
  });
  const newdate = new Date();
  const d1 = moment.utc(newdate).format('YYYY-MM-DD');
  const Today = new Date(announcement_Data);
  const d2 = moment.utc(Today).format('YYYY-MM-DD');

  const Expirydate = d1 < d2;

  return (
    <>
       <div className={darkMode ? 'dark-mode' : 'light-mode'}>

      <div className="total_sections">
        <homedata.Provider value={Homeabout}>
          <Header1 handleToggle={toggleMode}  />
          <Homepage darkMode={darkMode} />
          <Educationforeveryone darkMode={darkMode}/>
          <OnlineLearning darkMode={darkMode}/>
          <Popularcourses  darkMode={darkMode}/>
          {/* <div className="learning_back_image"> */}
          <Distancelearning darkMode={darkMode}/>
          <Coursevideo darkMode={darkMode} />
          {/* </div> */}
          <Footer />
        </homedata.Provider>
        {!isadvertisementClose && Expirydate ?
         (
          <div></div>
        //  <Modal
        //   width={500}
        //   height={100}
        //   open={isModalOpen}
        //   onOk={handleOk}
        //   onCancel={() => handleCancel("Announcement-cancel")}
        //   okButtonProps={{ hidden: true }}
        //   cancelButtonProps={{ hidden: true }}
        // >
        //   <div className="Announcement_image" >
        //     {AnnouncementData.slice(0, 1)?.map((n) => {
        //       return (
        //         <p className="announcement">
        //           <a href={n?.url} target="_blank">
        //             <img
        //               src={"http://54.86.219.221/assets/images/cars/" + n.image}
        //             />
        //           </a>
        //         </p>
        //       );
        //     })}
        //   </div>
        // </Modal>
        ) : (<></>
        )}

      </div>
      </div>
    </>
  );
};


export default connect((Aboutus) => Aboutus, {
  getaboutusrequest,
})(Home);
