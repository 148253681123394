import {
    takeEvery,
    takeLatest,
    take,
    call,
    put,
    fork,
} from "redux-saga/effects";
import * as AboutusActions from "../actions/aboutus";
import * as AboutusApi from "../api/about";


function* getaboutus() {
    try {
        const result = yield call(AboutusApi.getAboutusAttribute);
        yield put(
            AboutusActions.getaboutusSuccess({
                aboutusitems: result?.data?.data,
            })
        );
    } catch (e) {
        yield put(

        );
    }
}


function* watchGetAboutusRequest() {
    yield takeEvery(
        AboutusActions.AboutusType.GET_ABOUTUS_REQUEST,
        getaboutus
    );
}



const Aboutus = [
    fork(watchGetAboutusRequest),
];
export default Aboutus;
