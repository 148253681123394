import {
    takeEvery,
    takeLatest,
    take,
    call,
    put,
    fork,
} from "redux-saga/effects";
import * as MessageActions from "../actions/message";
import * as MessageApi from "../api/message";


function* getmessage() {
    try {
        const result = yield call(MessageApi.getMessageAttribute);
        yield put(
            MessageActions.getmessageSuccess({
                messageitems: result?.data?.data,
            })
        );
    } catch (e) {
        yield put(

        );
    }
}


function* watchGetMessageRequest() {
    yield takeEvery(
        MessageActions.MessageType.GET_MESSAGE_REQUEST,
        getmessage
    );
}


function* Post_message({ payload }) {

    try {

        let subjectid = yield call(MessageApi.CreateMessage, payload);


        yield put(MessageActions.PostmessageafterSuccess({ messageitems: subjectid?.data?.data, }));

        yield call(getmessage);


    }

    catch (e) {

    }


}
function* watchCreateMessage() {
    yield takeLatest(MessageActions.MessageType.POST_MESSAGE_SUCCESS, Post_message);
}


function* getMessage_subjectID(val) {
    try {
        const result = yield call(MessageApi.getMessageBySubjectId, val.payload);
        yield put(MessageActions.getMessagebySubjectID({ messagesubjectiditem: result?.data?.data })
        );
    } catch (e) {

        yield put(

        );
    }
}


function* watchCreateMessage_by_subjectID() {
    yield takeEvery(
        MessageActions.MessageType.GET_MESSAGESUBJECTID_REQUEST,
        getMessage_subjectID
    );
}


const Message = [
    fork(watchGetMessageRequest),
    fork(watchCreateMessage),
    fork(watchCreateMessage_by_subjectID)
];
export default Message;
