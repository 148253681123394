import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import Develop from "../../assets/Images/homepageCropimagedark.png";
import "../../assets/SCSS/Onlinelearning.scss";
import Online1 from "../../assets/Images/Online1blue.png";
import Online1Bule from "../../assets/Images/Online1.png";

import Online2 from "../../assets/Images/Online2blue.png";
import online2Red from "../../assets/Images/Online2.png"
import Online4 from "../../assets/Images/Online4blue.png";
import Online4red from "../../assets/Images/Online4.png"
import viewallicon from "../../assets/Images/viewallicon.png";
import onlinetop from "../../assets/Images/onlinetopblue.png";
import online_circle from "../../assets/Images/online_circleblue.png";
import dot_circle from "../../assets/Images/dot_circle.png";
import { Link } from "react-router-dom";
import Develop1 from "../../assets/Images/homepageCropLite.png";
import onlinetop1 from"../../assets/Images/onlinetop.png";

const OnlineLearning = (props) => {
  const { darkMode } = props;
  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
    <div className="total_onlinelearning">
      <Container>
        <div>
          <div>
            <img src={darkMode?onlinetop:onlinetop1} />
          </div>
          <Row className="online-lrn">
            <Col md="6">
              <div>
                <img className="r-values" width={"100%"} src={darkMode?Develop:Develop1} />
              </div>
            </Col>
            <Col md="6">
              <div className="online_learning">
                <h6>Are you puzzled? We’ve got a solution!</h6>
                <h4>
                  Why Small businesses remain Small <br /> for long?
                </h4>
                <div className="online_para">
                  <p>
                    We know, you – like many small business owners - are striving to solve the puzzle.  At Business Champions Academy, we researched extensively on the topic and have found the answer. The courses are designed keeping these so-far secret answers mind.
                  </p>
                  <span className="online_circle">
                    {/* <img src={online_circle} width={40} /> */}
                  </span>
                </div>
              </div>

              <div
                className="online_list"
              >

                <div className="online_learning_main1">
                  <div className="online_learning_sub">
                    {/* <img src={Online2} /> */}
                    <img src={darkMode?Online2:online2Red} />

                    <span className="online_learning_name">
                      Practical Learning
                    </span>
                  </div>
                  <div className="online_learn_flex">
                    {/* <img src={Online1} /> */}
                    <img src={darkMode?Online1:Online1Bule}/>
                    <span className="online_learning_name">
                      Learn from Anywhere, at your ease
                    </span>
                  </div>
                </div>

                <div className="online_learning_main2">
                  <div className="online_membership">
                    {/* <img src={Online4} /> */}
                    
                    <img src={darkMode?Online4:Online4red} />
                    <span className="online_learning_name">
                      Perpetual Membership  to Champions Community
                    </span>
                  </div>
                  <div className="online_membership2">
                    {/* <img src={Online2} /> */}
                    <img src={darkMode?Online2:online2Red} />

                    <span className="online_learning_name">
                      Workbooks, support materials, webtools and more
                    </span>
                  </div>
                </div>

              </div>


              <div className="dot_circle_part">
                <Link to="/subscriptionamount">
                  <Button className="viewall_btn"> 
                    <img src={viewallicon} />
                    View All Courses
                  </Button>
                </Link>
                <div className="dot_circle_img">
                  <img src={dot_circle} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
    </div>
  );
};

export default OnlineLearning;
