import React, { useContext, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
// import logo1 from "../../assets/Images/signupdarktheme1.png";
// import logo2 from "../../assets/Images/signupdarktheme2.png"
// import logo1 from "../../assets/Images/signupdarktheme1.png";
// import logo2 from "../../assets/Images/signupdarktheme2.png"
import { Form, Input, } from 'antd';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import logo1 from "../../assets/Images/logo1.png";
import arrow1 from "../../assets/Images/ic_baseline-arrow-back.png";
import arrow2 from "../../assets/Images/ic_baseline-arrow-back-1.png";
import logo2 from "../../assets/Images/e-learlogo.png";
import "../../assets/SCSS/Login.scss";
import { authenticationService } from "../Service/authentication";
import { useNavigate } from "react-router-dom";
// import { Form } from "antd";
import OTPInput from "otp-input-react";
import OtpInput from 'react-otp-input';
import { ElearningAxios } from "../../index";
import Signuppage from "../Signup/Signuppage";
import jwt from "jwt-decode";
import { toast } from "react-toastify";
import { ThemeContext } from "../../utils/ThemeProvider";
import useDarkMode from "../../Pages/useDarkMode";
import { DoubleLeftOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { authenticationSignup } from "../Service/Signupauthentication";

const Loginpage = ({ clickRow, pageType, handlePage,page }) => {
  const { toggleMode } = useDarkMode();
  const { darkMode } = useContext(ThemeContext);

  const [forgotemail, setforgotemail] = useState();
  const [forgototpnumber, setforgototpnumber] = useState();
  const [resetPassword, setResetPassword] = useState();

  const [otpnumber, setOtpNumber] = React.useState("");
  console.log('firstotpnumber', otpnumber)
  const [userOtp, setUserOtp] = React.useState(false);
  console.log('first', userOtp)

  const [signupdata, setSignupdata] = useState("");
  const [otptoken, setOtptoken] = React.useState([]);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  //otp
  // const Otpsubmit = async (otptoken) => {
  //   if (otpnumber == "") {
  //     toast.error("please enter Otp");
  //   }
  //   await ElearningAxios.post("/api/user/forgot-otp-verification", {
  //     email: signupdata.email,
  //     otp: otpnumber,
  //     // password: signupdata.password,
  //   }).then(
  //     (res) => {
  //       if (res.data.token) {
  //         toast.success("User registration successful");
  //         window.location.reload();
  //       } else {
  //         toast.error("Your OTP is incorrect");
  //       }
  //       let userDetail = jwt(res.data.token);
  //       localStorage.setItem("currentUser", JSON.stringify(res.data.token));
  //       localStorage.setItem("userDetail", JSON.stringify(userDetail));
  //     },
  //     (error) => {
  //       toast.error("Your OTP is incorrect");
  //     }
  //   );
  // };
  const Otpsubmit = async (otptoken) => {
    if (otpnumber === "") {
      toast.error("Please enter OTP");
      return;
    }

    try {
      const response = await ElearningAxios.post(
        "/api/user/forgot-otp-verification",
        {
          email: forgotemail, // Make sure `forgotemail` has the correct value
          otp: otpnumber,
        }
      );
      // toast.success("User registration successful");

      if (response.status === 200) {
        // The OTP verification is successful
        toast.success("User registration successful");
        // window.location.reload();
      } else {
        // The OTP is incorrect
        toast.error("Your OTP is incorrect");
      }

      const userDetail = jwt(response.data.token);
      localStorage.setItem("currentUser", JSON.stringify(response.data.token));
      localStorage.setItem("userDetail", JSON.stringify(userDetail));
    } catch (error) {
      console.log(error);
    }
  };

  const Signup = async (signupdata) => {
    setSignupdata(signupdata);

    authenticationSignup.Signup(signupdata).then((res) => {
      if (res.status) {
        setUserOtp(true);
      }
    });
  };
  //otp end
  const navigate = useNavigate();

  const login = (formvalues) => {
    authenticationService.login(formvalues).then(
      (user) => {
        if (user) {
          const userData = jwt(user.data.token);
          localStorage.setItem("currentUser", JSON.stringify(user.data.token));
          localStorage.setItem("userDetail", JSON.stringify(userData));
          if(page){
            navigate('/');

          }else{
            navigate(`/docuspage/${id}`);

          }

          
          clickRow("cancel");
          toast.success("User login successful");
        }
      },
      (error) => {
        toast.error("Your email or password is incorrect");
      }
    );
  };

  const otpfield = async (e) => {
    await ElearningAxios.post("/api/user/forgetpassword", {
      email: forgotemail,
    });
    setUserOtp(true);
  };

  const forgototp = async (e) => {
    await ElearningAxios.post("/api/user/resetPassword", {
      otp: forgototpnumber,
    }).then((res) => {});
  };
  const data = JSON.parse(localStorage.getItem("SettingKey"));
  var id = data[0]._id;
  console.log('firstid', id)
  // console.log(data[0]._id,"hhh");
  // const handlesubmit = async (e) => {
  //   // console.log(e,"ddd");
  //   const email = e.email;
  //   const password = e.password;
  //   await ElearningAxios.put("/api/user/changeuserpassword", {
  //     email: email,
  //     password: password,
  //     _id: id,
  //   }).then((res) => {
  //     console.log("res.data!!", res.data);
  //   });
  // };
  const handlesubmit = async (e) => {
    const email = e.email;
    const newpassword = e.newpassword;
  
    try {
      const response = await ElearningAxios.post("/api/user/comparepassword",
      {
        email: email,
        newpassword: newpassword,
      });
      if (response.data.Success) {
        // Password updated successfully
        toast.success("User password updated successfully");
      } else {
        // Password update failed
        toast.error("Failed to update user password");
      }
  
      console.log("res.data!!", response.data);
    } catch (error) {
      toast.error("An error occurred while updating user password");
      console.log(error);
    }
  };
  
  // const ResetUserPassword = async (e) => {
  //   await ElearningAxios.put("/api/user/changeuserpassword", {
  //     email: resetPassword,
  //     password: resetPassword,
  //     _id: resetPassword,
  //   }).then((res) => {
  //     console.log("res.data!!", res.data);
  //   });
  // };
  function ForgotPasswordView() {
    return (
      <>
        <div className={darkMode ? "dark-mode" : "light-mode"}>
          <div class="accordion-body">
            {userOtp == true ? (
              <div className="open_otp">
                <div className="total_otp">
                  <Form
                    layout="vertical"
                    className="forgot_username"
                    onFinish={Signup}
                  >
                    {/* <Form.Item className="verify_email" name="email">
                      <label
                        className="custom-label"
                        style={{
                          color: darkMode ? "#FFFFFF" : "black",
                          marginBottom: "10px",
                          display: "flex",
                        }}
                      >
                        <span style={{ color: "red" }}>*</span> Emailss:
                      </label>
                      <Input
                        className="mb-4"
                        style={{ color: "black" }}
                        maxlength="254"
                      />
                    </Form.Item> */}
                    <OTPInput
                      className="otp_input"
                      style={{ color: darkMode ? "white" : "black", }}
                      value={otpnumber}
                      onChange={setOtpNumber}

                      // autoFocus
                      OTPLength={4}
                      otpType="number"
                    />
                  </Form>
                </div>
                <Button
                  className="mt-3"
                  onClick={() => {
                    handlePage("ResetPassword");
                    Otpsubmit(otptoken);
                  }}
                >
                  Verify
                </Button>
              </div>
            ) : (
              <Form
                layout="vertical"
                className="forgot_username"
                onFinish={Signup}
              >
                <Form.Item className="verify_email">
                  <label
                    className="custom-label"
                    style={{
                      color: darkMode ? "#FFFFFF" : "black",
                      marginBottom: "10px",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span> Email
                  </label>
                  <Input
                    value={forgotemail}
                    onChange={(e) => {
                      setforgotemail(e.target.value);
                    }}
                    autoFocus
                  />
                </Form.Item>

                <Button className="verify_email_submit" onClick={otpfield}>
                  Submit
                </Button>
              </Form>
            )}
          </div>
        </div>
      </>
    );
  }

  function OtpView() {
    return (
      <div>
        <OTPInput
          style={{ color: "#fff" }}
          value={forgototpnumber}
          onChange={setforgototpnumber}
          autoFocus
          OTPLength={4}
          otpType="number"
        />
        <Button onClick={forgototp}>Verify</Button>
      </div>
    );
  }

  function ResetPassword() {
    return (
      <div>
        <Form
          layout="vertical"
          className="forgot_username"
          onFinish={handlesubmit}
          // onFinish={Signup}
        >
          <Form.Item
            name="email"
            label="Email"
            className="inpute"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="newpassword"
            label="New Passwordss"
            className="inpute"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
                        <Input.Password type="password" required maxLength="254" />

          </Form.Item>
          <Button className="bttn-login mt-3" type="submit">Submit</Button>
        </Form>
      </div>
    );
  }
  function LoginView() {
    return (
      <>
        <div className={darkMode ? "dark-mode" : "light-mode"}>
          <Form onFinish={login} layout="vertical" className="total_login_form">
            <Form.Item
              name="email"
              className="inpute"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input required maxLength="254" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              className="inpute"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
                                      {/* <Input type="password" required maxLength="254" /> */}
                                      <Input.Password
        maxLength="254"
        iconRender={(visible) =>
          visible ? (
            <EyeOutlined onClick={togglePasswordVisibility} />
          ) : (
            <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
          )
        }
      />

 
   </Form.Item>

            <div className="Forget_pass">
              <a
                onClick={() => handlePage("FORGOTPASS")}
                style={{ color: darkMode ? " #FFFFFF" : "black" }}
              >
                Forgot Password?
              </a>
              <a  onClick={() => handlePage("SIGNUP")} style={{ color: darkMode ? " #FFFFFF" : "black" }}>
                New User? Sign Up
              </a>
            </div>
            <Button type="primary" className="bttn-login" htmlType="submit"   >
              Submit
            </Button>
           
          </Form>
        </div>
      </>
    );
  }

  function PageView({ pageType }) {
    switch (pageType) {
      case "SIGNUP":
        return <Signuppage />;
      case "LOGIN":
        return <LoginView />;
      case "OTP":
        return <OtpView />;
      case "FORGOTPASS":
        return <ForgotPasswordView />;
      case "ResetPassword":
        return <ResetPassword />;
      default:
        return <LoginView />;
    }
  }

  return (
    <>
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div className="login_design" handleToggle={toggleMode}>
          <Container className="cnt_color">
            <Row>
              <Col md="12">
              <a
                    className="SignupBackBtn"
                    style={{
                      color: darkMode ? "rgb(255 254 254 / 30%)" : "black",
                      fontSize: "20px"
                    }}
                    onClick={() => handlePage("LOGIN")}
                  >
                    {pageType !== "LOGIN" ? <DoubleLeftOutlined 
                      /> : null}
                  </a>
                <div>
               
                  <div className="Img_arrow">
                   
                 
                  </div>
                  <div className="Img_business">
                    <img src={darkMode ? logo1 : logo2} />
                  </div>
                 
                  <PageView pageType={pageType} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Loginpage;
