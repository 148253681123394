import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useParams, HashRouter } from 'react-router-dom';

import loader from './assets/Images/loader copy.gif'


import reducers from './reducer/index';
import rootSaga from './sagas/index';
import { ThemeProvider } from './utils/ThemeProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;

export const ElearningAxios = axios.create({ baseURL: process.env.REACT_APP_DOMAIN });

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);


export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const inc = mod => setCounter(c => c + mod);
    const handleRequest = config => (inc(1), config);
    const handleResponse = response => (inc(-1), response);
    const handleError = error => (inc(-1), Promise.reject(error));
    // add request interceptors
    const reqInterceptor = ElearningAxios.interceptors.request.use(handleRequest, handleError);
    // add response interceptors
    const resInterceptor = ElearningAxios.interceptors.response.use(handleResponse, handleError);
    return () => {
      // remove all intercepts when done
      ElearningAxios.interceptors.request.eject(reqInterceptor);
      ElearningAxios.interceptors.response.eject(resInterceptor);
    };
  }, []);
  return counter > 0;
};

export const GlobalLoader = () => {
  const loading = useAxiosLoader();

  return (
    loading ? <div className='loader-lb'><img src={loader} width='5%' /></div> : <></>
  );
}


root.render(
  <Provider store={store}>
    <HashRouter>
      <GlobalLoader />
      <ThemeProvider>
      <App />
      </ThemeProvider>
      <ToastContainer />
    </HashRouter>
  </Provider>
);

