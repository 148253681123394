import React, {  useEffect, useState } from 'react'
const ThemeContext=React.createContext()

const ThemeProvider=({children})=> {
   
    const [darkMode,setDarkmode]=useState(localStorage.getItem(false))
    const toggleTheme=()=>{
        localStorage.setItem('theme',!darkMode?'dark':'light')
        setDarkmode(!darkMode)
    }
useEffect(() => {
  const theme=localStorage.getItem('theme')
  if(theme){
    setDarkmode(theme==='dark'?true:false)
  }
}, [localStorage])
  return (
   
      <ThemeContext.Provider value={{toggleTheme,darkMode}}>
        {children}
      </ThemeContext.Provider>
   
  )
}

export  {ThemeProvider,ThemeContext}
