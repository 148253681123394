import { takeEvery, call, put, fork, takeLatest, take } from "redux-saga/effects";
import * as coursemoduleactions from "../actions/coursemodule";
import * as coursemoduleapi from "../api/coursemodule"

function* getCoursemodule() {
    try {
        const result = yield call(coursemoduleapi.getCoursemoduleAttribute);
      {  console.log('resultresult', result)}
        yield put(
            coursemoduleactions.getCoursemoduleSuccess({
                coursemoduleitems: result?.data?.data,
            })
        );
    } catch (e) {

    }
}

function* watchGetCoursemoduleRequest() {
    yield takeEvery(coursemoduleactions.CoursemoduleTypes.GET_COURSEMODULE_REQUEST, getCoursemodule);
}

const coursemodule = [
    fork(watchGetCoursemoduleRequest)
];
export default coursemodule;