import React from "react";
import LearningData from "../Datas/LearningData";
import { Card, Container } from "reactstrap";
import "../../assets/SCSS/Learningdemo.scss";

const Mylearningdemo = () => {
  return (
    <div className="learbox_demo">
      <Container>
        <div className="demo_section_heading">
          <h2>Giving Shape To Your Dream</h2>
          <div className="learning-card">
            {LearningData?.shapedata?.map((data) => {
              const {
                prgress,
                learnimage,
                learnHeadOne,
                learnHeadTwo,
                learnContentOne,
                learnContentTwo,
              } = data;
              const sass = { width: `${prgress}` };
              return (
                <Card className="All-card">
                  <div className="card-img All-card-img">
                    <img src={learnimage} alt="nam" />
                  </div>
                  <div className="All-card-content">
                    {learnHeadOne}
                    <br></br>
                    {learnHeadTwo}
                    <br></br>
                    <span>{learnContentOne}</span>
                    <div
                      className="progress All-card-content-pro"
                      style={{ height: "10px" }}
                    >
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={sass}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span className="All-card-span"> {learnContentTwo}</span>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>

        <div className="sec_card_demo">
          <h2 className="Learning-heading2">Finding Your Business</h2>
          <div className="learning-card">
            {LearningData?.findingdata?.map((data) => {
              const {
                prgress,
                learnimage,
                learnHeadOne,
                learnHeadTwo,
                learnContentOne,
                learnContentTwo,
              } = data;
              const sass = { width: `${prgress}` };
              return (
                <Card className="All-card">
                  <div className="card-img All-card-img">
                    <img src={learnimage} alt="nam" />
                  </div>
                  <div className="All-card-content">
                    {learnHeadOne}
                    <br></br>
                    {learnHeadTwo}
                    <br></br>
                    <span>{learnContentOne}</span>
                    <div
                      className="progress All-card-content-pro"
                      style={{ height: "10px" }}
                    >
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={sass}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span className="All-card-span">
                      {" "}
                      {learnContentTwo}
                    </span>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Mylearningdemo;
