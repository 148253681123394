import React, { useState, useEffect, useContext } from "react";
import "../../assets/SCSS/Footer.scss";
// import Learnerboxlogo from '../../assets/Images/LearnerBoxLogo.jpeg'
import Line from "../../assets/Images/line.PNG";
import Facebook from "../../assets/Images/fb.png";
import Youtube from "../../assets/Images/yt.png";
import Instagram from "../../assets/Images/insta.png";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useNavigate, useHistory } from "react-router-dom";
import Twitter from "../../assets/Images/tw.png";
import { Container, Row, Col, Button } from "reactstrap";
const Footer = () => {


  const [activeItem, setActiveItem] = useState('');

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <>
      <div className="footer_main">
        <section>
          <Container>
            <div className="footer_learnerbox_logo">
              {/* <img src={Learnerboxlogo} width="5%" /> */}
            </div>
            {/* <div className='footer_learnerbox_title'>
              <h6>Recognised as a startup by department for promotion of industry and Internal Trade</h6>
              <h6>Member of AWS Active Program</h6>
            </div> */}
            <div className="footer_startlearning_title">
              <h5>START LEARNING</h5>
              <div className="footer_line">
                <img src={Line} width="10%" />
              </div>
            </div>
            <center className="mt-3 mb-3">
              <div className="footer_socialicons ">
                <a href="https://www.facebook.com/BusinessChampionsAcademyOfficial" className="me-2">
                  <img className="footerLogo_media" src={Facebook} width="5%" alt="Facebook" />
                </a>{" "}
                <a href="https://www.youtube.com/@BCPAcademy" className="me-2">
                  <img className="footerLogo_media" src={Youtube} width="5%" alt="Facebook" />
                </a>{" "}
                <a href="https://www.instagram.com/bcpacademy/" className="me-2">
                  <img className="footerLogo_media" src={Instagram} width="5%" alt="Facebook" />
                </a>{" "}
                <a href="https://twitter.com/LSeshadriKannan" className="me-2">
                  <img className="footerLogo_media" src={Twitter} width="5%" alt="Facebook" />
                </a>

              </div>
            </center>
          </Container>
        </section>
        <section>
          <div className="footer_usefullinks">
            <Container>
              <div className="about_footer_bcp">
                <h1>About BCP
                </h1>
                <p>BCP Academy is the ultimate destination for entrepreneurs aiming to grow their small and medium organizations. With BCP Academy, you'll have all the necessary tools and resources to make your business prosper and succeed.
                </p>
              </div>
              <Row className="footer_row">
                <Col md="12">
                  <ul className="footer_links">
                    <li className="footer_title_color">USEFUL LINKS</li>
                    <li>  <NavLink
                      to="/about"
                      activeClassName="active"
                      className={`navbar-item ${activeItem === 'about' ? 'active' : ''}`}
                      onClick={() => handleItemClick('about')}
                    >
                      About Us
                    </NavLink>
                    </li>
                    <li><Link to="/contactusdetails">
                      Contact Us
                    </Link></li>
                  </ul>
                </Col>
              </Row>
              <div className="footer_copyright">
                <p>
                  {" "}
                  &#169; Copyright 2022 - Business Champions. All Rights
                  Reserved
                </p>
              </div>
            </Container>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
