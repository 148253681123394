
import React, { useState, useEffect } from "react";
import "../assets/SCSS/Pagedetails.scss";
import Header1 from "../components/Layout/Header1";
import { useParams } from 'react-router-dom';
const Pagedetailspage = (params) => {



  const [course, setCourse] = useState({});

  const { id } = useParams();
  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/api/courses/" + id)
      .then(res => res.json())
      .then(
        (result) => {
          setCourse(result?.data[0]);
        }
      );
  }, [id]);

  return (
    <>
      <Header1 />
      <div className="background">
        <div className="total_course_section">
          <div className="columnContainer">
            <div className="sidebar">
              <section1>
                <div className="courses">
                  <div className="courses_iframe">
                    <img
                      width={100}
                      height={100}
                      src=
                      {course?.images}
                    />
                  </div>

                  <h5>About this course</h5>
                </div>
                <div className="container">
                  <div className="courses_para">
                    <p>
                      {course?.shortDescription}
                    </p>
                  </div>
                  <div className="video_content">
                    <div className="video_heading">
                      <h6>Description</h6>
                    </div>
                    <div className="video_subheading">
                      <div className="content_pagedetail">
                        <p>
                          <span>
                            {course?.description}
                          </span>
                          .
                        </p>
                        <p>
                          I've spent more than a decade working at the
                          intersection of business and media, which has provided
                          me with a unique competitive advantage as we've
                          entered this world where every company is expected to
                          be a media company
                        </p>
                        <p>
                          <span>
                            In this course, you'll learn exactly what it takes
                            to plan and execute a content marketing strategy
                            that drives your business forward
                          </span>
                          . By the end, you'll have all the tools you need to
                          successfully market your products and services in the
                          digital age
                        </p>
                        <p>Here are some of the things we'll cover:</p>
                      </div>

                      <div className="things">
                        <p>
                          <span>Classic Marketing Fundamentals</span>: Basic
                          principles from the "old world of marketing” that
                          you'll need to understand for this course. Beginne
                        </p>
                        <p>
                          <p> Welcome here!</p>
                          <span>Content Marketing Essentials</span> What is
                          content marketing is, how it works, how to tell a
                          compelling story, and how to create detailed buyer
                          personas to guide your approach
                        </p>
                        <p>
                          <span>Web Design</span> How to optimize your website
                          for content marketing and buda well-oiled,
                          lead-generating machine
                        </p>
                        <p>
                          <span>Generating Content Ideas</span>: Develop a
                          system for getting to know your audience and
                          consistently generating new contentide
                        </p>
                        <p>
                          <span>Copywritings</span> We'll discuss why effective
                          writing is so important, and you'll lea all about
                          headlines, structure, vocabulary, fone,
                          call-to-action, editing, as well as a number of Sips
                          to help you become a better writer overall
                        </p>
                        <p>
                          <span>Types Of Content</span>: Learn about the most
                          effective types of content, and see more than a dozen
                          specific content ideas that you can steal and apply to
                          your own strategy.
                        </p>
                        <p>
                          <span>Content Formats & Repurposings</span> Get
                          familier with various content formats and learn how to
                          create an endless stream of fresh content simply by
                          repurposing what you've already created.
                        </p>
                        <p>
                          <span>SEO (Search Engine Optimization</span>{" "}
                          Everything you need to know to optimize your site for
                          search engines and drive free, organic search traffic
                          to your content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section1>
            </div>

            <div className="mainContent">
              <div>
                <div className="course_heading_title">
                  <h5>Course content</h5>
                </div>
                <div>
                  <ul className="course_title">
                    <li>
                      <a href="">
                        Section 1 : Introduction
                        <p> 1/1 ] 7 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Section 2 : Classic Marketing Fundamentals
                        <p> 1/41 ] 17 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Section 3 : Content Marketing Essentials
                        <p> 1/6 ] 27 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Section 4 : Web Design
                        <p> 1/4 ] 37 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Section 5 : Web Design
                        <p> 1/7 ] 7 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Section 6 : Copywriting
                        <p> 1/6 ] 127 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Section 7 : Types of Content
                        <p> 1/6 ] 27 min</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );



};

export default Pagedetailspage;
