import React, { useState, useEffect } from "react";
import "../assets/SCSS/Mycourses.scss";
import axios from "axios";
import { saveAs } from "file-saver";

import {
  Button,
  Modal,
  Input,
  Table,
  Select,
  Form,
  Space,
  Popconfirm,
  Switch,
} from "antd";
const Document = () => {
  const [batchId, setBatchId] = useState("");
  console.log("batchId", batchId);
  const userid = localStorage.getItem("userDetail");
  const parsedId = JSON.parse(userid);

  useEffect(() => {
    const id = localStorage.getItem("userDetail");
    if (id) {
      console.log("firsteeeeee", id);
      getBatchDropdown(id);
    }
  }, []);

  const getBatchDropdown = async (id) => {
    const userId = JSON.parse(id);
    console.log("Step2", JSON.parse(id));
    await axios.get(`api/user/${userId._id}`).then((res) => {
      console.log("res", res);
      setBatchId(res.data.data[0].userType);
      console.log("first", res.data.data[0].userType);
    });
  };
  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/api/userbatch/${parsedId._id}`)
      .then((res) => res.json())
      .then((result) => {
        console.log("first!!##", result.docunmentData);
        const data = result.docunmentData.filter(
          (document) => document.userType === batchId
        );
        setDocument(data);
        console.log("firstdata", data);
      });
  }, [batchId]);

  // demo
  const downloadDocument = (data) => {
    let documentUrl = data.documentUrl;
    let originalFileName = data.originalName;
    const downloadUrl = `${
      process.env.REACT_APP_DOMAIN
    }/api/getdocument?key=${encodeURIComponent(documentUrl)}`;

    fetch(downloadUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch document");
        }
        return response.json();
      })
      .then(async (data) => {
        console.log("JSON data:", data);

        const pdfUrl = data.url;
        console.log("PDF URL:", pdfUrl);

        const filename = pdfUrl.substring(pdfUrl.lastIndexOf("/") + 1);
        console.log("Filename:", filename);

        const response = await fetch(pdfUrl);
        const pdfBlob = await response.blob();
        saveAs(pdfBlob, originalFileName);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };

  let userData = JSON.parse(localStorage.getItem("userDetail"));
  const [Document, setDocument] = useState();
  console.log("Document@@11", Document);
  const getuserData = () => {};
  const viewDocument = async (documentUrl) => {
    console.log("documentUrl", documentUrl);
    let res = await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/getdocument?key=${documentUrl}`
    );
    console.log(
      "documenturl",
      `${process.env.REACT_APP_DOMAIN}/api/getdocument?key=${documentUrl}`
    );
    let data = await res.json();
    console.log("datasssss", data);
    return window.open(data?.url, "_blank");
  };

  const finaldata = Document?.filter((document) => document?._id);

  const columns = [
    {
      title: "DocumentName",
      dataIndex: "documentName",
      key: "documentName",
    },
    {
      title: "Description",
      dataIndex: "originalName",
      key: "originalName",
    },
    {
      title: "View Document",
      render: (_, record) => (
        <Space size="small">
          <a onClick={() => viewDocument(record.documentUrl)}>
            {console.log("jjjj", record.documentUrl)}
            <Button type="primary" size="medium">
              View Document
            </Button>{" "}
          </a>
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space size="small">
          <Button
            className="DocumentDownloadBtn"
            type="primary"
            size="medium"
            onClick={() => downloadDocument(record)}
          >
            {console.log("record.documentUrl", record.documentUrl)} Download
          </Button>
        </Space>
      ),
    },
  ];
  // useEffect(() => {
  //   fetch(process.env.REACT_APP_DOMAIN + "/api/userbatch/64967ba09fa40872b80f343a")
  //     .then((res) => res.json())
  //     .then((result) => {
  //       console.log("first!!", result?.data);
  //       const data=result.data.filter(document=>document.batch===batchId)
  //       setDocument(data);
  //     });
  // }, [batchId]);
  return (
    <div className="document_table">
      <h3>My Document</h3>
      <Table dataSource={finaldata} columns={columns} />
      {console.log("finaldata", finaldata)}
    </div>
  );
};

export default Document;
